import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  FaPlus,
  FaClipboardList,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import Switch from 'react-switch';

import Select from '../../components/Select';
import Confirmation from '../../components/Confirmation';

import api from '../../services/api';

import { report } from '../../utils/printReport';
import { changeNamesOfColumns } from '../../utils/handleSelects';
import { orderBy as handleOrderBy } from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersLine,
  FiltersField,
  FiltersFieldSwitch,
  Tables,
  TableTd,
  TablesPagination,
} from '../../styles/lists';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id?: string;
  razaoSocial?: string;
  fantasia?: string;
  telefone1?: string;
  email1?: string;
  cidade?: string;
  dataInativo?: Date;
}

const Supplier: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'razaoSocial',
    desc: false,
  });

  const filters: any = getFilters('suppliers');

  const [nome, setNome] = useState(
    Object.keys(filters).length > 0 ? filters.nome : '',
  );
  const [inativos, setInativos] = useState(
    Object.keys(filters).length > 0 ? filters.inativos : false,
  );
  const [cidades, setCidades] = useState<string>(
    Object.keys(filters).length > 0 ? filters.cidades : '',
  );

  function saveFilters() {
    setFilters(
      {
        nome,
        inativos,
        cidades,
      },
      'suppliers',
    );
  }

  const [selectCidades, setSelectCidades] = useState([]);

  const load = useCallback(async () => {
    try {
      const status = inativos ? {} : { status: 'ativo' };
      const response = await api.get('/suppliers', {
        params: {
          nome,
          cidade: cidades,
          ...status,
        },
      });
      const responseDisassembled = await response.data.map((item: any) => {
        return {
          id: item.id,
          razaoSocial: item.razaoSocial,
          fantasia: item.fantasia,
          cidade: item.cidades?.nome,
          dataInativo: item.dataInativo,
          telefone1: item.telefone1,
          email1: item.email1,
        };
      });
      const responseOrdered: any = await handleOrderBy(
        responseDisassembled,
        order,
      );
      setEntity(responseOrdered);
      setPages(Math.ceil(response.data.length / 10));
      setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
    } catch (err) {
      setEntity([{}]);
      setPages(1);
      setActualPage(1);
    }
  }, [nome, inativos, cidades, order]);

  useEffect(() => {
    async function loadCities() {
      const response = await api.get('/cities');
      setSelectCidades(changeNamesOfColumns(response.data, 'nome', 'id'));
    }
    loadCities();
    load();
  }, [load]);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#a53234' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  function handlePrint() {
    const header =
      '<th>NOME</th><th>CIDADE</th><th>TELEFONE</th><th>E-MAIL</th><th>INATIVO</th>';
    let content = '';
    entity.map(
      // eslint-disable-next-line no-return-assign
      supplier =>
      (content =
        `${content}<tr key=${supplier.id}> ` +
        `<td>${supplier.fantasia}</td> ` +
        `<td>${supplier.cidade}</td> ` +
        `<td>${supplier.telefone1}</td> ` +
        `<td>${supplier.email1}</td> ` +
        `<td>${supplier.dataInativo === null ? '' : supplier.dataInativo
        }</td> ` +
        `</tr>`),
    );
    report('RELAÇÃO DE FORNECEDORES', header, content);
  }

  function handleDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/suppliers/${id}`);
          load();
          addToast('Fornecedor deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o fornecedor, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o fornecedor ?',
      'delete',
    );
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/suppliers/register/${id}`);
  }

  function handleInsert() {
    saveFilters();
    navigate('/suppliers/register');
  }

  return (
    <Container>
      <Title>
        <h1>Listagem de Fornecedores</h1>
      </Title>
      <Buttons>
        <Button onClick={handleInsert}>
          <FaPlus size={18} color="#faede8" />
          Adicionar
        </Button>
        <Button visual="secondary" onClick={handlePrint}>
          <FaClipboardList size={18} color="#a53234" />
          Imprimir
        </Button>
      </Buttons>
      <Filters>
        <FiltersLine>
          <FiltersField size="50%">
            <input
              value={nome}
              onChange={e => setNome((e.target.value as any) || '')}
              id="nome"
              placeholder="Filtre pelo nome"
            />
          </FiltersField>
          <FiltersField size="35%">
            <Select
              name="cidades"
              height={40}
              clear
              maxMenuHeight={300}
              placeholder="Filtre pelas cidades"
              onChange={(e: any) => {
                // eslint-disable-next-line no-unused-expressions
                if (e !== null) {
                  setCidades(e.value);
                } else {
                  setCidades('');
                }
              }}
              defaultValue={cidades}
              options={selectCidades}
            />
          </FiltersField>
          <FiltersField size="15%">
            <FiltersFieldSwitch>
              <h3>Inativos</h3>
              <Switch
                checked={inativos}
                onChange={() => setInativos(!inativos)}
              />
            </FiltersFieldSwitch>
          </FiltersField>
        </FiltersLine>
      </Filters>
      <Tables>
        <table>
          <thead>
            <tr>
              <th>
                <div>
                  Razão social
                  <button
                    onClick={() => {
                      orderBy('razaoSocial');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('razaoSocial', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('razaoSocial', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Fantasia
                  <button
                    onClick={() => {
                      orderBy('fantasia');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('fantasia', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('fantasia', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Cidade
                  <button
                    onClick={() => {
                      orderBy('cidade');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('cidade', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('cidade', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Telefone
                  <button
                    onClick={() => {
                      orderBy('telefone1');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('telefone1', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('telefone1', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  E-mail
                  <button
                    onClick={() => {
                      orderBy('email1');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('email1', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('email1', true)}
                    />
                  </button>
                </div>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {'id' in entity[0] &&
              entity
                .slice(handlePagination(1), handlePagination(2))
                .map((e, index) => (
                  <tr key={index}>
                    <TableTd color={e.dataInativo && '#a53234'} width="20%">
                      {e.razaoSocial}
                    </TableTd>
                    <TableTd color={e.dataInativo && '#a53234'} width="20%">
                      {e.fantasia}
                    </TableTd>
                    <TableTd color={e.dataInativo && '#a53234'} width="20%">
                      {e.cidade}
                    </TableTd>
                    <TableTd color={e.dataInativo && '#a53234'} width="15%">
                      {e.telefone1}
                    </TableTd>
                    <TableTd color={e.dataInativo && '#a53234'} width="15%">
                      {e.email1}
                    </TableTd>
                    <TableTd center width="10%">
                      <button type="button" onClick={() => handleEdit(e.id)}>
                        <FiEdit size={20} color="#Fafafa" />
                      </button>
                      <button type="button" onClick={() => handleDelete(e.id)}>
                        <FiTrash size={20} color="#d13337" />
                      </button>
                    </TableTd>
                  </tr>
                ))}
          </tbody>
        </table>
        <TablesPagination>
          <span>
            {`${'id' in entity[0] ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#a53234'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#a53234'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default Supplier;
