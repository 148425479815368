import React from 'react';

import { Editor, EditorProps } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Container } from './styles';

interface EditorComponentProps extends EditorProps {
  uploadImage?: any;
}

const EditorComponent: React.FC<EditorComponentProps> = ({
  uploadImage,
  ...rest
}) => {
  return (
    <Container>
      <Editor
        {...rest}
        toolbarClassName="rdw-storybook-toolbar"
        wrapperClassName="rdw-storybook-wrapper"
        editorClassName="rdw-storybook-editor"
        toolbarStyle={{
          borderTop: '0',
          borderLeft: '0',
          borderRight: '0',
          borderBottom: '1px solid #3d3a3a',
          background: '#2e2c2c',
          marginBottom: '0',
          borderRadius: '8px 8px 0 0',
          paddingTop: '13px',
          paddingBottom: '7px',
        }}
        editorStyle={{
          border: '1px solid #2e2c2c',
          minHeight: '200px',
          backgroundColor: '#2e2c2c',
          borderRadius: '0 0px 8px 8px',
          paddingLeft: '15px',
        }}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'embedded',
            'emoji',
            'image',
            'history',
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          embedded: { className: 'embedded' },
          colorPicker: {
            className: 'colorPicker',
          },
          image: {
            className: 'image',
            uploadCallback: uploadImage || null,
          },
          emoji: {
            className: 'emoji',
          },
          history: {
            inDropdown: true,
          },
        }}
      />
    </Container>
  );
};

export default EditorComponent;
