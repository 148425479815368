import React from 'react';
import Select from 'react-select';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function ReactSelect({
  name,
  label,
  options,
  multiple,
  height,
  error = false,
  clear,
  defaultValue,
  disabled,
  ...rest
}: any) {
  const customStyles = {
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.isSelected ? '#6b6a6a' : '',
      '&:hover': {
        backgroundColor: '#a53234', // d1cdcb
      },
    }),
    control: (base: any, state: any) => ({
      ...base,
      minHeight: '1px',
      height: `${height}px`,
      padding: '0px 0px 0px 0px',
      border: `${error ? '2px solid #a53234' : '1px solid #2e2c2c'}`,
      borderRadius: '8px',
      boxShadowColor: '1px solid #2e2c2c',
      boxShadow: 'none',
      backgroundColor: '#2e2c2c',
      cursor: state.isDisabled && 'not-allowed',
      opacity: state.isDisabled && '0.4',
      '&:hover': {},
    }),
    placeholder: (base: any, state: any) => ({
      ...base,
      fontWeight: 'bold',
      fontSize: '14px',
      color: '#a39f9d',
    }),
    input: (base: any, state: any) => ({
      ...base,
      fontWeight: 'bold',
      fontSize: '14px',
      color: '#d1cdcb',
    }),
    singleValue: (base: any, state: any) => ({
      ...base,
      fontWeight: 'bold',
      fontSize: '14px',
      color: '#d1cdcb',
    }),
    multiValue: (base: any, state: any) => ({
      ...base,
      fontWeight: 'bold',
      fontSize: '12px',
      background: '#888888',
      color: '#d1cdcb',
    }),
    menu: (base: any, state: any) => ({
      ...base,
      fontWeight: 'bold',
      fontSize: '14px',
      background: '#2e2c2c',
      zIndex: 999,
      color: '#d1cdcb',
    }),
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      height: `${height}px`,
      borderRadius: '5px',
    }),
  };

  function getDefaultValue() {
    if (!defaultValue) return null;
    if (!multiple) {
      return options.find(
        (option: { value: any }) => option.value === defaultValue,
      );
    }
    return options.filter((option: { value: any }) =>
      defaultValue.includes(option.value),
    );
  }

  return (
    <>
      <Select
        name={name}
        isClearable={clear}
        options={options}
        isDisabled={disabled}
        isMulti={multiple}
        value={getDefaultValue()}
        noOptionsMessage={() => 'Nenhum registro encontrado'}
        getOptionValue={(option: { value: string }) => option.value}
        getOptionLabel={(option: { label: string}) => option.label}
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null,
        }}
        {...rest}
      />
    </>
  );
}
