import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  FaPlus,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import Confirmation from '../../components/Confirmation';

import api from '../../services/api';

import { orderBy as handleOrderBy } from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersLine,
  FiltersField,
  Tables,
  TableTd,
  TablesPagination,
} from '../../styles/lists';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id?: string;
  titulo?: string;
}

const ProblemSolution: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'titulo',
    desc: false,
  });

  const filters: any = getFilters('problemsSolutions');

  const [titulo, setTitulo] = useState(
    Object.keys(filters).length > 0 ? filters.titulo : '',
  );

  function saveFilters() {
    setFilters(
      {
        titulo,
      },
      'problemsSolutions',
    );
  }

  const load = useCallback(async () => {
    try {
      const response = await api.get('/problemsSolutions', {
        params: {
          titulo,
        },
      });
      const responseDisassembled = await response.data.map((item: any) => {
        return {
          id: item.id,
          titulo: item.titulo,
        };
      });
      const responseOrdered: any = await handleOrderBy(
        responseDisassembled,
        order,
      );
      setEntity(responseOrdered);
      setPages(Math.ceil(response.data.length / 10));
      setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
    } catch (err) {
      setEntity([{}]);
      setPages(1);
      setActualPage(1);
    }
  }, [titulo, order]);

  useEffect(() => {
    load();
  }, [load]);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#a53234' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  function handleDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/problemsSolutions/${id}`);
          load();
          addToast('Problema/Solução deletada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast(
            'Não foi possível deletar o problema/solução, tente novamente',
            {
              appearance: 'error',
              autoDismiss: true,
            },
          );
        }
      },
      'Tem certeza que deseja excluir o problema/solução ?',
      'delete',
    );
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/problemsSolutions/register/${id}`);
  }

  function handleInsert() {
    saveFilters();
    navigate('/problemsSolutions/register');
  }

  return (
    <Container>
      <Title>
        <h1>Listagem de Problemas e Soluções</h1>
      </Title>
      <Buttons>
        <Button onClick={handleInsert}>
          <FaPlus size={18} color="#faede8" />
          Adicionar
        </Button>
      </Buttons>
      <Filters>
        <FiltersLine>
          <FiltersField size="100%">
            <input
              value={titulo}
              onChange={e => setTitulo((e.target.value as any) || '')}
              id="titulo"
              placeholder="Filtre pela descrição"
            />
          </FiltersField>
        </FiltersLine>
      </Filters>
      <Tables>
        <table>
          <thead>
            <tr>
              <th>
                <div>
                  Descrição
                  <button
                    onClick={() => {
                      orderBy('titulo');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('titulo', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('titulo', true)}
                    />
                  </button>
                </div>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {'id' in entity[0] &&
              entity
                .slice(handlePagination(1), handlePagination(2))
                .map((e, index) => (
                  <tr key={index}>
                    <TableTd width="90%">{e.titulo}</TableTd>
                    <TableTd center width="10%">
                      <button type="button" onClick={() => handleEdit(e.id)}>
                        <FiEdit size={20} color="#Fafafa" />
                      </button>
                      <button type="button" onClick={() => handleDelete(e.id)}>
                        <FiTrash size={20} color="#d13337" />
                      </button>
                    </TableTd>
                  </tr>
                ))}
          </tbody>
        </table>
        <TablesPagination>
          <span>
            {`${'id' in entity[0] ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#a53234'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#a53234'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default ProblemSolution;
