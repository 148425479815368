import styled from 'styled-components';

export const FilterDiv = styled.div`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const FilterContentDiv = styled.div`
  width: 100%;
`;

export const FilterButtonDiv = styled.div`
  width: 40px;
  margin-right: 20px;
  margin-left: -6px;
`;
