import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';

import api from '../../../services/api';

import {
  getIndividualErrors,
  getErrors,
  validCpf,
  validCnpj,
  validFone,
  validCep,
} from '../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../utils/handleSelects';
import {
  cpfMask,
  cnpjMask,
  cepMask,
  foneMask,
} from '../../../utils/handleMasks';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldWithSearch,
  FormFieldInput,
  FormFieldTextArea,
  FormTitle,
  FormButtons,
  Button,
} from '../../../styles/registers';
import { getCEP } from '../../../utils/handleCEP';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  razaoSocial: string;
  fantasia: string;
  dataInativo: Date | null;
  cpfCnpj: string;
  rgIe: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  cidade: string;
  telefone1: string;
  telefone2: string;
  telefone3: string;
  email1: string;
  email2: string;
  email3: string;
  contatos: string;
  obs: string;
}

interface ErrorsData {
  [key: string]: string;
}

interface SelectData {
  label: string;
  value: string;
}

const SupplierRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idSupplier } = useParams<{ idSupplier: string }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    razaoSocial: '',
    fantasia: '',
    dataInativo: null,
    cpfCnpj: '',
    rgIe: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    cidade: '',
    telefone1: '',
    telefone2: '',
    telefone3: '',
    email1: '',
    email2: '',
    email3: '',
    contatos: '',
    obs: '',
  });
  const [fisicaJuridica, setFisicaJuridica] = useState('1');

  const [selectCidades, setSelectCidades] = useState([]);
  const [selectFisicaJuridica, setSelectFisicaJuridica] = useState<
    SelectData[]
  >([]);

  const [entityValidation, setEntityValidation] = useState({});
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      let response;

      try {
        response = await api.get('/cities');
        setSelectCidades(changeNamesOfColumns(response.data, 'nome', 'id'));
      } catch (err) {
        // manipulacao do erro
      }

      setSelectFisicaJuridica([
        {
          label: 'FÍSICA',
          value: '0',
        },
        {
          label: 'JURÍDICA',
          value: '1',
        },
      ]);
    }
    loadSelects();

    async function loadEntity() {
      if (idSupplier !== undefined) {
        const response = await api.get(`/suppliers/${idSupplier}`);
        const entityManipulated: any = loadForm(
          response.data,
          [],
          ['dataInativo'],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);

        const { cpfCnpj } = response.data;
        if (cpfCnpj !== null) {
          if (cpfCnpj.length === 18) {
            setFisicaJuridica('1');
          } else {
            setFisicaJuridica('0');
          }
        } else {
          setFisicaJuridica('1');
        }
      }
    }
    loadEntity();
  }, [idSupplier]);

  useEffect(() => {
    setErrors({});
    setEntityValidation({
      razaoSocial: Yup.string()
        .required('Preenchimento obrigatório')
        .label(`${fisicaJuridica === '1' ? 'Razão Social' : 'Nome'}`),
      fantasia: Yup.string().label(
        `${fisicaJuridica === '1' ? 'Fantasia' : 'Apelido'}`,
      ),
      dataInativo: Yup.date().nullable(),
      cpfCnpj: Yup.string()
        .test(
          'testCpfCnpj',
          fisicaJuridica === '1' ? 'CNPJ Inválido' : 'CPF Inválido',
          async function validate(value) {
            const result =
              fisicaJuridica === '1'
                ? await validCnpj(value as string)
                : await validCpf(value as string);
            return result;
          },
        )
        .label(`${fisicaJuridica === '1' ? 'CNPJ' : 'CPF'}`),
      rgIe: Yup.string(),
      logradouro: Yup.string(),
      numero: Yup.string(),
      complemento: Yup.string(),
      bairro: Yup.string(),
      cep: Yup.string()
        .nullable()
        .label('CEP')
        .test('testCepOk', 'CEP com formato inválido', function validade(
          value,
        ) {
          return validCep(value as string);
        }),
      cidade: Yup.string().nullable(),
      telefone1: Yup.string()
        .nullable()
        .label('Telefone 1')
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        }),
      telefone2: Yup.string()
        .nullable()
        .label('Telefone 2')
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        }),
      telefone3: Yup.string()
        .nullable()
        .label('Telefone 3')
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        }),
      email1: Yup.string()
        .email('E-mail com formato inválido')
        .label('E-mail 1'),
      email2: Yup.string()
        .email('E-mail com formato inválido')
        .label('E-mail 2'),
      email3: Yup.string()
        .email('E-mail com formato inválido')
        .label('E-mail 3'),
      obs: Yup.string(),
      contatos: Yup.string(),
    });
  }, [fisicaJuridica]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    let newValue;
    if (type === 'cpf') {
      newValue = cpfMask(value);
    } else if (type === 'cnpj') {
      newValue = cnpjMask(value);
    } else if (type === 'cep') {
      newValue = cepMask(value);
    } else if (type === 'fone') {
      newValue = foneMask(value);
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        if (idSupplier === undefined) {
          delete entitySave.codigo;
          const response = await api.post('/suppliers', entitySave);
          addToast('Fornecedor inserido com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          const response = await api.put(
            `/suppliers/${idSupplier}`,
            entitySave,
          );
          addToast('Fornecedor editado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
        navigate(-1);
      } catch (err) {
        addToast('Problemas ao gravar o fornecedor, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handleSearchCEP(cep: string) {
    try {
      const data = await getCEP(cep);

      if (data.erro) {
        addToast('CEP não encontrado, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      const entityModified: EntityData = entity;
      if (data.logradouro) {
        entityModified.logradouro = data.logradouro;
      }

      if (data.bairro) entityModified.bairro = data.bairro;

      if (data.localidade) {
        const cityReponse = await api.get(`cities/`, {
          params: {
            nomeCompleto: data.localidade.toUpperCase(),
          },
        });
        entityModified.cidade = cityReponse.data[0].id;
      }

      setEntity(entityModified);
      document.getElementById('bairro')?.focus();
      document.getElementById('logradouro')?.focus();
    } catch (err) {
      addToast('Problemas ao buscar dados do CEP, tente novamente', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Fornecedores</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="50%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'Razão social *' : 'Nome *'}
                ${errors.razaoSocial ? `(${errors.razaoSocial})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.razaoSocial}
                id="razaoSocial"
                autoComplete="off"
                error={!!errors.razaoSocial}
                placeholder={
                  fisicaJuridica === '1'
                    ? 'Digite a razão social'
                    : 'Digite o nome'
                }
              />
            </FormField>
            <FormField size="50%">
              <span>
                {`
                 ${fisicaJuridica === '1' ? 'Nome fantasia' : 'Apelido'}
                ${errors.fantasia ? `(${errors.fantasia})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.fantasia}
                autoComplete="off"
                id="fantasia"
                error={!!errors.fantasia}
                placeholder={
                  fisicaJuridica === '1'
                    ? 'Digite o nome fantasia'
                    : 'Digite o apelido'
                }
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="25%">
              <span>Física/Jurídica</span>
              <Select
                name="fisicajuridica"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha entre física e jurídica"
                onChange={(e: any) => {
                  setFisicaJuridica(e.value);
                  handleChanges(
                    'cpfCnpj',
                    entity.cpfCnpj,
                    e.value === '1' ? 'cnpj' : 'cpf',
                  );
                }}
                defaultValue={fisicaJuridica}
                options={selectFisicaJuridica}
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'CNPJ' : 'CPF'}
                ${errors.cpfCnpj ? `(${errors.cpfCnpj})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(
                    e.target.id,
                    e.target.value,
                    fisicaJuridica === '1' ? 'cnpj' : 'cpf',
                  );
                }}
                maxLength={fisicaJuridica === '1' ? 18 : 14}
                value={entity.cpfCnpj}
                autoComplete="off"
                id="cpfCnpj"
                placeholder={
                  fisicaJuridica === '1' ? 'Defina o CNPJ' : 'Defina o CPF'
                }
                error={!!errors.cpfCnpj}
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'I.E' : 'I.E/RG'}
                ${errors.rgIe ? `(${errors.rIge})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.rgIe}
                id="rgIe"
                autoComplete="off"
                error={!!errors.rgIe}
                placeholder={
                  fisicaJuridica === '1'
                    ? 'Digite a inscrição estadual'
                    : 'Digite o RG ou a inscrição estadual'
                }
              />
            </FormField>
            <FormField size="25%">
              <span>Data de inativação</span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataInativo')}
                error={!!errors.dataInativo}
                onChange={(e: any) => {
                  handleChanges('dataInativo', e, 'date');
                }}
                placeholderText="Defina a data de inativação"
                selected={entity.dataInativo}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>ENDEREÇOS</FormTitle>
          <FormLine>
            <FormFieldWithSearch size="20%">
              <span>
                {`CEP
                ${errors.cep ? `(${errors.cep})` : ''}
                `}
              </span>
              <div>
                <FormFieldInput
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => {
                    handleChanges(e.target.id, e.target.value, 'cep');
                  }}
                  value={entity.cep}
                  id="cep"
                  maxLength={9}
                  autoComplete="off"
                  error={!!errors.cep}
                  placeholder="Digite o CEP"
                />
                <button
                  onClick={() => handleSearchCEP(entity.cep)}
                  type="button"
                >
                  <FaSearch size={20} color="#faede8" />
                </button>
              </div>
            </FormFieldWithSearch>

            <FormField size="80%">
              <span>
                {`Logradouro
                ${errors.logradouro ? `(${errors.logradouro})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.logradouro}
                autoComplete="off"
                id="logradouro"
                error={!!errors.logradouro}
                placeholder="Digite o logradouro"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="20%">
              <span>
                {`Número
                ${errors.numero ? `(${errors.numero})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.numero}
                autoComplete="off"
                id="numero"
                error={!!errors.numero}
                placeholder="Digite o número"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`Bairro
                ${errors.bairro ? `(${errors.bairro})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.bairro}
                autoComplete="off"
                id="bairro"
                error={!!errors.bairro}
                placeholder="Digite o bairro"
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Complemento
                ${errors.complemento ? `(${errors.complemento})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.complemento}
                autoComplete="off"
                id="complemento"
                error={!!errors.complemento}
                placeholder="Digite o complemento"
              />
            </FormField>
            <FormField size="40%">
              <span>
                {`Cidade
                ${errors.cidade ? `(${errors.cidade})` : ''}
                `}
              </span>
              <Select
                name="cidade"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha a cidade"
                onBlur={(e: any) => handleValidation('cidade')}
                onChange={(e: any) => {
                  handleChanges('cidade', e.value);
                }}
                error={!!errors.cidade}
                defaultValue={entity.cidade}
                options={selectCidades}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>CONTATOS</FormTitle>
          <FormLine>
            <FormField size="33.33%">
              <span>
                {`Telefone 1
                ${errors.telefone1 ? `(${errors.telefone1})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone1}
                autoComplete="off"
                id="telefone1"
                maxLength={15}
                error={!!errors.telefone1}
                placeholder="Digite o telefone"
              />
            </FormField>
            <FormField size="33.33%">
              <span>
                {`Telefone 2
                ${errors.telefone2 ? `(${errors.telefone2})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone2}
                autoComplete="off"
                id="telefone2"
                maxLength={15}
                error={!!errors.telefone2}
                placeholder="Digite o telefone"
              />
            </FormField>
            <FormField size="33.34%">
              <span>
                {`Telefone 3
                ${errors.telefone3 ? `(${errors.telefone3})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone3}
                autoComplete="off"
                id="telefone3"
                maxLength={15}
                error={!!errors.telefone3}
                placeholder="Digite o telefone"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="33.33%">
              <span>
                {`Email 1
                ${errors.email1 ? `(${errors.email1})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email1}
                autoComplete="off"
                id="email1"
                error={!!errors.email1}
                placeholder="Digite o e-mail"
              />
            </FormField>
            <FormField size="33.33%">
              <span>
                {`Email 2
                ${errors.email2 ? `(${errors.email2})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email2}
                autoComplete="off"
                id="email2"
                error={!!errors.email2}
                placeholder="Digite o e-mail"
              />
            </FormField>
            <FormField size="33.34%">
              <span>
                {`Email 3
                ${errors.email3 ? `(${errors.email3})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email3}
                autoComplete="off"
                id="email3"
                error={!!errors.email3}
                placeholder="Digite o e-mail"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="100%">
              <span>
                {`Contatos
                ${errors.contatos ? `(${errors.contatos})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.contatos}
                autoComplete="off"
                id="contatos"
                error={!!errors.contatos}
                placeholder="Digite os contatos pessoais"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>OBSERVAÇÕES</FormTitle>
          <FormLine height="130px">
            <FormField size="100%">
              <span>
                {`Observações
                ${errors.obs ? `(${errors.obs})` : ''}
                `}
              </span>
              <FormFieldTextArea
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.obs}
                autoComplete="off"
                id="obs"
                error={!!errors.obs}
                placeholder="Digite as observações"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default SupplierRegister;
