/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

export function changeNamesOfColumns(
  array: any,
  label: string,
  value: string,
): any {
  const result: any = [];
  array.map((data: { [x: string]: any }) =>
    result.push({
      value: data[value],
      label: data[label],
    }),
  );
  return result;
}
