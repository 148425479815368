import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';
import Switch from 'react-switch';

import api from '../../../../services/api';

import {
  getIndividualErrors,
  getErrors,
  validFone,
} from '../../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../../utils/handleForms';
import { foneMask } from '../../../../utils/handleMasks';

import Confirmation from '../../../../components/Confirmation';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
} from '../../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  cliente: string | undefined;
  nome: string;
  telefone1: string;
  telefone2: string;
  email1: string;
  email2: string;
  telefone1Whatsapp: number;
  telefone2Whatsapp: number;
}

interface ErrorsData {
  [key: string]: string;
}

const ClientContactRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idClient } = useParams<{ idClient: string }>();
  const { idClientContact } = useParams<{ idClientContact: string }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    cliente: idClient,
    nome: '',
    telefone1: '',
    telefone2: '',
    email1: '',
    email2: '',
    telefone1Whatsapp: 0,
    telefone2Whatsapp: 0,
  });
  const [entityValidation] = useState({
    cliente: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Cliente'),
    nome: Yup.string().required('Preenchimento obrigatório').label('Nome'),
    telefone1: Yup.string()
      .nullable()
      .label('Telefone 1')
      .test('testFoneOk', 'Telefone com formato inválido', function validate(
        value,
      ) {
        return validFone(value as string);
      }),
    telefone2: Yup.string()
      .nullable()
      .label('Telefone 2')
      .test('testFoneOk', 'Telefone com formato inválido', function validate(
        value,
      ) {
        return validFone(value as string);
      }),
    email1: Yup.string().email('E-mail com formato inválido').label('E-mail 1'),
    email2: Yup.string().email('E-mail com formato inválido').label('E-mail 2'),
    telefone1Whatsapp: Yup.number().nullable(),
    telefone2Whatsapp: Yup.number().nullable(),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadEntity() {
      if (idClientContact !== undefined) {
        const response = await api.get(`/clientContacts/${idClientContact}`);
        const entityManipulated: any = loadForm(response.data, [], [], []);

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idClientContact]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    let newValue;
    if (type === 'fone') {
      newValue = foneMask(value);
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        if (idClientContact === undefined) {
          await api.post('/clientContacts', entitySave);
        } else {
          await api.put(`/clientContacts/${idClientContact}`, entitySave);
        }
        addToast('Contato de cliente gravado com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err) {
        addToast('Problemas ao gravar o contato de cliente, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Contatos do Cliente</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="100%">
              <span>
                {`
                Nome *
                ${errors.nome ? `(${errors.nome})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.nome}
                id="nome"
                autoComplete="off"
                error={!!errors.nome}
                placeholder="Digite o nome"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>CONTATOS</FormTitle>
          <FormLine>
            <FormField size="42%">
              <span>
                {`
                Telefone 1
                ${errors.telefone1 ? `(${errors.telefone1})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone1}
                autoComplete="off"
                id="telefone1"
                maxLength={15}
                error={!!errors.telefone1}
                placeholder="Digite o telefone"
              />
            </FormField>
            <FormField size="8%">
              <span>
                {`
                WhatsApp
                ${errors.telefone1Whatsapp
                    ? `(${errors.telefone1Whatsapp})`
                    : ''
                  }
                `}
              </span>
              <Switch
                checked={!!entity.telefone1Whatsapp}
                onChange={() => {
                  handleChanges(
                    'telefone1Whatsapp',
                    entity.telefone1Whatsapp === 0 ? 1 : 0,
                  );
                }}
              />
            </FormField>
            <FormField size="42%">
              <span>
                {`
                Telefone 2
                ${errors.telefone2 ? `(${errors.telefone2})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone2}
                autoComplete="off"
                id="telefone2"
                maxLength={15}
                error={!!errors.telefone2}
                placeholder="Digite o telefone"
              />
            </FormField>
            <FormField size="8%">
              <span>
                {`
                WhatsApp
                ${errors.telefone2Whatsapp
                    ? `(${errors.telefone2Whatsapp})`
                    : ''
                  }
                `}
              </span>
              <Switch
                checked={!!entity.telefone2Whatsapp}
                onChange={() => {
                  handleChanges(
                    'telefone2Whatsapp',
                    entity.telefone2Whatsapp === 0 ? 1 : 0,
                  );
                }}
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="50%">
              <span>
                {`
                E-mail 1
                ${errors.email1 ? `(${errors.email1})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email1}
                autoComplete="off"
                id="email1"
                error={!!errors.email1}
                placeholder="Digite o email"
              />
            </FormField>
            <FormField size="50%">
              <span>
                {`
                E-mail 2
                ${errors.email2 ? `(${errors.email2})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email2}
                autoComplete="off"
                id="email2"
                error={!!errors.email2}
                placeholder="Digite o email"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ClientContactRegister;
