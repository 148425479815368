import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../services/api';

import { getIndividualErrors, getErrors } from '../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../utils/handleSelects';
import { moneyMask } from '../../../utils/handleMasks';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
} from '../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  fornecedor: string;
  formaPagamento: string;
  conta: string;
  caixa: string;
  documento: string;
  descricao: string;
  valor: number;
  dataVencimento: Date | null;
  dataPagamento: Date | null;
  parcelaFinal: number;
}

interface ErrorsData {
  [key: string]: string;
}

const PaymentRegister: React.FC = () => {
  const navigate = useNavigate();

  const { idPayment } = useParams<{ idPayment: string }>();
  const { type } = useParams<{ type: string }>(); // 1 = alterar e 2 = pagar

  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    fornecedor: '',
    formaPagamento: '',
    conta: '',
    caixa: '',
    documento: '',
    descricao: '',
    valor: 0,
    dataVencimento: null,
    dataPagamento: null,
    parcelaFinal: 1,
  });
  const [entityValidation, setEntityValidation] = useState({
    fornecedor: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Fornecedor'),
    formaPagamento: Yup.string().nullable(),
    conta: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Conta')
      .nullable(),
    caixa: Yup.string().nullable(),
    documento: Yup.string().nullable(),
    descricao: Yup.string().nullable(),
    valor: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Valor')
      .min(0.01, 'Informe um valor maior que 0'),
    dataVencimento: Yup.date()
      .required('Preenchimento obrigatório')
      .label('Data de Vencimento')
      .nullable(),
    dataPagamento: Yup.date().nullable(),
    parcelaFinal: Yup.number()
      .required('Preenchimento obrigatório')
      .label('Parcela Final'),
  });
  const [group, setGroup] = useState<string>();
  const [subGroup, setSubGroup] = useState<string>();

  const [errors, setErrors] = useState<ErrorsData>({});
  const [errorGroup, setErrorGroup] = useState<boolean>(false);
  const [errorSubGroup, setErrorSubGroup] = useState<boolean>(false);

  const [selectSuppliers, setSelectSuppliers] = useState([]);
  const [selectPaymentMethods, setSelectPaymentMethods] = useState([]);
  const [selectGroups, setSelectGroups] = useState([]);
  const [selectSubGroups, setSelectSubGroups] = useState([]);
  const [selectAccounts, setSelectAccounts] = useState([]);
  const [selectCashPlaces, setSelectCashPlaces] = useState([]);

  const handleChanges = useCallback(
    async (id: string, value: any, fieldType?: string) => {
      let newValue;
      if (fieldType === 'money') {
        newValue = moneyMask(value);
      } else if (type === 'integer') {
        newValue = value === '' ? null : value;
      } else {
        newValue = value;
      }
      const newEntity = changeForm(entity, id, newValue);
      setEntity((newEntity as unknown) as EntityData);
    },
    [entity, type],
  );

  const handleGroup = useCallback(
    async (filledGroup: string) => {
      setGroup(filledGroup);
      setSubGroup(undefined);
      handleChanges('conta', null);

      setSelectSubGroups([]);
      setSelectAccounts([]);

      const response = await api.get('/cashPlaceSubGroups', {
        params: {
          grupo: filledGroup,
        },
      });
      setSelectSubGroups(
        changeNamesOfColumns(response.data, 'descricao', 'id'),
      );
    },
    [handleChanges],
  );

  const handleSubGroup = useCallback(
    async (filledSubGroup: string) => {
      setSubGroup(filledSubGroup);
      handleChanges('conta', null);

      setSelectAccounts([]);

      const response = await api.get('/cashPlaceAccounts', {
        params: {
          subGrupo: filledSubGroup,
          tipo: 'D',
        },
      });
      setSelectAccounts(changeNamesOfColumns(response.data, 'descricao', 'id'));
    },
    [handleChanges],
  );

  useEffect(() => {
    if (type === '2') {
      setEntityValidation({
        fornecedor: Yup.string().required('Preenchimento obrigatório'),
        formaPagamento: Yup.string().nullable(),
        conta: Yup.string().required('Preenchimento obrigatório'),
        caixa: Yup.string().nullable(),
        documento: Yup.string().nullable(),
        descricao: Yup.string().nullable(),
        valor: Yup.string()
          .required('Preenchimento obrigatório')
          .min(0.01, 'Informe um valor maior que 0'),
        dataVencimento: Yup.date()
          .required('Preenchimento obrigatório')
          .nullable(),
        dataPagamento: Yup.date().required('Preenchimento obrigatório'),
        parcelaFinal: Yup.number().required('Preenchimento obrigatório'),
      });
    }

    async function loadSelects() {
      try {
        const responseProvider = await api.get('/suppliers');
        setSelectSuppliers(
          changeNamesOfColumns(responseProvider.data, 'razaoSocial', 'id'),
        );
        const responsePaymentMethod = await api.get('/paymentMethods');
        setSelectPaymentMethods(
          changeNamesOfColumns(responsePaymentMethod.data, 'descricao', 'id'),
        );
        const responseCashPlaces = await api.get('/cashPlaces');
        setSelectCashPlaces(
          changeNamesOfColumns(responseCashPlaces.data, 'descricao', 'id'),
        );
        const responseGroups = await api.get('/cashPlaceGroups', {
          params: {
            tipo: 'D',
          },
        });
        setSelectGroups(
          changeNamesOfColumns(responseGroups.data, 'descricao', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idPayment !== undefined) {
        const response = await api.get(`/payments/${idPayment}`);
        const entityManipulated: any = loadForm(
          response.data,
          ['valor'],
          ['dataVencimento', 'dataPagamento'],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        const responseAccount = await api.get(
          `/cashPlaceAccounts/${entityManipulated.conta}`,
        );
        const responseSubGroup = await api.get(
          `/cashPlaceSubGroups/${responseAccount.data.subGrupo}`,
        );
        // setGroup(responseSubGroup.data.grupo);
        handleGroup(responseSubGroup.data.grupo);

        // setSubGroup(responseAccount.data.subGrupo);
        handleSubGroup(responseAccount.data.subGrupo);

        setEntity(entityManipulated);
      }
    }
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idPayment]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    setErrorGroup(!group);
    setErrorSubGroup(!subGroup);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, ['valor']);
        if (idPayment === undefined) {
          await api.post('/payments', entitySave);
        } else {
          const entityAlter = entitySave;
          delete entityAlter.parcela;
          await api.put(`/payments/${idPayment}`, entityAlter);
        }
        addToast('Conta a pagar gravada com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err: any) {
        addToast(
          err.response
            ? err.response.data.message
            : 'Problemas ao gravar a conta a pagar, tente novamente',
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>
          {`
          ${
            // eslint-disable-next-line no-nested-ternary
            idPayment ? (type === '1' ? 'Alteração' : 'Pagamento') : 'Cadastro'
            }
           de Contas a Pagar`}
        </h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS DA CONTA</FormTitle>
          <FormLine>
            <FormField size="33.33%">
              <span>
                {`
                Fornecedor *
                ${errors.fornecedor ? `(${errors.fornecedor})` : ''}
                `}
              </span>
              <Select
                name="fornecedor"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o fornecedor"
                onBlur={(e: any) => handleValidation('fornecedor')}
                onChange={(e: any) => {
                  handleChanges('fornecedor', e.value);
                }}
                error={!!errors.fornecedor}
                defaultValue={entity.fornecedor}
                options={selectSuppliers}
                disabled={type === '2'}
              />
            </FormField>
            <FormField size="33.33%">
              <span>
                {`
                Forma de pagamento
                ${errors.formaPagamento ? `(${errors.formaPagamento})` : ''}
                `}
              </span>
              <Select
                name="formaPagamento"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha a forma de pagamento"
                onBlur={(e: any) => handleValidation('formaPagamento')}
                onChange={(e: any) => {
                  handleChanges('formaPagamento', e.value);
                }}
                error={!!errors.formaPagamento}
                defaultValue={entity.formaPagamento}
                options={selectPaymentMethods}
              />
            </FormField>
            <FormField size="33.34%">
              <span>
                {`
                Caixa
                ${errors.caixa ? `(${errors.caixa})` : ''}
                `}
              </span>
              <Select
                name="caixa"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o caixa"
                onBlur={(e: any) => handleValidation('caixa')}
                onChange={(e: any) => {
                  handleChanges('caixa', e.value);
                }}
                error={!!errors.caixa}
                defaultValue={entity.caixa}
                options={selectCashPlaces}
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="33.33%">
              <span>
                {`Grupo *
                ${errorGroup ? '(Preenchimento obrigatório)' : ''}
                `}
              </span>
              <Select
                name="grupo"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o grupo"
                onChange={(e: any) => {
                  handleGroup(e.value);
                }}
                onBlur={(e: any) => setErrorGroup(!group)}
                error={!!errorGroup}
                defaultValue={group}
                options={selectGroups}
                disabled={type === '2'}
              />
            </FormField>
            <FormField size="33.33%">
              <span>
                {`Sub-grupo *
                ${errorSubGroup ? '(Preenchimento obrigatório)' : ''}
                `}
              </span>
              <Select
                name="subGrupo"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o sub grupo"
                onChange={(e: any) => {
                  handleSubGroup(e.value);
                }}
                onBlur={(e: any) => setErrorSubGroup(!subGroup)}
                error={!!errorSubGroup}
                defaultValue={subGroup}
                options={selectSubGroups}
                disabled={type === '2'}
              />
            </FormField>
            <FormField size="33.34%">
              <span>
                {`Conta *
                ${errors.conta ? `(${errors.conta})` : ''}
                `}
              </span>
              <Select
                name="conta"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha a conta"
                onBlur={(e: any) => handleValidation('conta')}
                onChange={(e: any) => {
                  handleChanges('conta', e.value);
                }}
                error={!!errors.conta}
                defaultValue={entity.conta}
                options={selectAccounts}
                disabled={type === '2'}
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="33.33%">
              <span>
                {`Documento
                ${errors.documento ? `(${errors.documento})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.documento}
                autoComplete="off"
                id="documento"
                error={!!errors.documento}
                placeholder="Digite o documento"
              />
            </FormField>
            <FormField size="33.33%">
              <span>
                {`Descrição
                ${errors.descricao ? `(${errors.descricao})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.descricao}
                autoComplete="off"
                id="descricao"
                error={!!errors.descricao}
                placeholder="Digite a descrição"
              />
            </FormField>
            <FormField size="33.34%">
              <span>
                {`Valor *
                ${errors.valor ? `(${errors.valor})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.valor}
                autoComplete="off"
                id="valor"
                error={!!errors.valor}
                placeholder="Digite o valor"
                disabled={!!entity.dataPagamento && type === '1'}
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="33.33%">
              <span>
                {`Data de Vencimento *
                ${errors.dataVencimento ? `(${errors.dataVencimento})` : ''}
                `}
              </span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataVencimento')}
                error={!!errors.dataVencimento}
                onChange={(e: any) => {
                  handleChanges('dataVencimento', e, 'date');
                }}
                placeholderText="Defina a data de vencimento"
                selected={entity.dataVencimento}
              />
            </FormField>
            <FormField size="33.33%">
              <span>
                {`Numero de parcelas *
                ${errors.parcelaFinal ? `(${errors.parcelaFinal})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'integer');
                }}
                value={entity.parcelaFinal}
                autoComplete="off"
                id="parcelaFinal"
                error={!!errors.parcelaFinal}
                placeholder="Digite o número de parcelas"
                type="number"
                min={1}
                max={500}
                disabled={!!type}
              />
            </FormField>
            <FormField size="33.34%">
              <span>
                {`${type === '2' ? '* ' : ''}Data de Pagamento
                ${errors.dataPagamento ? `(${errors.dataPagamento})` : ''}
                `}
              </span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataPagamento')}
                error={!!errors.dataPagamento}
                onChange={(e: any) => {
                  handleChanges('dataPagamento', e, 'date');
                }}
                placeholderText="Defina a data de pagamento"
                selected={entity.dataPagamento}
                disabled={type !== '2'}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default PaymentRegister;
