import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: #2E2C2C;
  }

  body, input, button, textarea {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
  }

  #root {
    margin: 0 auto;
  }

  button, label {
    cursor: pointer;
  }

  textarea {
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(35, 33, 41, 0.8);
    }
    scrollbar-width: thin;
    scrollbar-color: rgba(35, 33, 41, 0.8) transparent;
  }

`;
