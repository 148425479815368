import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../../services/api';

import { useAuth } from '../../../../hooks/AuthContext';

import { getIndividualErrors, getErrors } from '../../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../../utils/handleSelects';

import Confirmation from '../../../../components/Confirmation';
import Select from '../../../../components/Select';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldTextArea,
  FormTitle,
  FormButtons,
  Button,
} from '../../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  interesse: string | undefined;
  colaborador: string;
  descricao: string;
}

interface ErrorsData {
  [key: string]: string;
}

const LeadStoryRegister: React.FC = () => {
  const { user } = useAuth();

  const navigate = useNavigate();
  const { idLead } = useParams<{ idLead: string }>();
  const { idStory } = useParams<{ idStory: string }>();
  const { addToast } = useToasts();

  const [selectCollaborators, setSelectCollaborators] = useState([]);

  const [entity, setEntity] = useState<EntityData>({
    interesse: idLead,
    colaborador: '',
    descricao: '',
  });

  const [entityValidation] = useState({
    interesse: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Interesse'),
    colaborador: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Colaborador'),
    descricao: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Descrição'),
  });

  const [errors, setErrors] = useState<ErrorsData>({});

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  // Form Show
  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/collaborators');
        setSelectCollaborators(
          changeNamesOfColumns(response.data, 'nome', 'id'),
        );
      } catch (err) {
        //
      }
    }
    loadSelects();

    async function loadAuxiliarVariables() {
      try {
        const response = await api.get('/collaborators', {
          params: {
            usuario: user.id,
          },
        });
        handleChanges('colaborador', response.data[0].id);
      } catch (err) {
        //
      }
    }
    loadAuxiliarVariables();

    async function loadEntity() {
      if (idStory !== undefined) {
        const response = await api.get(`/leadStories/${idStory}`);
        const entityManipulated: any = loadForm(response.data, [], [], []);

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idStory, user.id]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        if (idStory === undefined) {
          await api.post('/leadStories', entitySave);
        } else {
          await api.put(`/leadStories/${idStory}`, entitySave);
        }
        addToast('Histórico gravado com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err) {
        addToast('Problemas ao gravar o histórico, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro histórico de interesse</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine height="100px">
            <FormField size="100%">
              <span>
                {`
                Descrição *
                ${errors.descricao ? `(${errors.descricao})` : ''}
              `}
              </span>
              <FormFieldTextArea
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.descricao}
                autoComplete="off"
                id="descricao"
                error={!!errors.descricao}
                placeholder="Digite a descrição"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="100%">
              <span>
                {`
                Colaborador *
                ${errors.colaborador ? `(${errors.colaborador})` : ''}
                `}
              </span>
              <Select
                name="colaborador"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o colaborador"
                onBlur={(e: any) => handleValidation('colaborador')}
                onChange={(e: any) => {
                  handleChanges('colaborador', e.value);
                }}
                error={!!errors.colaborador}
                defaultValue={entity.colaborador}
                options={selectCollaborators}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default LeadStoryRegister;
