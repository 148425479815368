import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiGitPullRequest, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../../../services/api';
import apiUpdater from '../../../../../services/apiUpdater';

import { changeNamesOfColumns } from '../../../../../utils/handleSelects';

import Confirmation from '../../../../../components/Confirmation';
import Select from '../../../../../components/Select';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormTitle,
  FormButtons,
  Button,
} from '../../../../../styles/registers';

interface ErrorsData {
  [key: string]: string;
}

const ClientProductComparative: React.FC = () => {
  const navigate = useNavigate();
  const { idClientProduct } = useParams<{ idClientProduct: string }>();
  const { addToast } = useToasts();

  const [errors, setErrors] = useState<ErrorsData>({});

  const [pendencies, setPendencies] = useState<string[]>([]);
  const [files, setFiles] = useState<string[]>([]);
  const [tasks, setTasks] = useState<string[]>([]);

  const [versaoIni, setVersaoIni] = useState();
  const [versaoFim, setVersaoFim] = useState();

  const [versoes, setVersoes] = useState();

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/clientProductVersions', {
          params: {
            clienteProduto: idClientProduct,
          },
        });

        const responseManipulated = await response.data.map((e: any) => {
          return {
            id: e.produtoVersao,
            descricao: e.produtoVersoes.versao,
          };
        });

        setVersoes(
          await changeNamesOfColumns(responseManipulated, 'descricao', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }
    }
    loadSelects();
  }, [idClientProduct]);

  async function handleComparative() {
    if (versaoIni === null || versaoIni === undefined) {
      addToast('Versão inicial não definida, verifique', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    if (versaoFim === null || versaoFim === undefined) {
      addToast('Versão final não definida, verifique', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    const authentication = await api.post('/sessions3rAtualizador', {
      nome: '3RATUALIZADOR',
      senha: '111111',
    });

    const response = await apiUpdater.get('/updaters', {
      params: {
        versaoInicial: versaoIni,
        versaoFinal: versaoFim,
      },
      headers: {
        Authorization: `Bearer ${authentication.data.token}`,
      },
    });

    setPendencies(response.data.pendencies);
    setFiles(response.data.files);
    setTasks(response.data.tasks);
  }

  return (
    <Container>
      <Title>
        <h1>Comparativo de versões do produto</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>PARÂMETROS</FormTitle>
          <FormLine>
            <FormField size="41.5%">
              <span>Versão inicial</span>
              <Select
                name="produtoVersao"
                height={40}
                maxMenuHeight={80}
                placeholder="Escolha a versão do produto"
                onChange={(e: any) => {
                  setVersaoIni(e.value);
                }}
                error={!!errors.produto}
                defaultValue={versaoIni}
                options={versoes}
              />
            </FormField>
            <FormField size="41.5%">
              <span>Versão final</span>
              <Select
                name="produtoVersao"
                height={40}
                maxMenuHeight={80}
                placeholder="Escolha a versão do produto"
                onChange={(e: any) => {
                  setVersaoFim(e.value);
                }}
                error={!!errors.produto}
                defaultValue={versaoFim}
                options={versoes}
              />
            </FormField>
            <FormField size="17%">
              <Button
                style={{ marginTop: '22px' }}
                type="button"
                width="100%"
                onClick={handleComparative}
              >
                <FiGitPullRequest size={20} color="#fafafa" />
                Comparar
              </Button>
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTitle>PENDÊNCIAS</FormTitle>
          <FormLine>
            <FormField size="100%">
              {pendencies.map((e: any) => (
                <>
                  <p>{e.descricao}</p>
                  <br />
                </>
              ))}
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTitle>ARQUIVOS</FormTitle>
          <FormLine>
            <FormField size="100%">
              {files.map((e: any) => (
                <>
                  <p>{`Copiar arquivo ${e.nome} para pasta ${e.diretorio}`}</p>
                  <br />
                </>
              ))}
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTitle>TAREFAS</FormTitle>
          <FormLine>
            <FormField size="100%">
              {tasks.map((e: any) => (
                <>
                  <p>{e.descricao}</p>
                  <br />
                </>
              ))}
            </FormField>
          </FormLine>
        </FormBlock>

        <FormButtons>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja voltar ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Voltar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ClientProductComparative;
