import styled from 'styled-components';

interface ContainerProps {
  height: string;
}

interface ProgressProps {
  height: string;
  size: number;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: ${props => (props.height ? `${props.height}px` : '40px')};
  background: #e6e6e6;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  box-shadow: inset -1px 1px 5px -1px rgba(196, 194, 196, 1);
`;

export const Progress = styled.div<ProgressProps>`
  width: ${props => (props.size ? `${props.size}%` : '0px')};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: ${props => (props.size > 99.9 ? '5px' : '0px')};
  border-bottom-right-radius: ${props => (props.size > 99.9 ? '5px' : '0px')};
  height: ${props => (props.height ? `${props.height}px` : '40px')};
  background: #029f74;
`;
