import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle, FiEdit, FiTrash } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';

import api from '../../../services/api';

import { getIndividualErrors, getErrors } from '../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';

import Confirmation from '../../../components/Confirmation';
import DatePicker from '../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  FormTable,
  Table,
  FormTableLegend,
  TableTd,
  Button,
  TableHeader,
  TableHeaderColumn,
} from '../../../styles/registers';
import { moneyMask } from '../../../utils/handleMasks';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  descricao: string;
  dataInativo: Date | null;
}

interface EntityModulesData {
  id?: string;
  produto?: string;
  descricao?: string;
  dataInativo?: Date;
}

interface EntityTypesData {
  id?: string;
  produto?: string;
  descricao?: string;
  dataInativo?: Date;
}

interface EntityVersionsData {
  id?: string;
  produto?: string;
  versao?: string;
  nota?: string;
  ordem?: number;
  producao?: number;
}

interface ErrorsData {
  [key: string]: string;
}

const ProductRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idProduct } = useParams<{ idProduct: string }>();
  const { addToast } = useToasts();
  const [idProductState, setIdProductState] = useState(idProduct);

  const [entity, setEntity] = useState<EntityData>({
    descricao: '',
    dataInativo: null,
  });

  const [entityModules, setEntityModules] = useState<EntityModulesData[]>([{}]);
  const [entityTypes, setEntityTypes] = useState<EntityTypesData[]>([{}]);
  const [entityVersions, setEntityVersions] = useState<EntityVersionsData[]>([
    {},
  ]);

  const [entityValidation] = useState({
    descricao: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Descrição'),
    dataInativo: Yup.date().nullable(),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  const loadModulesEntity = useCallback(async () => {
    if (!idProductState) return;

    const produto = idProductState;

    try {
      const response = await api.get('/productModules', {
        params: {
          produto,
        },
      });
      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          produto: item.produto,
          descricao: item.descricao,
          dataInativo: item.dataInativo,
        };
      });
      setEntityModules(responseDisassembled);
    } catch (error) {
    }

  }, [idProductState]);

  const loadTypesEntity = useCallback(async () => {
    if (!idProductState) return;

    const produto = idProductState;

    try {
      const response = await api.get('/productTypes', {
        params: {
          produto,
        },
      });
      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          produto: item.produto,
          descricao: item.descricao,
          dataInativo: item.dataInativo,
        };
      });
      setEntityTypes(responseDisassembled);
    } catch (error) {
    }

  }, [idProductState]);

  const loadVersionsEntity = useCallback(async () => {
    if (!idProductState) return;

    const produto = idProductState;

    try {
      const response = await api.get('/productVersions', {
        params: {
          produto,
          last: true,
        },
      });
      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          produto: item.produto,
          versao: item.versao,
          nota: item.nota,
          ordem: item.ordem,
          producao: item.producao,
        };
      });
      setEntityVersions(responseDisassembled);
    } catch (error) {
    }
  }, [idProductState]);

  useEffect(() => {
    async function loadEntity() {
      if (idProductState !== undefined) {
        const response = await api.get(`/products/${idProductState}`);
        const entityManipulated: any = loadForm(
          response.data,
          ['lite_excedentevalor', 'completo_excedentevalor'],
          ['dataInativo'],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
    loadModulesEntity();
    loadTypesEntity();
    loadVersionsEntity();
  }, [idProductState, loadModulesEntity, loadVersionsEntity, loadTypesEntity]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, fieldType?: string) {
    let newValue;
    if (fieldType === 'money') {
      newValue = moneyMask(value);
    } else if (fieldType === 'integer') {
      newValue = value === '' ? null : value;
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, ['lite_excedentevalor', 'completo_excedentevalor']);
        if (idProductState === undefined) {
          const response = await api.post('/products', entitySave);
          setIdProductState(response.data.id);
          navigate(`/products/register/${response.data.id}`, { replace: true });
          addToast(
            'Produto inserido com sucesso, clique novamente para fechar ou insira registros secundários',
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );
        } else {
          await api.put(`/products/${idProductState}`, entitySave);
          navigate(-1);
          addToast('Produto editado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      } catch (err) {
        addToast('Problemas ao gravar o produto, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  function handleModulesDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/productModules/${id}`);
          setEntityModules([{}]);
          loadModulesEntity();
          addToast('Módulo deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o módulo, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o módulo ?',
      'delete',
    );
  }

  function handleTypesDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/productTypes/${id}`);
          setEntityTypes([{}]);
          loadTypesEntity();
          addToast('Tipo deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o tipo, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o tipo ?',
      'delete',
    );
  }

  function handleVersionsDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/productVersions/${id}`);
          setEntityVersions([{}]);
          loadVersionsEntity();
          addToast('Versão deletada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar a versão, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir a versão ?',
      'delete',
    );
  }

  function handleSecondaryEdit(page: string, id?: string) {
    navigate(`/products/register/${idProductState}/${page}/${id}`);
  }

  function handleSecondaryInsert(page: string) {
    navigate(`/products/register/${idProductState}/${page}`);
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Produtos</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="75%">
              <span>
                {`
                Descrição *
                ${errors.descricao ? `(${errors.descricao})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.descricao}
                id="descricao"
                autoComplete="off"
                error={!!errors.descricao}
                placeholder="Digite a descrição"
              />
            </FormField>
            <FormField size="25%">
              <span>Data de inativação</span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataInativo')}
                error={!!errors.dataInativo}
                onChange={(e: any) => {
                  handleChanges('dataInativo', e);
                }}
                placeholderText="Defina a data de inativação"
                selected={entity.dataInativo}
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTable height="300px">
            <div>
              <h1>LISTA DE TIPOS</h1>
              <div>
                <FormTableLegend color="#a53234">Inativos</FormTableLegend>
                <button
                  disabled={idProductState === undefined}
                  onClick={() => {
                    handleSecondaryInsert('type');
                  }}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="90%">Nome</TableHeaderColumn>
            </TableHeader>
            <Table height="200px">
              <table>
                <tbody>
                  {'id' in entityTypes[0] &&
                    entityTypes.map((e, index) => (
                      <tr key={index}>
                        <TableTd color={e.dataInativo && '#a53234'} width="90%">
                          {e.descricao}
                        </TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleSecondaryEdit('type', e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleTypesDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>

        <FormBlock>
          <FormTable height="300px">
            <div>
              <h1>LISTA DE MÓDULOS</h1>
              <div>
                <FormTableLegend color="#a53234">Inativos</FormTableLegend>
                <button
                  disabled={idProductState === undefined}
                  onClick={() => {
                    handleSecondaryInsert('module');
                  }}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="90%">Nome</TableHeaderColumn>
            </TableHeader>
            <Table height="200px">
              <table>
                <tbody>
                  {'id' in entityModules[0] &&
                    entityModules.map((e, index) => (
                      <tr key={index}>
                        <TableTd color={e.dataInativo && '#a53234'} width="90%">
                          {e.descricao}
                        </TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleSecondaryEdit('module', e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleModulesDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>

        <FormBlock>
          <FormTable height="300px">
            <div>
              <h1>LISTA DE VERSÕES</h1>
              <div>
                <FormTableLegend color="#668B8B">
                  Em desenvolvimento
                </FormTableLegend>
                <button
                  disabled={idProductState === undefined}
                  onClick={() => {
                    handleSecondaryInsert('version');
                  }}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="70%">Nome</TableHeaderColumn>
              <TableHeaderColumn width="20%">Ordem</TableHeaderColumn>
            </TableHeader>
            <Table height="200px">
              <table>
                <tbody>
                  {'id' in entityVersions[0] &&
                    entityVersions.map((e, index) => (
                      <tr key={index}>
                        <TableTd
                          color={e.producao === 0 ? '#668B8B' : '#888888'}
                          width="70%"
                        >
                          {e.versao}
                        </TableTd>
                        <TableTd
                          color={e.producao === 0 ? '#668B8B' : '#888888'}
                          width="20%"
                        >
                          {e.ordem}
                        </TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleSecondaryEdit('version', e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleVersionsDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>

        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ProductRegister;
