import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './styles.css';

import { FaTimes, FaCheck } from 'react-icons/fa';
import { FiInfo, FiTrash } from 'react-icons/fi';

import { Confirmation, Message, Buttons } from './styles';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default async function confirmation(
  execute: any,
  message: string,
  type?: string,
) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <Confirmation>
          <Message>
            {type === 'delete' && <FiTrash size={30} color="#fafafa" />}
            {type === 'abort' && <FiInfo size={30} color="#fafafa" />}
            <h1>{message}</h1>
          </Message>
          <Buttons>
            <button
              type="button"
              onClick={() => {
                execute();
                onClose();
              }}
            >
              <FaCheck size={19} color="#fafafa" />
              Sim
            </button>
            <button
              type="button"
              onClick={() => {
                onClose();
              }}
            >
              <FaTimes size={20} color="#fafafa" />
              Não
            </button>
          </Buttons>
        </Confirmation>
      );
    },
  });
}
