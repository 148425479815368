import React from 'react';

import { Container, Progress } from './styles';

interface IProgress {
  size: number;
  height: string;
}

const ProgressComponent: React.FC<IProgress> = ({ size, height, ...rest }) => {
  return (
    <Container height={height}>
      <Progress size={size} height={height} />
    </Container>
  );
};

export default ProgressComponent;
