import styled from 'styled-components';

export const LogReasonContainer = styled.div`
  padding: 10px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  p {
    color: #d1cdcb;
    margin-bottom: 10px;
    margin-left: 2px;
    font-size: 14px;
  }
`;
