import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../../../services/api';

import {
  getIndividualErrors,
  getErrors,
} from '../../../../../utils/validateForm';
import {
  changeForm,
  saveForm,
  loadForm,
} from '../../../../../utils/handleForms';

import Select from '../../../../../components/Select';
import Confirmation from '../../../../../components/Confirmation';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormTitle,
  FormButtons,
  Button,
  FormFieldInput,
} from '../../../../../styles/registers';
import { moneyMask } from '../../../../../utils/handleMasks';
import { changeNamesOfColumns } from '../../../../../utils/handleSelects';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  produtoModulo: string | undefined;
  produtoTipo: string | undefined;
  usuariosAtivos: number;
  quantidadeDocumentos: number;
  quantidadeDocumentosExcedentes: number;
  valorQuantidadeDocumentosExcedentes: number;
  valor: number;
}

interface ErrorsData {
  [key: string]: string;
}

const ProductModulePriceRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idProductModule, idProduct } = useParams<{ idProductModule: string, idProduct: string }>();
  const { idProductModulePrice } = useParams<{
    idProductModulePrice: string;
  }>();
  const { addToast } = useToasts();

  const [selectTypes, setSelectTypes] = useState([]);

  const [entity, setEntity] = useState<EntityData>({
    produtoModulo: idProductModule,
    produtoTipo: '',
    usuariosAtivos: 0,
    quantidadeDocumentos: 0,
    quantidadeDocumentosExcedentes: 0,
    valorQuantidadeDocumentosExcedentes: 0,
    valor: 0,
  });

  const [entityValidation] = useState({
    produtoModulo: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Versão'),
    produtoTipo: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Descrição'),
    usuariosAtivos: Yup.string()
      .required('Preenchimento obrigatório')
      .min(0.01, 'Informe um valor maior que 0'),
    quantidadeDocumentos: Yup.string()
      .required('Preenchimento obrigatório')
      .min(0.01, 'Informe um valor maior que 0'),
    quantidadeDocumentosExcedentes: Yup.string()
      .required('Preenchimento obrigatório')
      .min(0.01, 'Informe um valor maior que 0'),
    valorQuantidadeDocumentosExcedentes: Yup.string()
      .required('Preenchimento obrigatório')
      .min(0.01, 'Informe um valor maior que 0'),
    valor: Yup.string()
      .required('Preenchimento obrigatório')
      .min(0.01, 'Informe um valor maior que 0'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/productTypes', {
          params: {
            produto: idProduct,
            somenteAtivos: true,
          }
        });
        setSelectTypes(
          changeNamesOfColumns(response.data, 'descricao', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idProductModulePrice !== undefined) {
        const response = await api.get(
          `/productModulePrices/${idProductModulePrice}`,
        );
        const entityManipulated: any = loadForm(response.data, ['valor', 'valorQuantidadeDocumentosExcedentes'], [], []);

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idProductModulePrice]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    let newValue;
    if (type === 'money') {
      newValue = moneyMask(value);
    } else if (type === 'integer') {
      newValue = value === '' ? null : value;
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, ['usuariosAtivos', 'quantidadeDocumentos', 'quantidadeDocumentosExcedentes', 'valorQuantidadeDocumentosExcedentes', 'valor']);
        if (idProductModulePrice === undefined) {
          await api.post('/productModulePrices', entitySave);
          addToast('Preço inserido com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          await api.put(
            `/productModulePrices/${idProductModulePrice}`,
            entitySave,
          );
          addToast('Preço editado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
        navigate(-1);
      } catch (err) {
        addToast('Problemas ao gravar o preço, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de preços de módulos de produtos</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="25%">
              <span>
                {`Tipo *
                ${errors.produtoTipo ? `(${errors.produtoTipo})` : ''}
                `}
              </span>
              <Select
                name="produtoTipo"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o tipo"
                onBlur={(e: any) => handleValidation('produtoTipo')}
                onChange={(e: any) => {
                  handleChanges('produtoTipo', e.value);
                }}
                error={!!errors.produtoTipo}
                defaultValue={entity.produtoTipo}
                options={selectTypes}
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Usuários Ativos *
                ${errors.usuariosAtivos ? `(${errors.usuariosAtivos})` : ''}
                `}
              </span>
              <FormFieldInput
                type="number"
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value, 'integer')}
                value={entity.usuariosAtivos}
                id="usuariosAtivos"
                autoComplete="off"
                error={!!errors.usuariosAtivos}
                placeholder="Digite a quantidade de usuários ativos."
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Quantidade Documentos *
                ${errors.quantidadeDocumentos ? `(${errors.quantidadeDocumentos})` : ''}
                `}
              </span>
              <FormFieldInput
                type="number"
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value, 'integer')}
                value={entity.quantidadeDocumentos}
                id="quantidadeDocumentos"
                autoComplete="off"
                error={!!errors.quantidadeDocumentos}
                placeholder="Digite a quantidade de documentos"
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Valor *
                ${errors.valor ? `(${errors.valor})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.valor}
                autoComplete="off"
                id="valor"
                error={!!errors.valor}
                placeholder="Digite o valor"
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Qtd.Docs.Excedentes *
                ${errors.quantidadeDocumentosExcedentes ? `(${errors.quantidadeDocumentosExcedentes})` : ''}
                `}
              </span>
              <FormFieldInput
                type="number"
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value, 'integer')}
                value={entity.quantidadeDocumentosExcedentes}
                id="quantidadeDocumentosExcedentes"
                autoComplete="off"
                error={!!errors.quantidadeDocumentosExcedentes}
                placeholder="Digite a quantidade de documentos"
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Valor Qtd.Docs.Excedentes *
                ${errors.valorQuantidadeDocumentosExcedentes ? `(${errors.valorQuantidadeDocumentosExcedentes})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.valorQuantidadeDocumentosExcedentes}
                autoComplete="off"
                id="valorQuantidadeDocumentosExcedentes"
                error={!!errors.valorQuantidadeDocumentosExcedentes}
                placeholder="Digite o valor"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ProductModulePriceRegister;
