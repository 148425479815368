import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiXCircle } from 'react-icons/fi';
import { format } from 'date-fns';

import api from '../../../services/api';

import { mesAnoMask } from '../../../utils/handleMasks';

import Confirmation from '../../../components/Confirmation';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormButtons,
  FormLine,
  FormField,
  FormFieldInput,
  Button,
  FormTitle,
} from '../../../styles/registers';

import { FormFieldInputPayed } from './styles';

interface EntityParams {
  day: string;
  oldPassword: string;
  password: string;
  payed: boolean;
}

const ClientPassword: React.FC = () => {
  const navigate = useNavigate();
  const { idClient } = useParams<{ idClient: string }>();

  const [clientName, setClientName] = useState();
  const [clientCode, setClientCode] = useState();
  const [monthYear, setMonthYear] = useState<string>('');
  const [entity, setEntity] = useState<EntityParams>({
    day: '',
    oldPassword: '',
    password: '',
    payed: true,
  });

  async function handlePassword(monthYearParam: string) {
    setMonthYear(monthYearParam);
    if (monthYearParam.length === 7) {
      const response = await api.get(`/monthYearPasswords`, {
        params: {
          monthYear: monthYearParam,
          clientId: idClient,
        },
      });
      setEntity(response.data);
    }
  }

  useEffect(() => {
    async function loadClient() {
      const response = await api.get(`/clients/${idClient}`);
      setClientName(response.data.fantasia);
      setClientCode(response.data.codigo);
    }
    loadClient();

    handlePassword(format(new Date(), 'dd/MM/yyyy').substring(3, 10));
  }, [idClient]);

  return (
    <Container>
      <Title>
        <h1>Listagem de senhas do cliente</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormLine>
            <FormField size="80%">
              <span>Nome</span>
              <FormFieldInput value={clientName || ''} id="codigo" disabled />
            </FormField>
            <FormField size="20%">
              <span>Código</span>
              <FormFieldInput value={clientCode || ''} id="codigo" disabled />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>Controle de senha</FormTitle>
          <FormLine>
            <FormField size="10%">
              <span>Mês/Ano</span>
              <FormFieldInput
                onChange={e => handlePassword(mesAnoMask(e.target.value))}
                value={monthYear || ''}
                id="mesAno"
                maxLength={7}
              />
            </FormField>
            <FormField size="25%">
              <span>{`Senha <`}</span>
              <FormFieldInput
                value={entity.oldPassword || ''}
                id="codigo"
                disabled
              />
            </FormField>
            <FormField size="25%">
              <span>Dia</span>
              <FormFieldInput value={entity.day || ''} id="codigo" disabled />
            </FormField>
            <FormField size="25%">
              <span>{`>= Senha`}</span>
              <FormFieldInput
                value={entity.password || ''}
                id="codigo"
                disabled
              />
            </FormField>
            <FormField size="15%">
              <span>Pagamento</span>
              <FormFieldInputPayed
                value={entity.payed ? 'EM DIA' : 'POSSUI INADIMPLÊNCIA'}
                id="codigo"
                color={entity.payed ? 'green' : 'red'}
                disabled
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormButtons>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja sair ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Fechar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ClientPassword;
