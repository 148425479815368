import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../../../services/api';

import {
  getIndividualErrors,
  getErrors,
} from '../../../../../utils/validateForm';
import {
  changeForm,
  saveForm,
  loadForm,
} from '../../../../../utils/handleForms';

import Confirmation from '../../../../../components/Confirmation';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
} from '../../../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  produtoVersao: string | undefined;
  apelido: string;
  nome: string;
  diretorio: string;
}

interface ErrorsData {
  [key: string]: string;
}

const ProductVersionFileRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idProductVersion } = useParams<{ idProductVersion: string }>();
  const { idProductVersionFile } = useParams<{
    idProductVersionFile: string;
  }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    produtoVersao: idProductVersion,
    apelido: '',
    nome: '',
    diretorio: '',
  });

  const [entityValidation] = useState({
    produtoVersao: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Versão'),
    apelido: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Apelido'),
    nome: Yup.string().required('Preenchimento obrigatório').label('Nome'),
    diretorio: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Diretório'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadEntity() {
      if (idProductVersionFile !== undefined) {
        const response = await api.get(
          `/productVersionFiles/${idProductVersionFile}`,
        );
        const entityManipulated: any = loadForm(response.data, [], [], []);

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idProductVersionFile]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        if (idProductVersionFile === undefined) {
          await api.post('/productVersionFiles', entitySave);
          addToast('Arquivo inserido com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          await api.put(
            `/productVersionFiles/${idProductVersionFile}`,
            entitySave,
          );
          addToast('Arquivo editado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
        navigate(-1);
      } catch (err) {
        addToast('Problemas ao gravar o arquivo, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de arquivos de versões de produtos</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="100%">
              <span>
                {`
                Apelido *
                ${errors.apelido ? `(${errors.apelido})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.apelido}
                id="apelido"
                autoComplete="off"
                error={!!errors.apelido}
                placeholder="Digite o apelido"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="50%">
              <span>
                {`
                Nome *
                ${errors.nome ? `(${errors.nome})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.nome}
                id="nome"
                autoComplete="off"
                error={!!errors.nome}
                placeholder="Digite o nome"
              />
            </FormField>
            <FormField size="50%">
              <span>
                {`
                Diretório *
                ${errors.diretorio ? `(${errors.diretorio})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.diretorio}
                id="diretorio"
                autoComplete="off"
                error={!!errors.diretorio}
                placeholder="Digite o diretório"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ProductVersionFileRegister;
