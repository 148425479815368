import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  format,
  parseISO,
  isAfter,
  isSameDay,
  isSameMonth,
  isSameYear,
  startOfMonth,
  endOfMonth,
} from 'date-fns';

import {
  FaPlus,
  FaClipboardList,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaCoins,
  FaSearch,
} from 'react-icons/fa';
import { FiEdit, FiPrinter, FiTrash, FiZapOff, FiMail } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import ClipLoader from 'react-spinners/ClipLoader';
import Select from '../../components/Select';
import Confirmation from '../../components/Confirmation';
import DatePicker from '../../components/DatePicker';
import Dropdown from '../../components/Dropdown';

import api from '../../services/api';

import { report } from '../../utils/printReport';
import { changeNamesOfColumns } from '../../utils/handleSelects';
import {
  orderBy as handleOrderBy,
  manipulateDates,
  manipulateFloats,
} from '../../utils/handleLists';
import {
  setFilters,
  getFilters,
  removeFilters,
} from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersField,
  FiltersFieldBetween,
  Tables,
  Legends,
  Legend,
  TableTd,
  TablesPagination,
  FormLoading,
} from '../../styles/lists';

import { ButtonPaymentLine, ButtonPayments, FilterButtonDiv, FilterContentDiv, FilterDiv } from './styles';
import { CustomFiltersLine } from '../Task/styles';
import FilterButton from '../../components/FilterButton';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id: string;
  dataVencimento: Date;
  documento: string;
  cliente: string;
  descricao: string;
  parcela: string;
  parcelaFinal: string;
  valor: number;
  dataPagamento: Date;
  boletoContaCobranca: string;
  boletoNossoNumero: string | null;
  boletoNumeroDoc: number;
  codigo: number;
  boletovalorliquidado: number;
  boletoDataProcessamento: Date;
  boletoSituacao: string;
}

interface InvoiceSetting {
  id: string;
  descricao: string;
}

interface PaymentSlip {
  nossoNumero: string;
  clienteRazaoSocial: string;
  clienteFantasia: string;
  dataVencimento: Date;
  dataPagamento: Date;
  valor: number;
  valorLiquidado: number;
}

interface PaymentSlipReport {
  boletonossonumero: string;
  razaosocial?: string;
  fantasia?: string;
  datavencimento?: Date;
  datapagamento?: Date;
  valor?: number;
  boletovalorliquidado?: number;
}

const Receipt: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);

  const [entity, setEntity] = useState<EntityParams[]>([]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'dataVencimento',
    desc: true,
  });
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const filters: any = getFilters('receipts');

  const [cliente, setCliente] = useState(
    Object.keys(filters).length > 0 ? filters.cliente : '',
  );
  const [documento, setDocumento] = useState(
    Object.keys(filters).length > 0 ? filters.documento : '',
  );
  const [descricao, setDescricao] = useState(
    Object.keys(filters).length > 0 ? filters.descricao : '',
  );
  const [conta, setConta] = useState<string>(
    Object.keys(filters).length > 0 ? filters.conta : '',
  );
  const [contaBancaria, setContaBancaria] = useState<string>(
    Object.keys(filters).length > 0 ? filters.contaBancaria : '',
  );
  const [emailEnviado, setEmailEnviado] = useState<string>(
    Object.keys(filters).length > 0 ? filters.emailEnviado : '',
  );
  const [tipoData, setTipoData] = useState(
    Object.keys(filters).length > 0 ? filters.tipoData : 'dataVencimento',
  );
  const [status, setStatus] = useState(
    Object.keys(filters).length > 0 ? filters.status : 'todas',
  );
  const [dataInicial, setDataIncial] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataInicial !== null
      ? parseISO(filters.dataInicial)
      : startOfMonth(new Date()),
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataFinal !== null
      ? parseISO(filters.dataFinal)
      : endOfMonth(new Date()),
  );

  function saveFilters() {
    setFilters(
      {
        cliente,
        documento,
        descricao,
        conta,
        contaBancaria,
        emailEnviado,
        tipoData,
        dataInicial,
        dataFinal,
        status,
      },
      'receipts',
    );
  }

  function saveSelectedRecords() {
    setFilters({ ids: selectedRows }, 'receipts-selected-records');
  }

  function removeSelectedRecords() {
    removeFilters('receipts-selected-records');
  }

  const [selectContas, setSelectContas] = useState([]);
  const [selectContasBancarias, setSelectContasBancarias] = useState([]);
  const [selectEmailEnviado] = useState([
    {
      label: 'Enviados',
      value: '1',
    },
    {
      label: 'Não enviados',
      value: '0',
    },
  ]);
  const [selectTipoDatas] = useState([
    {
      label: 'Digitação',
      value: 'created_at',
    },
    {
      label: 'Vencimento',
      value: 'dataVencimento',
    },
    {
      label: 'Pagamento',
      value: 'dataPagamento',
    },
  ]);

  const [selectStatus] = useState([
    {
      label: 'Todas',
      value: 'todas',
    },
    {
      label: 'Em aberto',
      value: 'aberto',
    },
    {
      label: 'Pagas',
      value: 'pagas',
    },
  ]);

  async function loadRegisters() {
    const delayDebounceFn = setTimeout(async () => {
      try {
        const response = await api.get('/receipts', {
          params: {
            cliente,
            documento,
            descricao,
            conta,
            contaCobranca: contaBancaria,
            emailEnviado,
            tipoData,
            status,
            dataInicial: dataInicial && format(dataInicial, 'yyyy-MM-dd'),
            dataFinal: dataFinal && format(dataFinal, 'yyyy-MM-dd'),
          },
        });

        const responseDisassembled = await response.data.map((item: any) => {
          return {
            id: item.id,
            dataVencimento: item.dataVencimento,
            documento: item.documento,
            cliente: item.clientes.fantasia,
            descricao: item.descricao,
            parcela: item.parcela,
            parcelaFinal: item.parcelaFinal,
            valor: manipulateFloats(item.valor),
            dataPagamento: item.dataPagamento,
            boletoContaCobranca: item.boletoContaCobranca,
            boletoNossoNumero: item.boletoNossoNumero,
            boletoNumeroDoc: item.boletoNumeroDoc,
            codigo: item.codigo,
            boletoDataProcessamento: item.boletoDataProcessamento,
            boletoSituacao: item.boletoSituacao,
            boletovalorliquidado: manipulateFloats(item.boletoValorLiquidado)
          };
        });

        const responseOrdered: any = await handleOrderBy(
          responseDisassembled,
          order,
        );
        setEntity(responseOrdered);
        setPages(Math.ceil(response.data.length / 10));
        setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
      } catch (err) {
        setEntity([]);
        setPages(1);
        setActualPage(1);
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }

  useEffect(() => {
    loadRegisters();
  }, [
    // cliente,
    // documento,
    // descricao,
    conta,
    contaBancaria,
    emailEnviado,
    tipoData,
    status,
    dataInicial,
    dataFinal,
    order,
    Button,
  ]);

  useEffect(() => {
    async function loadAccounts() {
      const response = await api.get('/cashPlaceAccounts', {
        params: {
          tipo: 'C',
        },
      });
      setSelectContas(changeNamesOfColumns(response.data, 'descricao', 'id'));
    }
    async function loadAccountsBanks() {
      const response = await api.get('/invoiceSettings');
      setSelectContasBancarias(
        changeNamesOfColumns(response.data, 'descricao', 'id'),
      );
    }
    loadAccounts();
    loadAccountsBanks();

    setDocumento('1');
    setDocumento('');
  }, []);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#a53234' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  function handlePrint() {
    const header =
      '<th>VENCIMENTO</th><th>DOCUMENTO</th><th>CLIENTE</th><th>DESCRIÇÃO</th><th>PARCELA</th><th>VALOR</th><th>VALOR LIQUIDADO</th><th>PAGAMENTO</th>';
    let content = '';
    let total = 0;
    let totalLiquidado = 0;
    entity.map(payment => {
      content =
        `${content}<tr key=${payment.id}> ` +
        `<td>${manipulateDates(`${payment.dataVencimento}`)}</td>  ` +
        `<td>${payment.documento ? payment.documento : ''}</td>  ` +
        `<td>${payment.cliente}</td>  ` +
        `<td>${payment.descricao ? payment.descricao : ''}</td>  ` +
        `<td>${payment.parcela}/${payment.parcelaFinal}</td>  ` +
        `<td>${payment.valor}</td>  ` +
        `<td>${payment.boletovalorliquidado}</td>  ` +
        `<td>${payment.dataPagamento
          ? manipulateDates(`${payment.dataPagamento}`)
          : ''
        }</td>  ` +
        `</tr>`;

      total += payment.valor
        ? parseFloat(String(payment.valor).replace(',', '.'))
        : 0;

      if (payment.dataPagamento) {
        if (parseFloat(String(payment.boletovalorliquidado).replace(',', '.')) > 0) {
          totalLiquidado += parseFloat(String(payment.boletovalorliquidado).replace(',', '.'));
        } else {
          totalLiquidado += payment.valor
            ? parseFloat(String(payment.valor).replace(',', '.'))
            : 0;
        }
      }
      return true;
    });

    content =
      `${content}<tr> ` +
      `<td></td>  ` +
      `<td></td>  ` +
      `<td></td>  ` +
      `<td></td>  ` +
      `<td><b>TOTAL</b></td>  ` +
      `<td>${String(total.toFixed(2)).replace('.', ',')}</td>  ` +
      `<td>${String(totalLiquidado.toFixed(2)).replace('.', ',')}</td>  ` +
      `<td></td>  ` +
      `</tr>`;

    report('RELAÇÃO DE CONTAS A RECEBER', header, content);
  }

  function handleDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/receipts/${id}`);
          loadRegisters()
          addToast('Recebimento deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o recebimento, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        setDocumento('1');
        setDocumento('');
      },
      'Tem certeza que deseja excluir o recebimento ?',
      'delete',
    );
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/receipts/register/${id}/1`);
  }

  async function handlePay(id?: string) {
    saveFilters();
    navigate(`/receipts/register/${id}/2`);
  }

  async function handleUndoPay(id: string) {
    Confirmation(
      async () => {
        try {
          const paymentRequest: any = await api.get(`/receipts/${id}`);
          const paymentEntity = paymentRequest.data;
          delete paymentEntity.id;
          delete paymentEntity.created_at;
          delete paymentEntity.updated_at;
          delete paymentEntity.parcela;
          delete paymentEntity.emailEnviado;
          paymentEntity.dataPagamento = null;

          await api.put(`/receipts/${id}`, paymentEntity);

          setDocumento('1');
          setDocumento('');

          addToast('Recebimento estornado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível estornar o recebimento, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja estornar o recebimento ?',
      'delete',
    );
  }

  function handleSendEmailSubject() {
    if (selectedRows.length === 0) {
      addToast('Selecione ao menos um registro', {
        appearance: 'error',
        autoDismiss: true,
      });
    } else {
      removeSelectedRecords();
      saveSelectedRecords();
      navigate('/receipts/emailSubject/send');
    }
  }

  function handleInsert() {
    saveFilters();
    navigate('/receipts/register');
  }

  function handleLimitColor(payment: Date, limit: Date): string {
    const limitDate: Date = parseISO(`${limit}`);
    if (payment === null) {
      if (
        isSameDay(limitDate, new Date()) &&
        isSameMonth(limitDate, new Date()) &&
        isSameYear(limitDate, new Date())
      ) {
        return '#668B8B';
      }
      if (isAfter(new Date(), limitDate)) {
        return '#a53234';
      }
    }
    return '#888888';
  }

  function handlePaymentColor(payment: Date): string {
    const paymentDate: Date = parseISO(`${payment}`);
    if (paymentDate !== null) {
      return '#12522D';
    }
    return '#888888';
  }

  function handleInsertChargingInvoices() {
    saveFilters();
    navigate('/receipts/register/chargingInvoice');
  }

  function handleReportComissions() {
    saveFilters();
    navigate('/receipts/report/comissions');
  }

  async function handlePrintChargingInvoices(
    paymentSlips: PaymentSlipReport[],
    reportName: string,
  ) {
    const header =
      '<th>NOSSONUMERO</th><th>RAZÃO SOCIAL</th><th>FANTASIA</th><th>VENCIMENTO</th><th>PAGAMENTO</th><th>VALOR</th><th>VALOR LIQUIDADO</th>';
    let content = '';
    paymentSlips.map(
      // eslint-disable-next-line no-return-assign
      paymentSlip =>
      (content =
        `${content}<tr key=${paymentSlip.boletonossonumero}> ` +
        `<td>${paymentSlip.boletonossonumero}</td>  ` +
        `<td>${paymentSlip.razaosocial ? paymentSlip.razaosocial : ''
        }</td>  ` +
        `<td>${paymentSlip.fantasia ? paymentSlip.fantasia : ''}</td>  ` +
        `<td>${paymentSlip.datavencimento
          ? manipulateDates(String(paymentSlip.datavencimento))
          : ''
        }</td>  ` +
        `<td>${paymentSlip.datapagamento
          ? manipulateDates(String(paymentSlip.datapagamento))
          : ''
        }</td>  ` +
        `<td>${paymentSlip.valor ? manipulateFloats(paymentSlip.valor) : ''
        }</td>  ` +
        `<td>${paymentSlip.boletovalorliquidado
          ? manipulateFloats(paymentSlip.boletovalorliquidado)
          : ''
        }</td>  ` +
        `</tr>`),
    );
    await report(reportName, header, content);
  }

  async function handleReportChargingInvoices() {
    try {
      setLoading(true);
      const response = await api.get(`/receiptReturns/`);

      if (response.data) {
        await handlePrintChargingInvoices(
          response.data as PaymentSlipReport[],
          `RELAÇÃO DE BOLETOS LIQUIDADOS`,
        );
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let messageErrror: string;
      if (error.response) {
        if (error.response.data.message.mensagem) {
          messageErrror = error.response.data.message.mensagem;
        } else {
          messageErrror = error.response.data.message;
        }
      } else {
        messageErrror = error;
      }
      addToast(messageErrror, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handleUpdateChargingInvoices() {
    try {
      setLoading(true);

      await api.delete('/receiptReturns/');

      const response = await api.get('/invoiceSettings/');
      const invoiceSettings: InvoiceSetting[] = response.data;
      if (invoiceSettings.length > 0) {
        for (const invoiceSetting of invoiceSettings) {
          api.put(`/receipts/paymentSlip/${invoiceSetting.id}`);
        }
      }

      setLoading(false);

      addToast(
        'O processo de atualização da situação dos boletos foi iniciado.',
        {
          appearance: 'info',
          autoDismiss: true,
        },
      );
    } catch (error: any) {
      setLoading(false);
      let messageErrror: string;
      if (error.response) {
        if (error.response.data.message.mensagem) {
          messageErrror = error.response.data.message.mensagem;
        } else {
          messageErrror = error.response.data.message;
        }
      } else {
        messageErrror = error;
      }
      addToast(messageErrror, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handlePrintPayment(id: string) {
    try {
      const response = await api.get(`/receipts/paymentSlip/${id}`);
      window
        .open('blank', '_blank')
        ?.document.write(
          `<iframe src="data:application/pdf;base64,${response.data.arquivo}" style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"></iframe>`,
        );
    } catch (error: any) {
      let messageErrror: string;
      if (error.response) {
        if (error.response.data.message.mensagem) {
          messageErrror = error.response.data.message.mensagem;
        } else {
          messageErrror = error.response.data.message;
        }
      } else {
        messageErrror = error;
      }
      addToast(messageErrror, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handleCancelChargingInvoices(id: string) {
    Confirmation(
      async () => {
        const cancel = [id];
        try {
          const reponse = await api.post(
            `/receipts/paymentSlip/cancel`,
            cancel,
          );

          setDocumento('1');
          setDocumento('');

          addToast(reponse.data.message, {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (error: any) {
          let messageErrror: string;
          if (error.response) {
            if (error.response.data.message.mensagem) {
              messageErrror = error.response.data.message.mensagem;
            } else {
              messageErrror = error.response.data.message;
            }
          } else {
            messageErrror = error;
          }
          addToast(messageErrror, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja cancelar o boleto ?',
      'delete',
    );
  }

  async function handleSelected(id?: string, checkStatus?: boolean) {
    let selectedList = selectedRows;
    if (id === '') {
      selectedList = [];
      entity
        // .slice(handlePagination(1), handlePagination(2))
        .map(function loop(e) {
          selectedList.push(e.id || '');
          const element: any = document.getElementById(e.id || '');
          if (element !== null) element.checked = checkStatus;
          return true;
        });
      if (checkStatus === false) {
        selectedList = [];
      }
    } else if (checkStatus) {
      selectedList.push(id || '');
    } else {
      const index = selectedList.indexOf(id || '');
      selectedList.splice(index, 1);
    }
    setSelectedRows(selectedList);
  }

  async function handleEmitChargingInvoices() {
    if (selectedRows.length === 0) {
      addToast('Selecione pelo menos um boleto para emitir', {
        appearance: 'error',
        autoDismiss: true,
      });
      return false;
    }

    Confirmation(
      async () => {
        try {
          setLoading(true);
          const response = await api.post(
            '/receipts/paymentSlip',
            selectedRows,
          );
          setLoading(false);
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (error: any) {
          setLoading(false);
          let messageErrror: string;
          if (error.response) {
            if (error.response.data.message.mensagem) {
              messageErrror = error.response.data.message.mensagem;
            } else {
              messageErrror = error.response.data.message;
            }
          } else {
            messageErrror = error;
          }
          addToast(messageErrror, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        setLoading(false);

        // await load();
        setDocumento('1');
        setDocumento('');

        // Clear all the checkboxs selected
        handleSelected('', false);
        const element: any = document.getElementById('checkboxGlobal');
        if (element !== null) element.checked = false;
        setSelectedRows([]);
      },
      `Tem certeza que deseja emitir o(s) ${selectedRows.length} boletos(s) selecionado(s) ?`,
      'abort',
    );
    return true;
  }

  async function handlSendMailChargingInvoices() {
    if (selectedRows.length === 0) {
      addToast('Selecione pelo menos um boleto para enviar por email', {
        appearance: 'error',
        autoDismiss: true,
      });
      return false;
    }

    Confirmation(
      async () => {
        try {
          setLoading(true);
          const response = await api.post(
            '/receipts/paymentSlip/sendEmail',
            selectedRows,
          );
          setLoading(false);
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (error: any) {
          setLoading(false);
          let messageErrror: string;
          if (error.response) {
            if (error.response.data.message.mensagem) {
              messageErrror = error.response.data.message.mensagem;
            } else {
              messageErrror = error.response.data.message;
            }
          } else {
            messageErrror = error;
          }
          addToast(messageErrror, {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        // await load();
        setDocumento('1');
        setDocumento('');

        // Clear all the checkboxs selected
        handleSelected('', false);
        const element: any = document.getElementById('checkboxGlobal');
        if (element !== null) element.checked = false;
        setSelectedRows([]);
      },
      `Tem certeza que deseja enviar por email o(s) ${selectedRows.length} boletos(s) selecionado(s) ?`,
      'abort',
    );
    return true;
  }

  return (
    <Container>
      <Title>
        <h1>Listagem de Contas a receber</h1>
      </Title>
      <Buttons>
        <Button onClick={handleInsert}>
          <FaPlus size={18} color="#faede8" />
          Adicionar
        </Button>
        <Button visual="secondary" onClick={handlePrint}>
          <FaClipboardList size={18} color="#a53234" />
          Imprimir
        </Button>
        <Button visual="secondary" onClick={handleSendEmailSubject}>
          <FiMail size={18} color="#a53234" />
          Email
        </Button>

        <Dropdown name="Boletos">
          <button type="button" onClick={handleInsertChargingInvoices}>
            <FaPlus size={18} color="#a53234" />
            Gerar Cobranças do Mês
          </button>
          <button type="button" onClick={handleEmitChargingInvoices}>
            <FaClipboardList size={18} color="#a53234" />
            Emitir
          </button>
          <button type="button" onClick={() => handleUpdateChargingInvoices()}>
            <FaSearch size={18} color="#a53234" />
            Atualizar Situação
          </button>
          <button type="button" onClick={() => handlSendMailChargingInvoices()}>
            <FiMail size={18} color="#a53234" />
            Enviar por Email
          </button>
          <button type="button" onClick={() => handleReportChargingInvoices()}>
            <FaClipboardList size={18} color="#a53234" />
            Relatório Liquidados
          </button>
        </Dropdown>

        <Dropdown name="Relatórios">
          <button type="button" onClick={handleReportComissions}>
            <FaClipboardList size={18} color="#a53234" />
            Relatório de comissões
          </button>
        </Dropdown>
      </Buttons>
      <Filters>
        <FilterDiv>
          <FilterContentDiv>
            <CustomFiltersLine first>
              <FiltersFieldBetween size="35%">
                <Select
                  name="tipo"
                  height={40}
                  maxMenuHeight={300}
                  placeholder="Tipo"
                  onChange={(e: any) => {
                    // eslint-disable-next-line no-unused-expressions
                    if (e !== null) {
                      setTipoData(e.value);
                    }
                  }}
                  defaultValue={tipoData}
                  options={selectTipoDatas}
                />
                <div>
                  <DatePicker
                    error={false}
                    onChange={(e: any) => {
                      setDataIncial(e);
                    }}
                    placeholderText="Data inicial"
                    selected={dataInicial}
                  />
                </div>
                <h3>a</h3>
                <div>
                  <DatePicker
                    error={false}
                    onChange={(e: any) => {
                      setDataFinal(e);
                    }}
                    placeholderText="Data final"
                    selected={dataFinal}
                  />
                </div>
              </FiltersFieldBetween>
              <FiltersField size="20%">
                <input
                  value={cliente}
                  onChange={e => setCliente((e.target.value as any) || '')}
                  id="cliente"
                  placeholder="Filtre pelo cliente"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      loadRegisters();
                    }
                  }}
                />
              </FiltersField>
              <FiltersField size="15%">
                <input
                  value={documento}
                  onChange={e => setDocumento((e.target.value as any) || '')}
                  id="documento"
                  placeholder="Filtre pelo documento"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      loadRegisters();
                    }
                  }}
                />
              </FiltersField>
              <FiltersField size="30%">
                <Select
                  name="status"
                  height={40}
                  clear
                  maxMenuHeight={300}
                  placeholder="Status"
                  onChange={(e: any) => {
                    // eslint-disable-next-line no-unused-expressions
                    if (e !== null) {
                      setStatus(e.value);
                    } else {
                      setStatus('');
                    }
                  }}
                  defaultValue={status}
                  options={selectStatus}
                />
              </FiltersField>
            </CustomFiltersLine>
            <CustomFiltersLine second>
              <FiltersField size="35%">
                <Select
                  name="conta"
                  height={40}
                  clear
                  maxMenuHeight={300}
                  placeholder="Filtre pelas contas"
                  onChange={(e: any) => {
                    // eslint-disable-next-line no-unused-expressions
                    if (e !== null) {
                      setConta(e.value);
                    } else {
                      setConta('');
                    }
                  }}
                  defaultValue={conta}
                  options={selectContas}
                />
              </FiltersField>
              <FiltersField size="20%">
                <input
                  value={descricao}
                  onChange={e => setDescricao((e.target.value as any) || '')}
                  id="descricao"
                  placeholder="Filtre pela descrição"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      loadRegisters();
                    }
                  }}
                />
              </FiltersField>

              <FiltersField size="22.5%">
                <Select
                  name="contaBancaria"
                  height={40}
                  clear
                  maxMenuHeight={300}
                  placeholder="Filtre pelas contas bancarias"
                  onChange={(e: any) => {
                    // eslint-disable-next-line no-unused-expressions
                    if (e !== null) {
                      setContaBancaria(e.value);
                    } else {
                      setContaBancaria('');
                    }
                  }}
                  defaultValue={contaBancaria}
                  options={selectContasBancarias}
                />
              </FiltersField>
              <FiltersField size="22.5%">
                <Select
                  name="emailEnviado"
                  height={40}
                  clear
                  maxMenuHeight={300}
                  placeholder="Filtre pelo status do e-mail"
                  onChange={(e: any) => {
                    // eslint-disable-next-line no-unused-expressions
                    if (e !== null) {
                      setEmailEnviado(e.value);
                    } else {
                      setEmailEnviado('');
                    }
                  }}
                  defaultValue={emailEnviado}
                  options={selectEmailEnviado}
                />
              </FiltersField>
            </CustomFiltersLine>
          </FilterContentDiv>
          <FilterButtonDiv>
            <FilterButton
              tooltipDescription='Filtrar Contas A Receber'
              onClick={loadRegisters}
            />
          </FilterButtonDiv>
        </FilterDiv>
      </Filters>
      <Legends>
        <Legend backgroundColor="#2e2c2c" fontColor="#668B8B">
          Vence hoje
        </Legend>
        <Legend backgroundColor="#2e2c2c" fontColor="#a53234">
          Vencidas
        </Legend>
        <Legend backgroundColor="#2e2c2c" fontColor="#12522D">
          Recebidas
        </Legend>
      </Legends>
      <Tables withLegend>
        {loading ? (
          <FormLoading>
            <ClipLoader size={30} color="#FFFFFF" loading={loading} />
          </FormLoading>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      id="checkboxGlobal"
                      onChange={checked => {
                        handleSelected('', checked.target.checked);
                      }}
                    />
                  </th>
                  <th>
                    <div>
                      Vcto
                      <button
                        onClick={() => {
                          orderBy('dataVencimento');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('dataVencimento', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('dataVencimento', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Doc.
                      <button
                        onClick={() => {
                          orderBy('documento');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('documento', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('documento', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Cliente
                      <button
                        onClick={() => {
                          orderBy('cliente');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('cliente', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('cliente', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Descrição
                      <button
                        onClick={() => {
                          orderBy('descricao');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('descricao', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('descricao', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Parc.
                      <button
                        onClick={() => {
                          orderBy('parcela');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('parcela', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('parcela', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Valor
                      <button
                        onClick={() => {
                          orderBy('valor');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('valor', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('valor', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Pgto.
                      <button
                        onClick={() => {
                          orderBy('dataPagamento');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('dataPagamento', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('dataPagamento', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Situação
                      <button
                        onClick={() => {
                          orderBy('boletoSituacao');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('boletoSituacao', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('boletoSituacao', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Nosso Número
                      <button
                        onClick={() => {
                          orderBy('boletoNossoNumero');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('boletoNossoNumero', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('boletoNossoNumero', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {entity.length > 0 &&
                  entity
                    .slice(handlePagination(1), handlePagination(2))
                    .map((e, index) => (
                      <tr key={index}>
                        <TableTd center width="6%">
                          <input
                            id={e.id}
                            type="checkbox"
                            onChange={checked => {
                              handleSelected(e.id, checked.target.checked);
                            }}
                          />
                        </TableTd>
                        <TableTd
                          color={handleLimitColor(
                            e.dataPagamento,
                            e.dataVencimento,
                          )}
                          width="5.5%"
                        >
                          {manipulateDates(`${e.dataVencimento}`)}
                        </TableTd>
                        <TableTd width="7.5%">{e.documento}</TableTd>
                        <TableTd width="20%">{e.cliente}</TableTd>
                        <TableTd width="20%">{e.descricao}</TableTd>
                        <TableTd width="6.0%">
                          {`${e.parcela}/${e.parcelaFinal}`}
                        </TableTd>
                        <TableTd width="6%">{e.valor}</TableTd>
                        <TableTd
                          color={handlePaymentColor(e.dataPagamento)}
                          width="5.5%"
                        >
                          {e.dataPagamento &&
                            manipulateDates(`${e.dataPagamento}`)}
                        </TableTd>
                        <TableTd width="7.5%">{e.boletoSituacao}</TableTd>
                        <TableTd width="5.5%">{e.boletoNossoNumero}</TableTd>
                        <TableTd center width="10.5%">
                          <button
                            type="button"
                            onClick={() => handleEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            disabled={!!e.dataPagamento}
                            onClick={() => handleDelete(e.id)}
                          >
                            <FiTrash
                              size={20}
                              opacity={e.dataPagamento && 0.2}
                              color="#d13337"
                            />
                          </button>
                          <button
                            disabled={
                              e.boletoNossoNumero == null ||
                              e.boletoSituacao === 'BAIXADO POR SOLICITACAO' ||
                              e.boletoSituacao === 'LIQUIDADO' ||
                              e.boletoSituacao === 'LIQUIDADO CARTORIO' ||
                              e.boletoSituacao === 'LIQUIDADO REDE' ||
                              e.boletoSituacao === 'LIQUIDADO COMPE' ||
                              e.boletoSituacao === 'LIQUIDADO PIX' ||
                              e.boletoSituacao === 'LIQUIDADO CHEQUE'
                            }
                            title="Imprimir Boleto Sicredi"
                            type="button"
                            onClick={async () => {
                              await handlePrintPayment(e.id);
                            }}
                          >
                            <FiPrinter
                              opacity={e.boletoNossoNumero == null ||
                                e.boletoSituacao === 'BAIXADO POR SOLICITACAO' ||
                                e.boletoSituacao === 'LIQUIDADO' ||
                                e.boletoSituacao === 'LIQUIDADO CARTORIO' ||
                                e.boletoSituacao === 'LIQUIDADO REDE' ||
                                e.boletoSituacao === 'LIQUIDADO COMPE' ||
                                e.boletoSituacao === 'LIQUIDADO PIX' ||
                                e.boletoSituacao === 'LIQUIDADO CHEQUE' ? 0.2 : 1}
                              size={20}
                              color="#668B8B"
                            />
                          </button>
                          <button
                            disabled={
                              e.boletoNossoNumero == null ||
                              e.boletoSituacao === 'BAIXADO POR SOLICITACAO' ||
                              e.boletoSituacao === 'LIQUIDADO' ||
                              e.boletoSituacao === 'LIQUIDADO CARTORIO' ||
                              e.boletoSituacao === 'LIQUIDADO REDE' ||
                              e.boletoSituacao === 'LIQUIDADO COMPE' ||
                              e.boletoSituacao === 'LIQUIDADO PIX' ||
                              e.boletoSituacao === 'LIQUIDADO CHEQUE'
                            }
                            title="Cancelar Boleto"
                            type="button"
                            onClick={async () => {
                              await handleCancelChargingInvoices(e.id);
                            }}
                          >
                            <FiZapOff
                              opacity={
                                e.boletoNossoNumero == null ||
                                  e.boletoSituacao === 'BAIXADO POR SOLICITACAO' ||
                                  e.boletoSituacao === 'LIQUIDADO' ||
                                  e.boletoSituacao === 'LIQUIDADO CARTORIO' ||
                                  e.boletoSituacao === 'LIQUIDADO REDE' ||
                                  e.boletoSituacao === 'LIQUIDADO COMPE' ||
                                  e.boletoSituacao === 'LIQUIDADO PIX' ||
                                  e.boletoSituacao === 'LIQUIDADO CHEQUE' ? 0.2 : 1
                              }
                              size={20}
                              color="#d13337"
                            />
                          </button>
                          <ButtonPaymentLine>
                            <ButtonPayments
                              type="button"
                              disabled={!!e.dataPagamento}
                              onClick={() => handlePay(e.id)}
                              color="#12522D"
                            >
                              <FaCoins size={14} color="#fafafa" />
                              <h3>Pagar</h3>
                            </ButtonPayments>
                            <ButtonPayments
                              type="button"
                              disabled={e.dataPagamento === null}
                              onClick={() => handleUndoPay(e.id)}
                              color="#a53234"
                            >
                              <FaCoins size={14} color="#fafafa" />
                              <h3>Estornar</h3>
                            </ButtonPayments>
                          </ButtonPaymentLine>
                        </TableTd>
                      </tr>
                    ))}
              </tbody>
            </table>
            <TablesPagination>
              <span>
                {`${entity.length > 0 ? entity.length : 0
                  } registro(s) encontrado(s)`}
              </span>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    if (actualPage > 1) {
                      setActualPage(actualPage - 1);
                      handleSelected('', false);
                      const element: any = document.getElementById(
                        'checkboxGlobal',
                      );
                      if (element !== null) element.checked = false;
                      setSelectedRows([]);
                    }
                  }}
                >
                  <FaArrowLeft
                    size={30}
                    color={actualPage <= 1 ? '#585858' : '#a53234'}
                  />
                </button>
                <strong>{`${actualPage}/${pages}`}</strong>
                <button
                  type="button"
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    if (actualPage !== pages) {
                      setActualPage(actualPage + 1);
                      handleSelected('', false);
                      const element: any = document.getElementById(
                        'checkboxGlobal',
                      );
                      if (element !== null) element.checked = false;
                      setSelectedRows([]);
                    }
                  }}
                >
                  <FaArrowRight
                    size={30}
                    color={pages === actualPage ? '#585858' : '#a53234'}
                  />
                </button>
              </div>
            </TablesPagination>
          </>
        )}
      </Tables>
    </Container>
  );
};

export default Receipt;
