/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { format, parseISO } from 'date-fns';

interface ObjectProps {
  [key: string]: string;
}

interface OrderProps {
  field: string;
  desc: boolean;
}

export function manipulateFloats(value: number) {
  if (value === null || String(value) === '') {
    return null;
  }
  const valueString = String(value.toFixed(2));
  if (valueString.indexOf('.') > 0) {
    return valueString.replace('.', ',');
  }
  return `${valueString},00`;
}

export function manipulateDates(date: string) {
  if (date === null || date === '') {
    return null;
  }
  return format(parseISO(date), 'dd/MM/yyyy');
}

export function manipulateDateHour(date: string) {
  if (date === null || date === '') {
    return null;
  }
  return format(parseISO(date), 'dd/MM/yyyy HH:mm:ss');
}

export async function orderBy(
  object: any,
  order: OrderProps,
): Promise<ObjectProps> {
  let obj: any = {};

  obj = order.desc
    ? await object.sort(
        (a: any, b: any) =>
          // a[order.field] > b[order.field] ? -1 : 1,
          <any>(a[order.field] === null) - <any>(b[order.field] === null) ||
          -(a[order.field] > b[order.field]) ||
          +(a[order.field] < b[order.field]),
      )
    : await object.sort(
        (a: any, b: any) =>
          // a[order.field] < b[order.field] ? -1 : 1,
          <any>(a[order.field] === null) - <any>(b[order.field] === null) ||
          +(a[order.field] > b[order.field]) ||
          -(a[order.field] < b[order.field]),
      );

  return obj;
}
