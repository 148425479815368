export function setFilters(
  filters: Record<string, unknown>,
  page: string,
): void {
  localStorage.setItem(
    `@3rsistemas-gerencial:filters_${page}`,
    JSON.stringify(filters),
  );
}

export function getFilters(page: string): Record<string, unknown> {
  const filters =
    localStorage.getItem(`@3rsistemas-gerencial:filters_${page}`) || '';
  return filters !== '' ? JSON.parse(filters) : {};
}

export function removeFilters(page: string): void {
  localStorage.removeItem(`@3rsistemas-gerencial:filters_${page}`);
}
