import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  format,
  parseISO,
  isAfter,
  isSameDay,
  isSameMonth,
  isSameYear,
} from 'date-fns';

import {
  FaPlus,
  FaClipboardList,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaCoins,
} from 'react-icons/fa';
import { FiBookOpen, FiEdit, FiTrash } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import Select from '../../components/Select';
import Confirmation from '../../components/Confirmation';
import DatePicker from '../../components/DatePicker';
import Dropdown from '../../components/Dropdown';

import api from '../../services/api';

import { report } from '../../utils/printReport';
import { changeNamesOfColumns } from '../../utils/handleSelects';
import {
  orderBy as handleOrderBy,
  manipulateDates,
  manipulateFloats,
} from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersLine,
  FiltersField,
  FiltersFieldBetween,
  Tables,
  Legends,
  Legend,
  TableTd,
  TablesPagination,
} from '../../styles/lists';

import { ButtonPaymentLine, ButtonPayments } from './styles';
import FilterButton from '../../components/FilterButton';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id: string;
  dataVencimento: Date;
  documento: string;
  fornecedor: string;
  fornecedorId: string;
  descricao: string;
  parcela: string;
  parcelaFinal: string;
  conta: string;
  valor: number;
  dataPagamento: Date;
}

const Payment: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityParams[]>([]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'dataVencimento',
    desc: true,
  });
  const [accountPartner, setAccountPartner] = useState();

  const filters: any = getFilters('payments');

  const [fornecedor, setFornecedor] = useState(
    Object.keys(filters).length > 0 ? filters.fornecedor : '',
  );
  const [documento, setDocumento] = useState(
    Object.keys(filters).length > 0 ? filters.documento : '',
  );
  const [descricao, setDescricao] = useState(
    Object.keys(filters).length > 0 ? filters.descricao : '',
  );
  const [conta, setConta] = useState<string>(
    Object.keys(filters).length > 0 ? filters.conta : '',
  );
  const [tipoData, setTipoData] = useState(
    Object.keys(filters).length > 0 ? filters.tipoData : 'created_at',
  );
  const [dataInicial, setDataIncial] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataInicial !== null
      ? parseISO(filters.dataInicial)
      : null,
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataFinal !== null
      ? parseISO(filters.dataFinal)
      : null,
  );

  function saveFilters() {
    setFilters(
      {
        fornecedor,
        documento,
        descricao,
        conta,
        tipoData,
        dataInicial,
        dataFinal,
      },
      'payments',
    );
  }

  const [selectContas, setSelectContas] = useState([]);
  const [selectTipoDatas] = useState([
    {
      label: 'Digitação',
      value: 'created_at',
    },
    {
      label: 'Vencimento',
      value: 'dataVencimento',
    },
    {
      label: 'Pagamento',
      value: 'dataPagamento',
    },
  ]);

  async function loadRegisters() {
    const delayDebounceFn = setTimeout(async () => {
      try {
        const response = await api.get('/payments', {
          params: {
            fornecedor,
            documento,
            descricao,
            conta,
            tipoData,
            dataInicial: dataInicial && format(dataInicial, 'yyyy-MM-dd'),
            dataFinal: dataFinal && format(dataFinal, 'yyyy-MM-dd'),
          },
        });

        const responseDisassembled = await response.data.map((item: any) => {
          return {
            id: item.id,
            dataVencimento: item.dataVencimento,
            documento: item.documento,
            fornecedor: item.fornecedores.razaoSocial,
            fornecedorId: item.fornecedor,
            descricao: item.descricao,
            parcela: item.parcela,
            parcelaFinal: item.parcelaFinal,
            conta: item.conta,
            valor: manipulateFloats(item.valor),
            dataPagamento: item.dataPagamento,
          };
        });
        const responseOrdered: any = await handleOrderBy(
          responseDisassembled,
          order,
        );
        setEntity(responseOrdered);
        setPages(Math.ceil(response.data.length / 10));
        setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
      } catch (err) {
        setEntity([]);
        setPages(1);
        setActualPage(1);
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }

  useEffect(() => {
    loadRegisters();
  }, [
    // fornecedor,
    // documento,
    // descricao,
    conta,
    tipoData,
    dataInicial,
    dataFinal,
    order,
  ]);

  useEffect(() => {
    async function loadAccounts() {
      const response = await api.get('/cashPlaceAccounts', {
        params: {
          tipo: 'D',
        },
      });
      setSelectContas(changeNamesOfColumns(response.data, 'descricao', 'id'));
    }
    loadAccounts();

    async function loadAccountPartner() {
      const response = await api.get('/financeSettings');
      setAccountPartner(response.data[0].contaparceiro);
    }
    loadAccountPartner();

    setDocumento('1');
    setDocumento('');
  }, []);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#a53234' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  function handlePrint() {
    const header =
      '<th>VENCIMENTO</th><th>DOCUMENTO</th><th>FORNECEDOR</th><th>DESCRIÇÃO</th><th>PARCELA</th><th>VALOR</th><th>PAGAMENTO</th>';
    let content = '';
    let total = 0;
    entity.map(payment => {
      content =
        `${content}<tr key=${payment.id}> ` +
        `<td>${manipulateDates(`${payment.dataVencimento}`)}</td>  ` +
        `<td>${payment.documento ? payment.documento : ''}</td>  ` +
        `<td>${payment.fornecedor}</td>  ` +
        `<td>${payment.descricao ? payment.descricao : ''}</td>  ` +
        `<td>${payment.parcela}/${payment.parcelaFinal}</td>  ` +
        `<td>${payment.valor}</td>  ` +
        `<td>${payment.dataPagamento
          ? manipulateDates(`${payment.dataPagamento}`)
          : ''
        }</td>  ` +
        `</tr>`;
      total += payment.valor
        ? parseFloat(String(payment.valor).replace(',', '.'))
        : 0;
      return true;
    });
    content =
      `${content}<tr> ` +
      `<td></td> ` +
      `<td></td> ` +
      `<td></td> ` +
      `<td></td> ` +
      `<td><b>TOTAL</b></td> ` +
      `<td>${String(total.toFixed(2)).replace('.', ',')}</td> ` +
      `<td></td> ` +
      `</tr>`;
    report('RELAÇÃO DE CONTAS A PAGAR', header, content);
  }

  function handleDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/payments/${id}`);

          setDocumento('1');
          setDocumento('');

          addToast('Pagamento deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o pagamento, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o pagamento ?',
      'delete',
    );
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/payments/register/${id}/1`);
  }

  async function handlePay(id?: string) {
    saveFilters();
    navigate(`/payments/register/${id}/2`);
  }

  async function handleUndoPay(id: string) {
    Confirmation(
      async () => {
        try {
          const paymentRequest: any = await api.get(`/payments/${id}`);
          const paymentEntity = paymentRequest.data;
          delete paymentEntity.id;
          delete paymentEntity.created_at;
          delete paymentEntity.updated_at;
          delete paymentEntity.parcela;
          paymentEntity.dataPagamento = null;
          await api.put(`/payments/${id}`, paymentEntity);

          setDocumento('1');
          setDocumento('');

          addToast('Pagamento estornado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível estornar o pagamento, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja estornar o pagamento ?',
      'delete',
    );
  }

  function handleInsert() {
    saveFilters();
    navigate('/payments/register');
  }

  function handleLimitColor(payment: Date, limit: Date): string {
    const limitDate: Date = parseISO(`${limit}`);
    if (payment === null) {
      if (
        isSameDay(limitDate, new Date()) &&
        isSameMonth(limitDate, new Date()) &&
        isSameYear(limitDate, new Date())
      ) {
        return '#668B8B';
      }
      if (isAfter(new Date(), limitDate)) {
        return '#a53234';
      }
    }
    return '#888888';
  }

  function handlePaymentColor(payment: Date): string {
    const paymentDate: Date = parseISO(`${payment}`);
    if (paymentDate !== null) {
      return '#12522D';
    }
    return '#888888';
  }

  function handleInsertChargingPartners() {
    saveFilters();
    navigate('/payments/register/chargingPartner');
  }

  async function handlePrintPartner(
    fornecedorNome: string,
    fornecedorId: string,
    dataVencimento: string,
  ) {
    const mesAno = `${dataVencimento.substring(
      5,
      7,
    )}/${dataVencimento.substring(0, 4)}`;

    const response = await api.get('/chargingPartnerComissionMonth', {
      params: {
        mesAno,
        parceiro: fornecedorId,
      },
    });

    const header =
      '<th>VENCIMENTO</th><th>PAGAMENTO</th><th>CLIENTE</th><th>VALOR</th>';
    let content = '';
    let total = 0;

    content = `${content}
      <p>MÊS/ANO: ${mesAno}</p>`;

    content = `${content}
      <p>FORNECEDOR: ${fornecedorNome}</p>`;

    response.data.map((p: any) => {
      content =
        `${content}<tr key=${p.id}> ` +
        `<td>${manipulateDates(`${p.datavencimento}`)}</td> ` +
        `<td>${manipulateDates(`${p.datapagamento}`)}</td> ` +
        `<td>${p.fantasia}</td> ` +
        `<td>${p.valor.toFixed(2)}</td> ` +
        `</tr>`;
      total += p.valor ? parseFloat(String(p.valor).replace(',', '.')) : 0;
      return true;
    });
    content =
      `${content}<tr> ` +
      `<td></td> ` +
      `<td></td> ` +
      `<td><b>TOTAL</b></td> ` +
      `<td>${String(total.toFixed(2)).replace('.', ',')}</td> ` +
      `</tr>`;
    report(`RELAÇÃO COMISSÕES DO PARCEIRO ${fornecedorNome}`, header, content);
  }

  return (
    <Container>
      <Title>
        <h1>Listagem de Contas a pagar</h1>
      </Title>
      <Buttons>
        <Button onClick={handleInsert}>
          <FaPlus size={18} color="#faede8" />
          Adicionar
        </Button>
        <Button visual="secondary" onClick={handlePrint}>
          <FaClipboardList size={18} color="#a53234" />
          Imprimir
        </Button>
        <Dropdown name="Parceiros">
          <button type="button" onClick={handleInsertChargingPartners}>
            <FaPlus size={18} color="#a53234" />
            Gerar Comissões do Mês
          </button>
        </Dropdown>
      </Buttons>
      <Filters>
        <FiltersLine>
          <FiltersFieldBetween size="35%">
            <Select
              name="tipo"
              height={40}
              maxMenuHeight={300}
              placeholder="Tipo"
              onChange={(e: any) => {
                // eslint-disable-next-line no-unused-expressions
                if (e !== null) {
                  setTipoData(e.value);
                }
              }}
              defaultValue={tipoData}
              options={selectTipoDatas}
            />
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataIncial(e);
                }}
                placeholderText="Data inicial"
                selected={dataInicial}
              />
            </div>
            <h3>a</h3>
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataFinal(e);
                }}
                placeholderText="Data final"
                selected={dataFinal}
              />
            </div>
          </FiltersFieldBetween>
          <FiltersField size="17%">
            <input
              value={fornecedor}
              onChange={e => setFornecedor((e.target.value as any) || '')}
              id="fornecedor"
              placeholder="Filtre pelo fornec."
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  loadRegisters();
                }
              }}
            />
          </FiltersField>
          <FiltersField size="10%">
            <input
              value={documento}
              onChange={e => setDocumento((e.target.value as any) || '')}
              id="documento"
              placeholder="Filtre pelo doc."
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  loadRegisters();
                }
              }}
            />
          </FiltersField>
          <FiltersField size="17%">
            <input
              value={descricao}
              onChange={e => setDescricao((e.target.value as any) || '')}
              id="descricao"
              placeholder="Filtre pela descrição"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  loadRegisters();
                }
              }}
            />
          </FiltersField>
          <FiltersField size="20%">
            <Select
              name="conta"
              height={40}
              clear
              maxMenuHeight={300}
              placeholder="Filtre pelas contas"
              onChange={(e: any) => {
                // eslint-disable-next-line no-unused-expressions
                if (e !== null) {
                  setConta(e.value);
                } else {
                  setConta('');
                }
              }}
              defaultValue={conta}
              options={selectContas}
            />
          </FiltersField>
          <FilterButton
              tooltipDescription='Filtrar Contas A Pagar'
              onClick={loadRegisters}
            />
        </FiltersLine>
      </Filters>
      <Legends>
        <Legend backgroundColor="#2e2c2c" fontColor="#668B8B">
          Vence hoje
        </Legend>
        <Legend backgroundColor="#2e2c2c" fontColor="#a53234">
          Vencidas
        </Legend>
        <Legend backgroundColor="#2e2c2c" fontColor="#12522D">
          Pagas
        </Legend>
      </Legends>
      <Tables withLegend>
        <table>
          <thead>
            <tr>
              <th>
                <div>
                  Vcto
                  <button
                    onClick={() => {
                      orderBy('dataVencimento');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('dataVencimento', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('dataVencimento', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Doc.
                  <button
                    onClick={() => {
                      orderBy('documento');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('documento', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('documento', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Forn.
                  <button
                    onClick={() => {
                      orderBy('fornecedor');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('fornecedor', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('fornecedor', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Descr.
                  <button
                    onClick={() => {
                      orderBy('descricao');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('descricao', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('descricao', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Parc.
                  <button
                    onClick={() => {
                      orderBy('parcela');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('parcela', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('parcela', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Valor
                  <button
                    onClick={() => {
                      orderBy('valor');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('valor', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('valor', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Pgto.
                  <button
                    onClick={() => {
                      orderBy('dataPagamento');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('dataPagamento', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('dataPagamento', true)}
                    />
                  </button>
                </div>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {entity.length > 0 &&
              entity
                .slice(handlePagination(1), handlePagination(2))
                .map((e, index) => (
                  <tr key={index}>
                    <TableTd
                      color={handleLimitColor(
                        e.dataPagamento,
                        e.dataVencimento,
                      )}
                      width="7.5%"
                    >
                      {manipulateDates(`${e.dataVencimento}`)}
                    </TableTd>
                    <TableTd width="7.5%">{e.documento}</TableTd>
                    <TableTd width="20%">{e.fornecedor}</TableTd>
                    <TableTd width="20%">{e.descricao}</TableTd>
                    <TableTd width="7.5%">
                      {`${e.parcela}/${e.parcelaFinal}`}
                    </TableTd>
                    <TableTd width="10%">{e.valor}</TableTd>
                    <TableTd
                      color={handlePaymentColor(e.dataPagamento)}
                      width="7.5%"
                    >
                      {e.dataPagamento && manipulateDates(`${e.dataPagamento}`)}
                    </TableTd>
                    <TableTd center width="23%">
                      <button type="button" onClick={() => handleEdit(e.id)}>
                        <FiEdit size={20} color="#Fafafa" />
                      </button>
                      <button
                        type="button"
                        disabled={!!e.dataPagamento}
                        onClick={() => handleDelete(e.id)}
                      >
                        <FiTrash
                          size={20}
                          opacity={e.dataPagamento && 0.2}
                          color="#d13337"
                        />
                      </button>
                      <button
                        type="button"
                        disabled={!!(e.conta !== accountPartner)}
                        onClick={() => {
                          handlePrintPartner(
                            e.fornecedor,
                            e.fornecedorId,
                            String(e.dataVencimento),
                          );
                        }}
                        title="Relatório de comissões de parceiros"
                      >
                        <FiBookOpen
                          size={20}
                          opacity={e.conta !== accountPartner ? 0.2 : 1}
                          color="#Fafafa"
                        />
                      </button>
                      <ButtonPaymentLine>
                        <ButtonPayments
                          type="button"
                          disabled={!!e.dataPagamento}
                          onClick={() => handlePay(e.id)}
                          color="#12522D"
                        >
                          <FaCoins size={14} color="#fafafa" />
                          <h3>Pagar</h3>
                        </ButtonPayments>
                        <ButtonPayments
                          type="button"
                          disabled={e.dataPagamento === null}
                          onClick={() => handleUndoPay(e.id)}
                          color="#a53234"
                        >
                          <FaCoins size={14} color="#fafafa" />
                          <h3>Estornar</h3>
                        </ButtonPayments>
                      </ButtonPaymentLine>
                    </TableTd>
                  </tr>
                ))}
          </tbody>
        </table>
        <TablesPagination>
          <span>
            {`${entity.length > 0 ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#a53234'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#a53234'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default Payment;
