import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FaPlus, FaRandom } from 'react-icons/fa';
import { FiEdit, FiAlertCircle } from 'react-icons/fi';

import ClipLoader from 'react-spinners/ClipLoader';

import Select from '../../../components/Select';

import api from '../../../services/api';

import { useAuth } from '../../../hooks/AuthContext';

import { changeNamesOfColumns } from '../../../utils/handleSelects';

import {
  Container,
  Title,
  Buttons,
  Button,
  ButtonLink,
  Filters,
  FiltersLine,
  FiltersField,
  FormLoading,
} from '../../../styles/lists';

import { Tables, Table, TableTd } from './styles';

interface EntityParams {
  id?: string;
  created_at?: string;
  cliente?: string;
  prioritaria?: number;
}

const Task: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [loadingAguardando, setLoadingAguardando] = useState(false);
  const [loadingPendente, setLoadingPendente] = useState(false);
  const [loadingAndamento, setLoadingAndamento] = useState(false);
  const [loadingConcluida, setLoadingConcluida] = useState(false);

  const [waitLoad, setWaitLoad] = useState(true);

  const [entityAguardando, setEntityAguardando] = useState<EntityParams[]>([
    {},
  ]);
  const [entityPendente, setEntityPendente] = useState<EntityParams[]>([{}]);
  const [entityAndamento, setEntityAndamento] = useState<EntityParams[]>([{}]);
  const [entityConcluida, setEntityConcluida] = useState<EntityParams[]>([{}]);

  const [cliente, setCliente] = useState('');
  const [colaborador, setColaborador] = useState<string>('');
  const [descricao, setDescricao] = useState('');
  const [descricaoTopico, setDescricaoTopico] = useState('');

  const [selectColaboradores, setSelectColaboradores] = useState([]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (waitLoad === false) {
        setLoadingAguardando(true);
        setLoadingPendente(true);
        setLoadingAndamento(true);
        setLoadingConcluida(true);

        try {
          const response = await api.get('/tasks', {
            params: {
              descricao,
              tarefaTopicoTitulo: descricaoTopico,
              tarefaTopicoColaborador: colaborador,
              clienteNome: cliente,
              status: ['AGUARDANDO'],
            },
          });
          const responseDisassembled = await response.data.map((item: any) => {
            return {
              id: item.id,
              cliente: item.clientes?.fantasia,
              prioritaria: item.prioritaria,
            };
          });
          setEntityAguardando(await responseDisassembled);
          setLoadingAguardando(false);
        } catch (err) {
          setEntityAguardando([{}]);
          setLoadingAguardando(false);
        }

        try {
          const response = await api.get('/tasks', {
            params: {
              descricao,
              tarefaTopicoTitulo: descricaoTopico,
              tarefaTopicoColaborador: colaborador,
              clienteNome: cliente,
              status: ['PENDENTE'],
            },
          });
          const responseDisassembled = await response.data.map((item: any) => {
            return {
              id: item.id,
              cliente: item.clientes?.fantasia,
              prioritaria: item.prioritaria,
            };
          });
          setEntityPendente(await responseDisassembled);
          setLoadingPendente(false);
        } catch (err) {
          setEntityPendente([{}]);
          setLoadingPendente(false);
        }

        try {
          const response = await api.get('/tasks', {
            params: {
              descricao,
              tarefaTopicoTitulo: descricaoTopico,
              tarefaTopicoColaborador: colaborador,
              clienteNome: cliente,
              status: ['EM ANDAMENTO'],
            },
          });
          const responseDisassembled = await response.data.map((item: any) => {
            return {
              id: item.id,
              cliente: item.clientes?.fantasia,
              prioritaria: item.prioritaria,
            };
          });
          setEntityAndamento(await responseDisassembled);
          setLoadingAndamento(false);
        } catch (err) {
          setEntityAndamento([{}]);
          setLoadingAndamento(false);
        }

        try {
          const response = await api.get('/tasks', {
            params: {
              descricao,
              tarefaTopicoTitulo: descricaoTopico,
              tarefaTopicoColaborador: colaborador,
              clienteNome: cliente,
              status: ['CONCLUÍDA'],
            },
          });
          const responseDisassembled = await response.data.map((item: any) => {
            return {
              id: item.id,
              cliente: item.clientes?.fantasia,
              prioritaria: item.prioritaria,
            };
          });
          setEntityConcluida(await responseDisassembled);
          setLoadingConcluida(false);
        } catch (err) {
          setEntityConcluida([{}]);
          setLoadingConcluida(false);
        }
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [cliente, colaborador, descricao, descricaoTopico, waitLoad]);

  useEffect(() => {
    async function loadCollaborator() {
      setWaitLoad(true);
      try {
        const response = await api.get('/collaborators', {
          params: {
            usuario: user.id,
          },
        });
        if (user.nivel === 3) {
          setColaborador(response.data[0].id);
        }
      } catch (err) {
        //
      }
      setWaitLoad(false);
    }
    loadCollaborator();
  }, [user.id, user.nivel]);

  useEffect(() => {
    async function loadCollaborators() {
      const response = await api.get('/collaborators', {
        params: {
          status: 'ativo'
        }
      });
      setSelectColaboradores(changeNamesOfColumns(response.data, 'nome', 'id'));
    }
    loadCollaborators();
  }, []);

  function handleEdit(id?: string) {
    navigate(`/tasks/register/${id}`);
  }

  function handleInsert() {
    navigate('/tasks/register');
  }

  function handleTask() {
    navigate('/tasks');
  }

  return (
    <Container>
      <Title>
        <h1>Painel de Tarefas</h1>
      </Title>
      <Buttons spaceBetween>
        <Button onClick={handleInsert}>
          <FaPlus size={18} color="#faede8" />
          Adicionar
        </Button>
        <ButtonLink onClick={handleTask}>
          <FaRandom size={18} color="#faede8" />
          Trocar para tarefas
        </ButtonLink>
      </Buttons>
      <Filters>
        <FiltersLine>
          <FiltersField size="25%">
            <input
              value={cliente}
              onChange={e => setCliente((e.target.value as any) || '')}
              id="cliente"
              placeholder="Filtre pelo cliente"
            />
          </FiltersField>
          <FiltersField size="25%">
            <input
              value={descricao}
              onChange={e => setDescricao((e.target.value as any) || '')}
              id="descricao"
              placeholder="Filtre pela descrição da tarefa"
            />
          </FiltersField>
          <FiltersField size="25%">
            <input
              value={descricaoTopico}
              onChange={e => setDescricaoTopico((e.target.value as any) || '')}
              id="descricaoTopico"
              placeholder="Filtre pela descrição dos tópicos"
            />
          </FiltersField>
          <FiltersField size="25%">
            <Select
              name="colaborador"
              height={40}
              clear
              maxMenuHeight={300}
              placeholder="Filtre pelo colaborador"
              onChange={(e: any) => {
                // eslint-disable-next-line no-unused-expressions
                if (e !== null) {
                  setColaborador(e.value);
                } else {
                  setColaborador('');
                }
              }}
              defaultValue={colaborador}
              options={selectColaboradores}
            />
          </FiltersField>
        </FiltersLine>
      </Filters>
      <Tables>
        <Table height="390px">
          <h3>AGUARDANDO</h3>
          {loadingAguardando ? (
            <FormLoading>
              <ClipLoader
                size={30}
                color="#FFFFFF"
                loading={loadingAguardando}
              />
            </FormLoading>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>
                      <div>Cliente</div>
                    </th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {'id' in entityAguardando[0] &&
                    entityAguardando.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="90%">
                          <div>
                            {e.prioritaria === 1 ? (
                              <FiAlertCircle size={18} color="#d12637" />
                            ) : (
                              ''
                            )}
                            {e.cliente}
                          </div>
                        </TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </Table>

        <Table height="390px">
          <h3>PENDENTE</h3>
          {loadingPendente ? (
            <FormLoading>
              <ClipLoader size={30} color="#FFFFFF" loading={loadingPendente} />
            </FormLoading>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>
                      <div>Cliente</div>
                    </th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {'id' in entityPendente[0] &&
                    entityPendente.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="90%">
                          <div>
                            {e.prioritaria === 1 ? (
                              <FiAlertCircle size={18} color="#d12637" />
                            ) : (
                              ''
                            )}
                            {e.cliente}
                          </div>
                        </TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </Table>

        <Table height="390px">
          <h3>ANDAMENTO</h3>
          {loadingAndamento ? (
            <FormLoading>
              <ClipLoader
                size={30}
                color="#FFFFFF"
                loading={loadingAndamento}
              />
            </FormLoading>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>
                      <div>Cliente</div>
                    </th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {'id' in entityAndamento[0] &&
                    entityAndamento.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="90%">
                          <div>
                            {e.prioritaria === 1 ? (
                              <FiAlertCircle size={18} color="#d12637" />
                            ) : (
                              ''
                            )}
                            {e.cliente}
                          </div>
                        </TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </Table>
        <Table height="390px">
          <h3>CONCLUÍDA</h3>
          {loadingConcluida ? (
            <FormLoading>
              <ClipLoader
                size={30}
                color="#FFFFFF"
                loading={loadingConcluida}
              />
            </FormLoading>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>
                      <div>Cliente</div>
                    </th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {'id' in entityConcluida[0] &&
                    entityConcluida.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="90%">
                          <div>
                            {e.prioritaria === 1 ? (
                              <FiAlertCircle size={18} color="#d12637" />
                            ) : (
                              ''
                            )}
                            {e.cliente}
                          </div>
                        </TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </Table>
      </Tables>
    </Container>
  );
};

export default Task;
