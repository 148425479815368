import React, { useState } from 'react';

import { FiArrowLeftCircle, FiArrowRightCircle, FiPower } from 'react-icons/fi';
import {
  Container,
  Menu,
  MenuLogo,
  MenuItems,
  MenuProfile,
  MenuExpanded,
  MenuHidden,
  Content,
} from './styles';

import MenuComponent from '../Menu';

import { useAuth } from '../../hooks/AuthContext';
import logo from '../../assets/logo-3r-reddarken.png';
import { ReactComponentProps } from '../../utils/general';

const Panel: React.FC<ReactComponentProps> = ({ children }) => {
  const { user, signOut } = useAuth();
  const [menuExpanded, setMenuExpanded] = useState(true);

  return (
    <Container>
      <Menu expanded={menuExpanded}>
        {menuExpanded === false ? (
          <MenuHidden>
            <button type="button" onClick={() => setMenuExpanded(true)}>
              <FiArrowRightCircle color="#faede8" size={20} />
            </button>
          </MenuHidden>
        ) : (
          <>
            <MenuLogo>
              <img src={logo} alt="logo" />
            </MenuLogo>
            <MenuItems>
              <MenuComponent />
            </MenuItems>
            <MenuProfile>
              <span>seja bem vindo,</span>
              <h1>{String(user.nome).toUpperCase()}</h1>
              <button onClick={() => signOut()} type="button">
                <FiPower size={24} color="#faede8" />
              </button>
            </MenuProfile>
            <MenuExpanded onClick={() => setMenuExpanded(false)} type="button">
              <FiArrowLeftCircle color="#faede8" size={20} />
              Recolher menu
            </MenuExpanded>
          </>
        )}
      </Menu>
      <Content>{children}</Content>
    </Container>
  );
};

export default Panel;
