import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

import { Dropdown, DropdownButton, DropdownContent } from './styles';

const Drop: React.FC<any> = ({ children, name, icon, ...rest }) => (
  <Dropdown type="button" {...rest}>
    <DropdownButton className="dropdown-button">
      <span>{name}</span>
      <FaChevronDown size="18px" />
    </DropdownButton>
    <DropdownContent className="dropdown-content">{children}</DropdownContent>
  </Dropdown>
);

export default Drop;
