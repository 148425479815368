import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO, endOfMonth, startOfMonth } from 'date-fns';

import {
  FaPlus,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaRandom,
} from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';

import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';

import api from '../../services/api';

import {
  manipulateDates,
  orderBy as handleOrderBy,
  manipulateDateHour,
} from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersLine,
  FiltersField,
  FiltersFieldBetween,
  Tables,
  TableTd,
  TablesPagination,
  ButtonLink,
} from '../../styles/lists';
import { changeNamesOfColumns } from '../../utils/handleSelects';
import FilterButton from '../../components/FilterButton';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id?: string;
  dataHora?: string;
  produto?: string;
  produtoVersao?: string;
  cliente?: string;
  descricao?: string;
}

const Failure: React.FC = () => {
  const navigate = useNavigate();

  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'data',
    desc: true,
  });

  const filters: any = getFilters('failures');

  const [produto, setProduto] = useState(
    Object.keys(filters).length > 0 ? filters.produto : '',
  );
  const [produtoVersao, setProdutoVersao] = useState(
    Object.keys(filters).length > 0 ? filters.produtoVersao : '',
  );
  const [cliente, setCliente] = useState(
    Object.keys(filters).length > 0 ? filters.cliente : '',
  );
  const [dataInicial, setDataIncial] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataInicial !== null
      ? parseISO(filters.dataInicial)
      : startOfMonth(new Date()),
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataFinal !== null
      ? parseISO(filters.dataFinal)
      : endOfMonth(new Date()),
  );
  const [descricao, setDescricao] = useState(
    Object.keys(filters).length > 0 ? filters.descricao : '',
  );

  function saveFilters() {
    setFilters(
      {
        produto,
        produtoVersao,
        cliente,
        dataInicial,
        dataFinal,
        descricao,
      },
      'failures',
    );
  }

  const [selectProducts, setSelectProducts] = useState([]);

  useEffect(() => {
    async function loadProducts() {
      const response = await api.get('/products', {
        params: {
          status: 'ativo'
        }
      });
      setSelectProducts(changeNamesOfColumns(response.data, 'descricao', 'id'));
    }
    loadProducts();
  }, []);

  async function loadRegisters() {
    const delayDebounceFn = setTimeout(async () => {
      try {
        const response = await api.get('/failures', {
          params: {
            productId: produto,
            productVersionName: produtoVersao,
            clientName: cliente,
            startDate: dataInicial && format(dataInicial, 'yyyy-MM-dd'),
            endDate: dataFinal && format(dataFinal, 'yyyy-MM-dd'),
            description: descricao,
          },
        });

        const responseDisassembled = await response.data.map((item: any) => {
          return {
            id: item.id,
            dataHora: item.dataHora,
            produto: item.produtoDados.descricao,
            produtoVersao: item.produtoVersaoDados.versao,
            cliente: item.clienteDados.fantasia,
            descricao:
              // eslint-disable-next-line no-nested-ternary
              item.descricao !== null
                ? item.descricao.length > 100 ? `${String(item.descricao).substring(0, 100)} ...` : item.descricao
                : '',
          };
        });

        const responseOrdered: any = await handleOrderBy(
          responseDisassembled,
          order,
        );
        setEntity(responseOrdered);
        setPages(Math.ceil(response.data.length / 10));
        setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
      } catch (err) {
        setEntity([{}]);
        setPages(1);
        setActualPage(1);
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }

  useEffect(() => {
    loadRegisters()
  }, [dataInicial, dataFinal, produto, order]);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#a53234' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/failures/register/${id}`);
  }

  return (
    <Container>
      <Title>
        <h1>Ocorrência de Falhas</h1>
      </Title>
      <Filters>
        <FiltersLine>
          <FiltersFieldBetween size="20%">
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataIncial(e);
                }}
                placeholderText="Data inicial"
                selected={dataInicial}
              />
            </div>
            <h3>a</h3>
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataFinal(e);
                }}
                placeholderText="Data final"
                selected={dataFinal}
              />
            </div>
          </FiltersFieldBetween>
          <FiltersField size="18%">
            <Select
              name="produto"
              height={40}
              clear
              maxMenuHeight={300}
              placeholder="Filtre pelo produto"
              onChange={(e: any) => {
                // eslint-disable-next-line no-unused-expressions
                if (e !== null) {
                  setProduto(e.value);
                } else {
                  setProduto('');
                }
              }}
              defaultValue={produto}
              options={selectProducts}
            />
          </FiltersField>
          <FiltersField size="12%">
            <input
              value={produtoVersao}
              onChange={e => setProdutoVersao((e.target.value as any) || '')}
              id="produtoVersao"
              placeholder="Filtre pela versão"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  loadRegisters();
                }
              }}
            />
          </FiltersField>
          <FiltersField size="20%">
            <input
              value={cliente}
              onChange={e => setCliente((e.target.value as any) || '')}
              id="cliente"
              placeholder="Filtre pelo cliente"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  loadRegisters();
                }
              }}
            />
          </FiltersField>
          <FiltersField size="30%">
            <input
              value={descricao}
              onChange={e => setDescricao((e.target.value as any) || '')}
              id="descricao"
              placeholder="Filtre pela descrição"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  loadRegisters();
                }
              }}
            />
          </FiltersField>
          <FilterButton
            tooltipDescription='Filtrar Falhas'
            onClick={loadRegisters}
          />
        </FiltersLine>
      </Filters>
      <Tables>
        <table>
          <thead>
            <tr>
              <th>
                <div>
                  Data
                  <button
                    onClick={() => {
                      orderBy('data');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('data', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('data', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Produto
                  <button
                    onClick={() => {
                      orderBy('produto');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('produto', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('produto', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Versão
                  <button
                    onClick={() => {
                      orderBy('produtoVersao');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('produtoVersao', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('produtoVersao', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Cliente
                  <button
                    onClick={() => {
                      orderBy('cliente');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('cliente', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('cliente', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Descrição
                  <button
                    onClick={() => {
                      orderBy('descricao');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('descricao', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('descricao', true)}
                    />
                  </button>
                </div>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {'id' in entity[0] &&
              entity
                .slice(handlePagination(1), handlePagination(2))
                .map((e, index) => (
                  <tr key={index}>
                    <TableTd width="10%">
                      {e.dataHora && manipulateDateHour(e.dataHora)}
                    </TableTd>
                    <TableTd width="15%">{e.produto}</TableTd>
                    <TableTd width="08%">{e.produtoVersao}</TableTd>
                    <TableTd width="25%">{e.cliente}</TableTd>
                    <TableTd width="32%">{e.descricao}</TableTd>
                    <TableTd center width="10%">
                      <button type="button" onClick={() => handleEdit(e.id)}>
                        <FiEdit size={20} color="#Fafafa" />
                      </button>
                    </TableTd>
                  </tr>
                ))}
          </tbody>
        </table>
        <TablesPagination>
          <span>
            {`${'id' in entity[0] ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#a53234'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#a53234'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default Failure;
