import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSend, FiXCircle, FiTrash, FiPaperclip } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import ClipLoader from 'react-spinners/ClipLoader';

import api from '../../../services/api';

import { getIndividualErrors, getErrors } from '../../../utils/validateForm';
import { changeForm } from '../../../utils/handleForms';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
  FormFieldTextArea,
} from '../../../styles/registers';
import { FormLoading } from '../../../styles/lists';
import {
  FileButtons,
  FileButtonUpload,
  FileButtonClear,
  FileList,
} from './styles';

import { changeNamesOfColumns } from '../../../utils/handleSelects';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  enviarPara: string;
  emailSubject: string;
  assunto: string;
  corpo: string;
}

interface ErrorsData {
  [key: string]: string;
}

const ClientSendEmails: React.FC = () => {
  const history = useNavigate();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);

  const [selectFilters, setSelectFilters] = useState([]);
  const [selectEmailSubjects, setSelectEmailSubjects] = useState([]);

  const [entity, setEntity] = useState<EntityData>({
    enviarPara: 'ATIVOS',
    emailSubject: '',
    assunto: '',
    corpo: '',
  });
  const [files, setFiles] = useState<string[]>([]);
  const [filesInfo, setFilesInfo] = useState<string>('');

  const [entityValidation] = useState({
    descricao: Yup.string().label('Assunto de E-mail'),
    assunto: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Assunto'),
    corpo: Yup.string().required('Preenchimento obrigatório').label('Corpo'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/emailSubjects');
        setSelectEmailSubjects(
          changeNamesOfColumns(response.data, 'descricao', 'id'),
        );

        const filters: any = [
          {
            label: 'TODOS OS CLIENTES ATIVOS',
            value: 'ATIVOS',
          },
        ];
        const response2 = await api.get('/products', {
          params:{
            status: 'ativo'
          }
        });
        const produtos: string[] = [];
        for (const element of response2.data) {
          if (produtos.indexOf(element.descricao) === -1) {
            produtos.push(element.descricao);
          }
        }
        produtos.map((item: string) => {
          return filters.push({
            label: `TODOS OS CLIENTES QUE UTILIZAM ${item}`,
            value: item,
          });
        });

        const response3 = await api.get('/productModules');
        const modulos: string[] = [];
        for (const element of response3.data) {
          if (modulos.indexOf(element.descricao) === -1) {
            modulos.push(element.descricao);
          }
        }

        modulos.map((item: string) => {
          return filters.push({
            label: `TODOS OS CLIENTES QUE EMITEM ${item}`,
            value: item,
          });
        });

        setSelectFilters(filters);
      } catch (err) {
        // manipulacao do erro
      }
    }
    loadSelects();
  }, []);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        setLoading(true);

        const data = new FormData();

        files.map((item: string) => {
          return data.append('files', item);
        });
        data.append('sendTo', entity.enviarPara);
        data.append('subject', entity.assunto);
        data.append('body', entity.corpo);

        await api.post('/clients/sendEmail', data);

        setLoading(false);
        addToast('Processo concluído', {
          appearance: 'success',
          autoDismiss: true,
        });
        history(-1);
      } catch (err: any) {
        addToast(
          err.response
            ? err.response.data.message
            : 'Problemas ao enviar e-mail, tente novamente',
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handleInformationEmailSubject(value: any) {
    if (value) {
      try {
        const response = await api.get(`/emailSubjects/${value}`);

        entity.assunto = response.data.assunto;
        entity.corpo = response.data.corpo;
        entity.emailSubject = value;
        setEntity(entity);
      } catch (err) {
        //
      }
    }
  }

  async function handleAddFiles(file: any) {
    const filesList = files;
    filesList.push(file.target.files[0]);
    setFilesInfo(
      `${filesInfo}${filesInfo !== '' ? ', ' : ''}${file.target.files[0].name}`,
    );
    setFiles(filesList);
  }

  async function handleDeleteFiles() {
    setFilesInfo('');
    setFiles([]);
  }

  return (
    <Container>
      <Title>
        <h1>Envio de E-mail para clientes</h1>
      </Title>
      {loading ? (
        <FormLoading>
          <ClipLoader size={30} color="#FFFFFF" loading={loading} />
        </FormLoading>
      ) : (
        <Form>
          <FormBlock>
            <FormTitle>Filtros</FormTitle>
            <FormLine height="50px">
              <FormField size="100%">
                <Select
                  name="filters"
                  height={40}
                  maxMenuHeight={300}
                  placeholder="Escolha os filtros"
                  onBlur={(e: any) => handleValidation('enviarPara')}
                  onChange={(e: any) => {
                    handleChanges('enviarPara', e.value);
                  }}
                  error={!!errors.enviarPara}
                  defaultValue={entity.enviarPara}
                  options={selectFilters}
                />
              </FormField>
            </FormLine>
          </FormBlock>
          <FormBlock>
            <FormTitle>Informações</FormTitle>
            <FormLine>
              <FormField size="50%">
                <span>
                  {`Assunto do E-mail
                ${errors.emailSubject ? `(${errors.emailSubject})` : ''}
                `}
                </span>
                <Select
                  name="emailSubject"
                  height={40}
                  maxMenuHeight={300}
                  placeholder="Escolha o assunto do e-mail"
                  onBlur={(e: any) => handleValidation('emailSubject')}
                  onChange={(e: any) => {
                    handleChanges('emailSubject', e.value);
                    handleInformationEmailSubject(e.value);
                  }}
                  error={!!errors.emailSubject}
                  defaultValue={entity.emailSubject}
                  options={selectEmailSubjects}
                />
              </FormField>
              <FormField size="50%">
                <span>
                  {`
                Assunto *
                ${errors.assunto ? `(${errors.assunto})` : ''}
                `}
                </span>
                <FormFieldInput
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => handleChanges(e.target.id, e.target.value)}
                  value={entity.assunto}
                  id="assunto"
                  autoComplete="off"
                  error={!!errors.assunto}
                  placeholder="Digite o assunto"
                />
              </FormField>
            </FormLine>
            <FormLine height="250px">
              <FormField size="100%">
                <span>
                  {`Corpo *
                ${errors.corpo ? `(${errors.corpo})` : ''}
                `}
                </span>
                <FormFieldTextArea
                  height="250px"
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => {
                    handleChanges(e.target.id, e.target.value);
                  }}
                  value={entity.corpo}
                  autoComplete="off"
                  id="corpo"
                  error={!!errors.corpo}
                  placeholder="Digite as informações do corpo"
                />
              </FormField>
            </FormLine>
            <FormLine height="260px">
              <FormField size="100%">
                <span>Anexos</span>

                <FileButtons style={{ display: 'flex', flexDirection: 'row' }}>
                  <FileButtonUpload htmlFor="file">
                    <FiPaperclip size={18} color="#faede8" />
                    Upload
                    <input
                      id="file"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleAddFiles}
                    />
                  </FileButtonUpload>
                  <FileButtonClear type="button" onClick={handleDeleteFiles}>
                    <FiTrash size={18} color="#a53234" />
                    Limpar anexos
                  </FileButtonClear>
                </FileButtons>
                <FileList>
                  <p>{filesInfo}</p>
                </FileList>
              </FormField>
            </FormLine>
          </FormBlock>

          <FormButtons>
            <Button onClick={handleSubmit} type="button">
              <FiSend size={20} color="#faede8" />
              Enviar
            </Button>
            <Button
              visual="secondary"
              type="button"
              onClick={() => {
                Confirmation(
                  () => history(-1),
                  'Tem certeza que deseja cancelar o envio ?',
                  'abort',
                );
              }}
            >
              <FiXCircle size={20} color="#a53234" />
              Cancelar
            </Button>
          </FormButtons>
        </Form>
      )}
    </Container>
  );
};

export default ClientSendEmails;
