import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  FiSave,
  FiXCircle,
  FiEdit,
  FiTrash,
  FiGitPullRequest,
} from 'react-icons/fi';
import { FaPlus, FaSearch } from 'react-icons/fa';
import Switch from 'react-switch';
import { useToasts } from 'react-toast-notifications';
import { format } from 'date-fns';

import { onlyNumbers } from '../../../utils/general';
import api from '../../../services/api';

import {
  getIndividualErrors,
  getErrors,
  validCpf,
  validCnpj,
  validFone,
} from '../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../utils/handleSelects';
import {
  cpfMask,
  cnpjMask,
  cepMask,
  foneMask,
  moneyMask,
} from '../../../utils/handleMasks';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormFieldTextArea,
  FormTitle,
  FormTable,
  FormTableLegend,
  FormButtons,
  TableTd,
  Button,
  Table,
  FormFieldWithSearch,
  TableHeader,
  TableHeaderColumn,
} from '../../../styles/registers';
import { getCEP } from '../../../utils/handleCEP';
import { manipulateFloats, manipulateDates } from '../../../utils/handleLists';

import { useAuth } from '../../../hooks/AuthContext';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  razaoSocial: string;
  fantasia: string;
  dataFundacao: Date | null;
  dataInativo: Date | null;
  cpfCnpj: string;
  rgIe: string;
  im: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  cidade: string;
  telefone1: string;
  telefone2: string;
  telefone3: string;
  email1: string;
  email2: string;
  email3: string;
  email4: string;
  obs: string;
  tipo: string;
  contabilidade: string;
  tecnico: string;
  diaVencimento: number | null;
  valorMensalidade: number | null;
  naoReajustaMensalidade: number | null;
  nfse: number | null;
  naoSomaTarifaMensalidade: number | null;
  grupo: string;
  obsAtualizacao: string;
  contaCobranca: string;
  codigo: number | null;
}

interface EntityContactData {
  id?: string;
  cliente?: string;
  nome?: string;
  telefone1?: string;
  telefone2?: string;
  email1?: string;
  email2?: string;
  telefone1whatsapp?: number;
  telefone2whatsapp?: number;
}

interface EntityComissionData {
  id?: string;
  cliente?: string;
  colaborador?: string;
  percentual?: number;
}

interface EntityPartnerData {
  id?: string;
  cliente?: string;
  fornecedor?: string;
  percentual?: number;
}

interface EntityProductData {
  id?: string;
  cliente?: string;
  produto?: string;
  dataInativo?: Date | null;
  dataInclusao?: Date | null;
}

interface ErrorsData {
  [key: string]: string;
}

interface SelectData {
  label: string;
  value: string;
}

const ClientRegister: React.FC = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const { idClient } = useParams<{ idClient: string }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    razaoSocial: '',
    fantasia: '',
    dataFundacao: null,
    dataInativo: null,
    cpfCnpj: '',
    rgIe: '',
    im: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    cidade: '',
    telefone1: '',
    telefone2: '',
    telefone3: '',
    email1: '',
    email2: '',
    email3: '',
    email4: '',
    obs: '',
    tipo: '',
    contabilidade: '',
    tecnico: '',
    diaVencimento: 0,
    valorMensalidade: 0,
    naoReajustaMensalidade: 0,
    nfse: 0,
    naoSomaTarifaMensalidade: 0,
    grupo: '',
    obsAtualizacao: '',
    contaCobranca: '',
    codigo: null,
  });
  const [entityCreatedAt, setEntityCreatedAt] = useState(
    format(new Date(), 'dd/MM/yyyy'),
  );
  const [fisicaJuridica, setFisicaJuridica] = useState('1');
  const [idClientState, setIdClientState] = useState(idClient);

  const [entityContacts, setEntityContacts] = useState<EntityContactData[]>([
    {},
  ]);

  const [entityComissions, setEntityComissions] = useState<
    EntityComissionData[]
  >([{}]);

  const [entityPartners, setEntityPartners] = useState<EntityPartnerData[]>([
    {},
  ]);

  const [entityProducts, setEntityProducts] = useState<EntityProductData[]>([
    {},
  ]);

  const [selectCidades, setSelectCidades] = useState([]);
  const [selectTipos, setSelectTipos] = useState([]);
  const [selectContabilidades, setSelectContabilidades] = useState([]);
  const [selectTecnicos, setSelectTecnicos] = useState([]);
  const [selectGrupos, setSelectGrupos] = useState([]);
  const [selectContaCobranca, setSelectContaCobranca] = useState([]);
  const [selectFisicaJuridica, setSelectFisicaJuridica] = useState<
    SelectData[]
  >([]);

  const [entityValidation, setEntityValidation] = useState({});
  const [errors, setErrors] = useState<ErrorsData>({});

  const loadContactEntity = useCallback(async () => {
    if (!idClientState) return;

    try {
      const response = await api.get('/clientContacts', {
        params: {
          cliente: idClientState,
        },
      });
      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          cliente: item.cliente,
          nome: item.nome,
          telefone1: item.telefone1,
          telefone2: item.telefone2,
          email1: item.email1,
          email2: item.email2,
          telefone1whatsapp: item.telefone1Whatsapp,
          telefone2whatsapp: item.telefone2Whatsapp,
        };
      });
      setEntityContacts(responseDisassembled);
    } catch (error) {
    }


  }, [idClientState]);

  const loadComissionEntity = useCallback(async () => {
    if (!idClientState) return;

    try {
      const response = await api.get('/clientComissions', {
        params: {
          cliente: idClientState,
        },
      });
      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          cliente: item.cliente,
          colaborador: item.colaboradores.nome,
          percentual: manipulateFloats(item.percentual),
        };
      });
      setEntityComissions(responseDisassembled);
    } catch (error) {
    }
  }, [idClientState]);

  const loadPartnerEntity = useCallback(async () => {
    if (!idClientState) return;

    try {
      const response = await api.get('/clientPartners', {
        params: {
          cliente: idClientState,
        },
      });

      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          cliente: item.cliente,
          fornecedor: item.parceiros.razaoSocial,
          percentual: manipulateFloats(item.percentual),
        };
      });
      setEntityPartners(responseDisassembled);
    } catch (error) {

    }
  }, [idClientState]);

  const loadProductEntity = useCallback(async () => {
    if (!idClientState) return;

    try {
      const response = await api.get('/clientProducts', {
        params: {
          cliente: idClientState,
        },
      });

      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          cliente: item.cliente,
          produto: item.produtos.descricao,
          dataInativo: item.dataInativo,
          dataInclusao: item.created_at,
        };
      });
      setEntityProducts(responseDisassembled);
    } catch (error) {
    }
  }, [idClientState]);

  useEffect(() => {
    async function loadSelects() {
      let response;
      let contabilidadeId;
      let tecnicaId;

      try {
        response = await api.get('/cities');
        setSelectCidades(changeNamesOfColumns(response.data, 'nome', 'id'));
      } catch (err) {
        // manipulacao do erro
      }

      try {
        response = await api.get('/clientTypes');
        setSelectTipos(changeNamesOfColumns(response.data, 'descricao', 'id'));
      } catch (err) {
        // manipulacao do erro
      }

      try {
        response = await api.get('/clientGroups');
        if (response)
          setSelectGrupos(
            changeNamesOfColumns(response.data, 'descricao', 'id'),
          );
      } catch (err) {
        // manipulacao do erro
      }

      try {
        response = await api.get('/invoiceSettings');
        if (response)
          setSelectContaCobranca(
            changeNamesOfColumns(response.data, 'descricao', 'id'),
          );
      } catch (err) {
        // manipulacao do erro
      }

      try {
        response = await api.get('contactTypes', {
          params: {
            descricao: 'contabilidade',
          },
        });
        contabilidadeId = response.data[0].id;
      } catch (err) {
        // manipulacao do erro
      }

      try {
        response = await api.get('contactTypes', {
          params: {
            descricao: 'TÉCNICA',
          },
        });
        tecnicaId = response.data[0].id;
      } catch (err) {
        // manipulacao do erro
      }

      try {
        response = await api.get('/contacts', {
          params: {
            tipo: contabilidadeId,
          },
        });
        setSelectContabilidades(
          changeNamesOfColumns(response.data, 'nome', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }

      try {
        response = await api.get('/contacts', {
          params: {
            tipo: tecnicaId,
          },
        });
        setSelectTecnicos(changeNamesOfColumns(response.data, 'nome', 'id'));
      } catch (err) {
        // manipulacao do erro
      }

      setSelectFisicaJuridica([
        {
          label: 'FÍSICA',
          value: '0',
        },
        {
          label: 'JURÍDICA',
          value: '1',
        },
      ]);
    }
    loadSelects();

    async function loadEntity() {
      if (idClientState !== undefined) {
        const response = await api.get(`/clients/${idClientState}`);
        const entityManipulated: any = loadForm(
          response.data,
          ['valorMensalidade'],
          ['dataInativo', 'dataFundacao'],
          ['naoReajustaMensalidade', 'nfse', 'naoSomaTarifaMensalidade'],
        );

        delete entityManipulated.id;

        setEntityCreatedAt(
          `${String(entityManipulated.created_at).substring(8, 10)}/${String(
            entityManipulated.created_at,
          ).substring(5, 7)}/${String(entityManipulated.created_at).substring(
            0,
            4,
          )}`,
        );

        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);

        const { cpfCnpj } = response.data;
        if (cpfCnpj.length === 18) {
          setFisicaJuridica('1');
        } else {
          setFisicaJuridica('0');
        }
      }
    }
    loadEntity();

    loadContactEntity();
    loadComissionEntity();
    loadPartnerEntity();
    loadProductEntity();
  }, [
    idClientState,
    loadContactEntity,
    loadComissionEntity,
    loadPartnerEntity,
    loadProductEntity,
  ]);

  useEffect(() => {
    setErrors({});
    setEntityValidation({
      razaoSocial: Yup.string()
        .required('Preenchimento obrigatório')
        .label(`${fisicaJuridica === '1' ? 'Razão social' : 'Nome'}`),
      fantasia: Yup.string()
        .required('Preenchimento obrigatório')
        .label(`${fisicaJuridica === '1' ? 'Fantasia' : 'Apelido'}`),
      dataInativo: Yup.date().nullable().label('Data Inativo'),
      dataFundacao: Yup.date()
        .nullable()
        .label(
          `${fisicaJuridica === '1' ? 'Data de Fundação' : 'Data de Nascimento'
          }`,
        ),
      cpfCnpj: Yup.string()
        .required('Preenchimento obrigatório')
        .label(`${fisicaJuridica === '1' ? 'CNPJ' : 'CPF'}`)
        .test(
          'testCpfCnpj',
          fisicaJuridica === '1' ? 'CNPJ Inválido' : 'CPF Inválido',
          async function validate(value) {
            const result =
              fisicaJuridica === '1'
                ? await validCnpj(value as string)
                : await validCpf(value as string);
            return result;
          },
        ),
      rgIe: Yup.string()
        .required('Preenchimento obrigatório')
        .label(
          `${fisicaJuridica === '1' ? 'Inscrição Estadual' : 'Registro Geral'}`,
        ),
      im: Yup.string(),
      logradouro: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Logradouro'),
      numero: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Número'),
      complemento: Yup.string(),
      bairro: Yup.string()
        .required('Preenchimento obrigatório')
        .label('bairro'),
      cep: Yup.string()
        .required('Preenchimento obrigatório')
        .label('CEP')
        .length(9)
        .matches(/^\d{5}-\d{3}$/, 'CEP com formato inválido'),
      cidade: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Cidade'),
      telefone1: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Telefone 1')
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        }),
      telefone2: Yup.string()
        .nullable()
        .label('Telefone 2')
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        }),
      telefone3: Yup.string()
        .nullable()
        .label('Telefone 3')
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        }),
      email1: Yup.string()
        .required('Preenchimento obrigatório')
        .email('E-mail com formato inválido')
        .label('E-mail 1'),
      email2: Yup.string()
        .email('E-mail com formato inválido')
        .label('E-mail 2'),
      email3: Yup.string()
        .required('Preenchimento obrigatório')
        .email('E-mail com formato inválido')
        .label('E-mail 3'),
      email4: Yup.string()
        .email('E-mail com formato inválido')
        .label('E-mail 4'),
      obs: Yup.string(),
      tipo: Yup.string().required('Preenchimento obrigatório').label('Tipo'),
      contabilidade: Yup.string(),
      tecnico: Yup.string(),
      diaVencimento: Yup.number()
        .required('Preenchimento obrigatório')
        .label('Dia do Vencimento')
        .min(1, 'Dia deve estar entre 1 e 23')
        .max(23, 'Dia deve estar entre 1 e 23'),
      valorMensalidade: Yup.string().nullable(),
      naoReajustaMensalidade: Yup.number(),
      nfse: Yup.number(),
      naoSomaTarifaMensalidade: Yup.number(),
      grupo: Yup.string(),
      obsAtualizacao: Yup.string(),
      contaCobranca: Yup.string(),
      codigo: Yup.number().nullable(),
    });
  }, [fisicaJuridica]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  async function handleChanges(id: string, value: any, type?: string) {
    let newValue;
    if (type === 'cpf') {
      newValue = cpfMask(value);
    } else if (type === 'cnpj') {
      newValue = cnpjMask(value);
    } else if (type === 'cep') {
      newValue = cepMask(value);
    } else if (type === 'fone') {
      newValue = foneMask(value);
    } else if (type === 'integer') {
      newValue = value === '' ? null : value;
    } else if (type === 'money') {
      newValue = moneyMask(value);
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, ['valorMensalidade']);
        if (idClientState === undefined) {
          delete entitySave.codigo;
          const response = await api.post('/clients', entitySave);
          setIdClientState(response.data.id);
          navigate(`/clients/register/${response.data.id}`, { replace: true });
          addToast(
            'Cliente inserido com sucesso, clique novamente para fechar ou insira registros secundários',
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );
        } else {
          const response = await api.put(
            `/clients/${idClientState}`,
            entitySave,
          );
          navigate(-1);
          addToast('Cliente editado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      } catch (err) {
        addToast('Problemas ao gravar o cliente, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  function handleContactDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/clientContacts/${id}`);
          setEntityContacts([{}]);
          loadContactEntity();
          addToast('Contato deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o contato, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o contato ?',
      'delete',
    );
  }

  function handleContactEdit(id?: string) {
    navigate(`/clients/register/${idClientState}/contact/${id}`);
  }

  function handleContactInsert() {
    navigate(`/clients/register/${idClientState}/contact`);
  }

  function handleComissionDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/clientComissions/${id}`);
          setEntityComissions([{}]);
          loadComissionEntity();
          addToast('Comissão deletada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar a comissão, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir a comissão ?',
      'delete',
    );
  }

  function handlePartnerDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/clientPartners/${id}`);
          setEntityPartners([{}]);
          loadPartnerEntity();
          addToast('Parceiro deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o parceiro, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o parceiro ?',
      'delete',
    );
  }

  function handleComissionEdit(id?: string) {
    navigate(`/clients/register/${idClientState}/comission/${id}`);
  }

  function handlePartnerEdit(id?: string) {
    navigate(`/clients/register/${idClientState}/partner/${id}`);
  }

  function handleComissionInsert() {
    navigate(`/clients/register/${idClientState}/comission`);
  }

  function handlePartnerInsert() {
    navigate(`/clients/register/${idClientState}/partner`);
  }

  function handleProductDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/clientProducts/${id}`);
          setEntityProducts([{}]);
          loadProductEntity();
          addToast('Produto deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o produto, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o produto ?',
      'delete',
    );
  }

  function handleProductEdit(id?: string) {
    navigate(`/clients/register/${idClientState}/product/${id}`);
  }

  function handleProductCompare(id?: string) {
    navigate(
      `/clients/register/${idClientState}/product/${id}/comparative`,
    );
  }

  function handleProductInsert() {
    navigate(`/clients/register/${idClientState}/product`);
  }

  async function handleSearchCEP(cep: string) {
    try {
      const data = await getCEP(cep);

      if (data.erro) {
        addToast('CEP não encontrado, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      const entityModified: EntityData = entity;
      if (data.logradouro) {
        entityModified.logradouro = data.logradouro;
      }

      if (data.bairro) entityModified.bairro = data.bairro;

      if (data.localidade) {
        const cityReponse = await api.get(`cities/`, {
          params: {
            nomeCompleto: data.localidade.toUpperCase(),
          },
        });
        entityModified.cidade = cityReponse.data[0].id;
      }

      setEntity(entityModified);
      document.getElementById('bairro')?.focus();
      document.getElementById('logradouro')?.focus();
    } catch (err) {
      addToast('Problemas ao buscar dados do CEP, tente novamente', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handleSearchCNPJ(cnpj: string) {
    try {
      const cnpjFormated = await onlyNumbers(cnpj);
      const response = await api.get(`/receitaWS/${cnpjFormated}`);

      const { data } = response;
      const entityModified: EntityData = entity;

      entityModified.razaoSocial = data.nome;
      entityModified.fantasia = data.fantasia;
      entityModified.telefone1 = foneMask(data.telefone);
      entityModified.email1 = data.email;
      entityModified.bairro = data.bairro;
      entityModified.cep = data.cep;
      entityModified.numero = data.numero;
      entityModified.logradouro = data.logradouro;

      if (data.municipio) {
        const cityReponse = await api.get(`cities/`, {
          params: {
            nomeCompleto: data.municipio.toUpperCase(),
          },
        });
        entityModified.cidade = cityReponse.data[0].id;
      }

      setEntity(entityModified);
      document.getElementById('bairro')?.focus();
      document.getElementById('logradouro')?.focus();
    } catch (err: any) {
      let error: string;
      if (err.response.data.message.message) {
        error = err.response.data.message.message;
      } else if (err.response.data.message) {
        error = err.response.data.message;
      } else {
        error = 'Problemas ao buscar dados do CNPJ, tente novamente';
      }
      addToast(error, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Clientes</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="50%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'Razão social *' : 'Nome *'}
                ${errors.razaoSocial ? `(${errors.razaoSocial})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.razaoSocial}
                id="razaoSocial"
                autoComplete="off"
                error={!!errors.razaoSocial}
                placeholder={
                  fisicaJuridica === '1'
                    ? 'Digite a razão social'
                    : 'Digite o nome'
                }
              />
            </FormField>
            <FormField size="33%">
              <span>
                {`
                 ${fisicaJuridica === '1' ? 'Nome fantasia *' : 'Apelido *'}
                ${errors.fantasia ? `(${errors.fantasia})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.fantasia}
                autoComplete="off"
                id="fantasia"
                error={!!errors.fantasia}
                placeholder={
                  fisicaJuridica === '1'
                    ? 'Digite o nome fantasia'
                    : 'Digite o apelido'
                }
              />
            </FormField>
            <FormField size="6%">
              <span>Código</span>
              <FormFieldInput
                value={entity.codigo ? entity.codigo : ''}
                id="codigo"
                disabled
              />
            </FormField>
            <FormField size="11%">
              <span>Inclusão</span>
              <FormFieldInput
                value={entityCreatedAt}
                id="dataInclusao"
                disabled
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="15%">
              <span>Física/Jurídica</span>
              <Select
                name="fisicajuridica"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha entre física e jurídica"
                onChange={(e: any) => {
                  setFisicaJuridica(e.value);
                  handleChanges(
                    'cpfCnpj',
                    entity.cpfCnpj,
                    e.value === '1' ? 'cnpj' : 'cpf',
                  );
                }}
                defaultValue={fisicaJuridica}
                options={selectFisicaJuridica}
              />
            </FormField>
            <FormFieldWithSearch size="35%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'CNPJ *' : 'CPF *'}
                ${errors.cpfCnpj ? `(${errors.cpfCnpj})` : ''}
                `}
              </span>
              <div>
                <FormFieldInput
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => {
                    handleChanges(
                      e.target.id,
                      e.target.value,
                      fisicaJuridica === '1' ? 'cnpj' : 'cpf',
                    );
                  }}
                  maxLength={fisicaJuridica === '1' ? 18 : 14}
                  value={entity.cpfCnpj}
                  autoComplete="off"
                  id="cpfCnpj"
                  placeholder={
                    fisicaJuridica === '1' ? 'Defina o CNPJ' : 'Defina o CPF'
                  }
                  error={!!errors.cpfCnpj}
                />
                <button
                  onClick={() => handleSearchCNPJ(entity.cpfCnpj)}
                  type="button"
                >
                  <FaSearch size={20} color="#faede8" />
                </button>
              </div>
            </FormFieldWithSearch>
            <FormField size="33%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'I.E *' : 'I.E/RG *'}
                ${errors.rgIe ? `(${errors.rgIe})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.rgIe}
                id="rgIe"
                autoComplete="off"
                error={!!errors.rgIe}
                placeholder={
                  fisicaJuridica === '1'
                    ? 'Digite a inscrição estadual'
                    : 'Digite o RG ou a inscrição estadual'
                }
              />
            </FormField>
            <FormField size="17%">
              <span>
                {`I.M
                ${errors.im ? `(${errors.im})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.im}
                id="im"
                autoComplete="off"
                error={!!errors.im}
                placeholder="Digite a inscrição municipal"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>ENDEREÇOS</FormTitle>
          <FormLine>
            <FormFieldWithSearch size="20%">
              <span>
                {`CEP *
                ${errors.cep ? `(${errors.cep})` : ''}
                `}
              </span>
              <div>
                <FormFieldInput
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => {
                    handleChanges(e.target.id, e.target.value, 'cep');
                  }}
                  value={entity.cep}
                  id="cep"
                  maxLength={9}
                  autoComplete="off"
                  error={!!errors.cep}
                  placeholder="Digite o CEP"
                />
                <button
                  onClick={() => handleSearchCEP(entity.cep)}
                  type="button"
                >
                  <FaSearch size={20} color="#faede8" />
                </button>
              </div>
            </FormFieldWithSearch>
            <FormField size="80%">
              <span>
                {`Logradouro *
                ${errors.logradouro ? `(${errors.logradouro})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.logradouro}
                autoComplete="off"
                id="logradouro"
                error={!!errors.logradouro}
                placeholder="Digite o logradouro"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="20%">
              <span>
                {`Número *
                ${errors.numero ? `(${errors.numero})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.numero}
                autoComplete="off"
                id="numero"
                error={!!errors.numero}
                placeholder="Digite o número"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`Bairro *
                ${errors.bairro ? `(${errors.bairro})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.bairro}
                autoComplete="off"
                id="bairro"
                error={!!errors.bairro}
                placeholder="Digite o bairro"
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Complemento
                ${errors.complemento ? `(${errors.complemento})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.complemento}
                autoComplete="off"
                id="complemento"
                error={!!errors.complemento}
                placeholder="Digite o complemento"
              />
            </FormField>
            <FormField size="40%">
              <span>
                {`Cidade *
                ${errors.cidade ? `(${errors.cidade})` : ''}
                `}
              </span>
              <Select
                name="cidade"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha a cidade"
                onBlur={(e: any) => handleValidation('cidade')}
                onChange={(e: any) => {
                  handleChanges('cidade', e.value);
                }}
                error={!!errors.cidade}
                defaultValue={entity.cidade}
                options={selectCidades}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>CONTATO PRINCIPAL</FormTitle>
          <FormLine>
            <FormField size="33.33%">
              <span>
                {`Telefone 1 *
                ${errors.telefone1 ? `(${errors.telefone1})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone1}
                autoComplete="off"
                id="telefone1"
                maxLength={15}
                error={!!errors.telefone1}
                placeholder="Digite o telefone"
              />
            </FormField>
            <FormField size="33.33%">
              <span>
                {`Telefone 2
                ${errors.telefone2 ? `(${errors.telefone2})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone2}
                autoComplete="off"
                id="telefone2"
                maxLength={15}
                error={!!errors.telefone2}
                placeholder="Digite o telefone"
              />
            </FormField>
            <FormField size="33.34%">
              <span>
                {`Telefone 3
                ${errors.telefone3 ? `(${errors.telefone3})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone3}
                autoComplete="off"
                id="telefone3"
                maxLength={15}
                error={!!errors.telefone3}
                placeholder="Digite o telefone"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="25%">
              <span>
                {`Email 1 *
                ${errors.email1 ? `(${errors.email1})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email1}
                autoComplete="off"
                id="email1"
                error={!!errors.email1}
                placeholder="Digite o e-mail"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`Email 2
                ${errors.email2 ? `(${errors.email2})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email2}
                autoComplete="off"
                id="email2"
                error={!!errors.email2}
                placeholder="Digite o e-mail"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`Email 3 *
                ${errors.email3 ? `(${errors.email3})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email3}
                autoComplete="off"
                id="email3"
                error={!!errors.email3}
                placeholder="Digite o e-mail"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`Email 4
                ${errors.email4 ? `(${errors.email4})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email4}
                autoComplete="off"
                id="email4"
                error={!!errors.email4}
                placeholder="Digite o e-mail"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTable height="330px">
            <div>
              <h1>LISTA DE CONTATOS PESSOAIS</h1>
              <div>
                <FormTableLegend color="#2E8B57">Com WhatsApp</FormTableLegend>
                <button
                  disabled={idClientState === undefined}
                  onClick={handleContactInsert}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>

            <TableHeader>
              <TableHeaderColumn width="25%">Nome</TableHeaderColumn>
              <TableHeaderColumn width="12.5%">Telefone 1</TableHeaderColumn>
              <TableHeaderColumn width="12.5%">Telefone 2</TableHeaderColumn>
              <TableHeaderColumn width="20%">E-mail 1</TableHeaderColumn>
              <TableHeaderColumn width="20%">E-mail 2</TableHeaderColumn>
            </TableHeader>

            <Table height="230px">
              <table>
                <tbody>
                  {'id' in entityContacts[0] &&
                    entityContacts.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="25%">{e.nome}</TableTd>
                        <TableTd
                          color={
                            e.telefone1whatsapp === 1 ? '#2E8B57' : '#888888'
                          }
                          width="12.5%"
                        >
                          {e.telefone1}
                        </TableTd>
                        <TableTd
                          color={
                            e.telefone2whatsapp === 1 ? '#2E8B57' : '#888888'
                          }
                          width="12.5%"
                        >
                          {e.telefone2}
                        </TableTd>
                        <TableTd width="20%">{e.email1}</TableTd>
                        <TableTd width="20%">{e.email2}</TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleContactEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleContactDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>
        <FormBlock>
          <FormTable height="290px">
            <div>
              <h1>LISTA DE PRODUTOS</h1>
              <div>
                <FormTableLegend color="#a53234">Inativo</FormTableLegend>
                <button
                  disabled={idClientState === undefined}
                  onClick={handleProductInsert}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="15%">
                Data de inclusão
              </TableHeaderColumn>
              <TableHeaderColumn width="70%">Nome</TableHeaderColumn>
            </TableHeader>
            <Table height="190px">
              <table>
                <tbody>
                  {'id' in entityProducts[0] &&
                    entityProducts.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="15%">
                          {e.dataInclusao &&
                            manipulateDates(`${e.dataInclusao}`)}
                        </TableTd>
                        <TableTd
                          color={e.dataInativo !== null ? '#a53234' : '#888888'}
                          width="70%"
                        >
                          {e.produto}
                        </TableTd>
                        <TableTd center width="15%">
                          <button
                            type="button"
                            onClick={() => handleProductEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleProductDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                          <button
                            type="button"
                            title="Comparar versões"
                            onClick={() => handleProductCompare(e.id)}
                          >
                            <FiGitPullRequest size={20} color="#fafafa" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>
        <FormBlock style={user.nivel !== 1 ? { display: 'none' } : {}}>
          <FormTable height="330px">
            <div>
              <h1>COMISSÕES DE COLABORADORES</h1>
              <div>
                <button
                  disabled={idClientState === undefined}
                  onClick={handleComissionInsert}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="80%">Nome</TableHeaderColumn>
              <TableHeaderColumn width="10%">Valor %</TableHeaderColumn>
            </TableHeader>
            <Table height="230px">
              <table>
                <tbody>
                  {'id' in entityComissions[0] &&
                    entityComissions.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="80%">{e.colaborador}</TableTd>
                        <TableTd width="10%">{e.percentual}</TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleComissionEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleComissionDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>

        <FormBlock style={user.nivel !== 1 ? { display: 'none' } : {}}>
          <FormTable height="330px">
            <div>
              <h1>COMISSÕES DE PARCEIROS</h1>
              <div>
                <button
                  disabled={idClientState === undefined}
                  onClick={handlePartnerInsert}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="80%">Nome</TableHeaderColumn>
              <TableHeaderColumn width="10%">Valor %</TableHeaderColumn>
            </TableHeader>
            <Table height="230px">
              <table>
                <tbody>
                  {'id' in entityPartners[0] &&
                    entityPartners.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="80%">{e.fornecedor}</TableTd>
                        <TableTd width="10%">{e.percentual}</TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handlePartnerEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handlePartnerDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>

        <FormBlock>
          <FormTitle>OUTRAS INFORMAÇÕES</FormTitle>
          <FormLine>
            <FormField size="25%">
              <span>
                {`
                ${fisicaJuridica === '1'
                    ? 'Data de fundação'
                    : 'Data de nascimento'
                  }
                `}
              </span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataFundacao')}
                error={!!errors.dataFundacao}
                onChange={(e: any) => {
                  handleChanges('dataFundacao', e, 'date');
                }}
                placeholderText={
                  fisicaJuridica === '1'
                    ? 'Defina a data de fundação'
                    : 'Defina a data de nascimento'
                }
                selected={entity.dataFundacao}
              />
            </FormField>
            <FormField size="25%">
              <span>Data de inativação</span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataInativo')}
                error={!!errors.dataInativo}
                onChange={(e: any) => {
                  handleChanges('dataInativo', e, 'date');
                }}
                placeholderText="Defina a data de inativação"
                selected={entity.dataInativo}
              />
            </FormField>
            <FormField size="50%">
              <span>
                {`Tipo *
                ${errors.tipo ? `(${errors.tipo})` : ''}
                `}
              </span>
              <Select
                name="tipo"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o tipo"
                onBlur={(e: any) => handleValidation('tipo')}
                onChange={(e: any) => {
                  handleChanges('tipo', e.value);
                }}
                error={!!errors.tipo}
                defaultValue={entity.tipo}
                options={selectTipos}
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="50%">
              <span>Contabilidade</span>
              <Select
                name="contabilidade"
                clear
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha a contabilidade"
                onBlur={(e: any) => handleValidation('contabilidade')}
                onChange={(e: any) => {
                  handleChanges('contabilidade', e !== null ? e.value : '');
                }}
                error={!!errors.contabilidade}
                defaultValue={entity.contabilidade}
                options={selectContabilidades}
              />
            </FormField>
            <FormField size="50%">
              <span>Técnico</span>
              <Select
                name="tecnico"
                clear
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o tecnico"
                onBlur={(e: any) => handleValidation('tecnico')}
                onChange={(e: any) => {
                  handleChanges('tecnico', e !== null ? e.value : '');
                }}
                error={!!errors.tecnico}
                defaultValue={entity.tecnico}
                options={selectTecnicos}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>OBSERVAÇÕES</FormTitle>
          <FormLine height="130px">
            <FormField size="100%">
              <span>
                {`Observações
                ${errors.obs ? `(${errors.obs})` : ''}
                `}
              </span>
              <FormFieldTextArea
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.obs}
                autoComplete="off"
                id="obs"
                error={!!errors.obs}
                placeholder="Digite as observações"
              />
            </FormField>
          </FormLine>
          <FormLine height="130px">
            <FormField size="100%">
              <span>
                {`Observações para tarefas
                ${errors.obsatualizacao ? `(${errors.obsatualizacao})` : ''}
                `}
              </span>
              <FormFieldTextArea
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.obsAtualizacao}
                autoComplete="off"
                id="obsAtualizacao"
                error={!!errors.obsAtualizacao}
                placeholder="Digite as observações para tarefas"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>PARAMETRIZAÇÕES</FormTitle>
          <FormLine>
            <FormField size="20%">
              <span>
                {`Conta para cobrança
                ${errors.contaCobranca ? `(${errors.contaCobranca})` : ''}
                `}
              </span>
              <Select
                name="contaCobranca"
                clear
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha a conta para cobrança"
                onBlur={(e: any) => handleValidation('contaCobranca')}
                onChange={(e: any) => {
                  handleChanges('contaCobranca', e !== null ? e.value : '');
                }}
                error={!!errors.contaCobranca}
                defaultValue={entity.contaCobranca}
                options={selectContaCobranca}
              />
            </FormField>
            <FormField size="20%">
              <span>
                {`Grupo
                ${errors.grupo ? `(${errors.grupo})` : ''}
                `}
              </span>
              <Select
                name="grupo"
                clear
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o grupo"
                onBlur={(e: any) => handleValidation('grupo')}
                onChange={(e: any) => {
                  handleChanges('grupo', e !== null ? e.value : '');
                }}
                error={!!errors.grupo}
                defaultValue={entity.grupo}
                options={selectGrupos}
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Dia de vencimento *
                ${errors.diaVencimento ? `(${errors.diaVencimento})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'integer');
                }}
                value={entity.diaVencimento ? entity.diaVencimento : ''}
                autoComplete="off"
                id="diaVencimento"
                type="number"
                max={23}
                min={1}
                error={!!errors.diaVencimento}
                placeholder="Digite o dia de vencimento"
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Valor mensal
                ${errors.valorMensalidade ? `(${errors.valorMensalidade})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.valorMensalidade ? entity.valorMensalidade : ''}
                autoComplete="off"
                id="valorMensalidade"
                error={!!errors.valorMensalidade}
                placeholder="Digite o valor mensal"
              />
            </FormField>
            <FormField size="12%">
              <span>
                {`Não reajustar mensalidade
                ${errors.naoReajustaMensalidade
                    ? `(${errors.naoReajustaMensalidade})`
                    : ''
                  }
                `}
              </span>
              <Switch
                checked={!!entity.naoReajustaMensalidade}
                onChange={() => {
                  handleChanges(
                    'naoReajustaMensalidade',
                    entity.naoReajustaMensalidade === 0 ? 1 : 0,
                  );
                }}
              />
            </FormField>
            <FormField size="12%">
              <span>
                {`Sem tarifa na mensalidade
                ${errors.naoSomaTarifaMensalidade
                    ? `(${errors.naoSomaTarifaMensalidade})`
                    : ''
                  }
                `}
              </span>
              <Switch
                checked={!!entity.naoSomaTarifaMensalidade}
                onChange={() => {
                  handleChanges(
                    'naoSomaTarifaMensalidade',
                    entity.naoSomaTarifaMensalidade === 0 ? 1 : 0,
                  );
                }}
              />
            </FormField>
            <FormField size="6%">
              <span>
                {`Emite nfs-e
                ${errors.nfse ? `(${errors.nfse})` : ''}
                `}
              </span>
              <Switch
                checked={!!entity.nfse}
                onChange={() => {
                  handleChanges('nfse', entity.nfse === 0 ? 1 : 0);
                }}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ClientRegister;
