import React, { useEffect, useState } from 'react';
import { Routes as Switch, useLocation, Route } from 'react-router-dom';

import { removeFilters } from '../utils/handleFilters';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Task from '../pages/Task';
import Management from '../pages/Managements';
import TaskRegister from '../pages/Task/Register';
import TaskPanel from '../pages/Task/Panel';
import TaskTopicRegister from '../pages/Task/Register/Topic';
import TaskTopicTestRegister from '../pages/Task/Register/Topic/Test';
import Lead from '../pages/Lead';
import LeadRegister from '../pages/Lead/Register';
import LeadStoryRegister from '../pages/Lead/Register/Story';
import Option from '../pages/Option';
import Client from '../pages/Client';
import ClientReadjustment from '../pages/Client/Readjustment';
import ClientSendEmail from '../pages/Client/SendEmail';
import ClientPassword from '../pages/Client/Password';
import ClientRegister from '../pages/Client/Register';
import ClientContactRegister from '../pages/Client/Register/Contact';
import ClientComissionRegister from '../pages/Client/Register/Comission';
import ClientPartnerRegister from '../pages/Client/Register/Partner';
import ClientProductComparative from '../pages/Client/Register/Product/Comparative';
import ClientProductRegister from '../pages/Client/Register/Product';
import ClientProductModuleRegister from '../pages/Client/Register/Product/Module';
import ClientProductVersionRegister from '../pages/Client/Register/Product/Version';
import Product from '../pages/Product';
import ProductRegister from '../pages/Product/Register';
import ProductRegisterModule from '../pages/Product/Register/Module';
import ProductRegisterModulePrice from '../pages/Product/Register/Module/Price';
import ProductRegisterVersion from '../pages/Product/Register/Version';
import ProductRegisterVersionTask from '../pages/Product/Register/Version/Task';
import ProductRegisterVersionPendency from '../pages/Product/Register/Version/Pendency';
import ProductRegisterVersionFile from '../pages/Product/Register/Version/File';
import Supplier from '../pages/Supplier';
import SupplierRegister from '../pages/Supplier/Register';
import Receipt from '../pages/Receipt';
import ReceiptRegister from '../pages/Receipt/Register';
import ReceiptReportComissions from '../pages/Receipt/Report/Comissions';
import ReceiptRegisterChargingInvoice from '../pages/Receipt/Register/ChargingInvoice';
import Payment from '../pages/Payment';
import PaymentRegister from '../pages/Payment/Register';
import PaymentRegisterChargingPartner from '../pages/Payment/Register/ChargingPartner';
import ClientType from '../pages/ClientType';
import ClientTypeRegister from '../pages/ClientType/Register';
import ClientGroup from '../pages/ClientGroup';
import ClientGroupRegister from '../pages/ClientGroup/Register';
import ContactType from '../pages/ContactType';
import ContactTypeRegister from '../pages/ContactType/Register';
import CollaboratorFunction from '../pages/CollaboratorFunction';
import CollaboratorFunctionRegister from '../pages/CollaboratorFunction/Register';
import Collaborator from '../pages/Collaborator';
import CollaboratorRegister from '../pages/Collaborator/Register';
import Contact from '../pages/Contact';
import ContactRegister from '../pages/Contact/Register';
import Company from '../pages/Company';
import CompanyRegister from '../pages/Company/Register';
import InvoiceSettingRegister from '../pages/Company/Register/InvoiceSetting';
import PaymentMethod from '../pages/PaymentMethod';
import PaymentMethodRegister from '../pages/PaymentMethod/Register';
import CashPlace from '../pages/CashPlace';
import CashPlaceRegister from '../pages/CashPlace/Register';
import CashPlaceGroup from '../pages/CashPlaceGroup';
import CashPlaceGroupRegister from '../pages/CashPlaceGroup/Register';
import CashPlaceSubGroup from '../pages/CashPlaceSubGroup';
import CashPlaceSubGroupRegister from '../pages/CashPlaceSubGroup/Register';
import CashPlaceAccount from '../pages/CashPlaceAccount';
import CashPlaceAccountRegister from '../pages/CashPlaceAccount/Register';
import FiscalDocument from '../pages/FiscalDocument';
import FiscalDocumentRegister from '../pages/FiscalDocument/Register';
import ReceiptDC from '../pages/ReceiptDC';
import ReceiptDCRegister from '../pages/ReceiptDC/Register';
import FiscalDocumentEdit from '../pages/FiscalDocument/Edit';
import EmailSubject from '../pages/EmailSubject';
import EmailSubjectRegister from '../pages/EmailSubject/Register';
import ReceiptSendEmailSubject from '../pages/Receipt/SendEmailSubject';
import CustomerService from '../pages/CustomerServices';
import CustomerServiceRegister from '../pages/CustomerServices/Register';
import CustomerServicePanel from '../pages/CustomerServices/Panel';
import ProblemSolution from '../pages/ProblemSolution';
import ProblemSolutionRegister from '../pages/ProblemSolution/Register';
import ManagementCompanyRegister from '../pages/Managements/Register';
import ProxyRoute from './ProxyRoute';
import ProductTypeRegister from '../pages/Product/Register/Type';
import Failure from '../pages/Failures';
import FailureRegister from '../pages/Failures/Register';

const Routes: React.FC = () => {
  const location = useLocation();

  const [lastRoute, setLastRoute] = useState<string>('');

  useEffect(() => {
    if (lastRoute !== location.pathname.split('/')[1]) {
      removeFilters(lastRoute);
      setLastRoute(location.pathname.split('/')[1]);
    }
  }, [location, lastRoute]);

  return (
    <Switch>
      <Route
        path="/"
        element={<ProxyRoute component={<Login />} />}
      />
      <Route
        path="/dashboard"
        element={<ProxyRoute isPrivate component={<Dashboard />} />}
      />
      <Route path="/options" element={<ProxyRoute isPrivate component={<Option />} />} />
      <Route path="/clients" element={<ProxyRoute isPrivate component={<Client />} />} />
      <Route
        path="/clients/readjustment"
        element={<ProxyRoute isPrivate component={<ClientReadjustment />} />}
      />
      <Route
        path="/clients/sendEmails"
        element={<ProxyRoute isPrivate component={<ClientSendEmail />} />}
      />
      <Route
        path="/clients/register/:idClient?"
        element={<ProxyRoute isPrivate component={<ClientRegister />} />}
      />
      <Route
        path="/clients/password/:idClient?"
        element={<ProxyRoute isPrivate component={<ClientPassword />} />}
      />
      <Route
        path="/clients/register/:idClient/contact/:idClientContact?"
        element={<ProxyRoute isPrivate component={<ClientContactRegister />} />}
      />
      <Route
        path="/clients/register/:idClient/comission/:idClientComission?"
        element={<ProxyRoute isPrivate component={<ClientComissionRegister />} />}
      />
      <Route
        path="/clients/register/:idClient/partner/:idClientPartner?"
        element={<ProxyRoute isPrivate component={<ClientPartnerRegister />} />}
      />
      <Route
        path="/clients/register/:idClient/product/:idClientProduct?"
        element={<ProxyRoute isPrivate component={<ClientProductRegister />} />}
      />
      <Route
        path="/clients/register/:idClient/product/:idClientProduct/comparative"
        element={<ProxyRoute isPrivate component={<ClientProductComparative />} />}
      />
      <Route
        path="/clients/register/:idClient/product/:idClientProduct/module/:idClientProductModule?"
        element={<ProxyRoute isPrivate component={<ClientProductModuleRegister />} />}
      />
      <Route
        path="/clients/register/:idClient/product/:idClientProduct/version/:idClientProductVersion?"
        element={<ProxyRoute isPrivate component={<ClientProductVersionRegister />} />}
      />
      <Route path="/contacts" element={<ProxyRoute isPrivate component={<Contact />} />} />
      <Route
        path="/contacts/register/:idContact?"
        element={<ProxyRoute isPrivate component={<ContactRegister />} />}
      />
      <Route path="/emailSubjects" element={<ProxyRoute isPrivate component={<EmailSubject />} />} />
      <Route
        path="/emailSubjects/register/:idEmailSubject?"
        element={<ProxyRoute isPrivate component={<EmailSubjectRegister />} />}
      />
      <Route path="/paymentMethods" element={<ProxyRoute isPrivate component={<PaymentMethod />} />} />
      <Route
        path="/paymentMethods/register/:idPaymentMethod?"
        element={<ProxyRoute isPrivate component={<PaymentMethodRegister />} />}
      />
      <Route path="/cashPlaces" element={<ProxyRoute isPrivate component={<CashPlace />} />} />
      <Route
        path="/cashPlaces/register/:idCashPlace?"
        element={<ProxyRoute isPrivate component={<CashPlaceRegister />} />}
      />
      <Route
        path="/cashPlaceGroups"
        element={<ProxyRoute isPrivate component={<CashPlaceGroup />} />}
      />
      <Route
        path="/cashPlaceGroups/register/:idCashPlaceGroup?"
        element={<ProxyRoute isPrivate component={<CashPlaceGroupRegister />} />}
      />
      <Route
        path="/cashPlaceSubGroups"
        element={<ProxyRoute isPrivate component={<CashPlaceSubGroup />} />}
      />
      <Route
        path="/cashPlaceSubGroups/register/:idCashPlaceSubGroup?"
        element={<ProxyRoute isPrivate component={<CashPlaceSubGroupRegister />} />}
      />
      <Route
        path="/cashPlaceAccounts"
        element={<ProxyRoute isPrivate component={<CashPlaceAccount />} />}
      />
      <Route
        path="/cashPlaceAccounts/register/:idCashPlaceAccount?"
        element={<ProxyRoute isPrivate component={<CashPlaceAccountRegister />} />}
      />
      <Route path="/clientTypes" element={<ProxyRoute isPrivate component={<ClientType />} />} />
      <Route
        path="/clientTypes/register/:idClientType?"
        element={<ProxyRoute isPrivate component={<ClientTypeRegister />} />}
      />
      <Route path="/clientGroups" element={<ProxyRoute isPrivate component={<ClientGroup />} />} />
      <Route
        path="/clientGroups/register/:idClientGroup?"
        element={<ProxyRoute isPrivate component={<ClientGroupRegister />} />}
      />
      <Route path="/contactTypes" element={<ProxyRoute isPrivate component={<ContactType />} />} />
      <Route
        path="/contactTypes/register/:idContactType?"
        element={<ProxyRoute isPrivate component={<ContactTypeRegister />} />}
      />

      <Route
        path="/collaboratorFunctions"
        element={<ProxyRoute isPrivate component={<CollaboratorFunction />} />}
      />
      <Route
        path="/collaboratorFunctions/register/:idCollaboratorFunction?"
        element={<ProxyRoute isPrivate component={<CollaboratorFunctionRegister />} />}
      />

      <Route path="/collaborators" element={<ProxyRoute isPrivate component={<Collaborator />} />} />
      <Route
        path="/collaborators/register/:idCollaborator?"
        element={<ProxyRoute isPrivate component={<CollaboratorRegister />} />}
      />

      <Route path="/products" element={<ProxyRoute isPrivate component={<Product />} />} />
      <Route
        path="/products/register/:idProduct?"
        element={<ProxyRoute isPrivate component={<ProductRegister />} />}
      />
      <Route
        path="/products/register/:idProduct/module/:idProductModule?"
        element={<ProxyRoute isPrivate component={<ProductRegisterModule />} />}
      />
      <Route
        path="/products/register/:idProduct/type/:idProductType?"
        element={<ProxyRoute isPrivate component={<ProductTypeRegister />} />}
      />
      <Route
        path="/products/register/:idProduct/module/:idProductModule?/price/:idProductModulePrice?"
        element={<ProxyRoute isPrivate component={<ProductRegisterModulePrice />} />}
      />
      <Route
        path="/products/register/:idProduct/version/:idProductVersion?"
        element={<ProxyRoute isPrivate component={<ProductRegisterVersion />} />}
      />
      <Route
        path="/products/register/:idProduct/version/:idProductVersion?/file/:idProductVersionFile?"
        element={<ProxyRoute isPrivate component={<ProductRegisterVersionFile />} />}
      />
      <Route
        path="/products/register/:idProduct/version/:idProductVersion?/pendency/:idProductVersionPendency?"
        element={<ProxyRoute isPrivate component={<ProductRegisterVersionPendency />} />}
      />
      <Route
        path="/products/register/:idProduct/version/:idProductVersion?/task/:idProductVersionTask?"
        element={<ProxyRoute isPrivate component={<ProductRegisterVersionTask />} />}
      />

      <Route path="/suppliers" element={<ProxyRoute isPrivate component={<Supplier />} />} />
      <Route
        path="/suppliers/register/:idSupplier?"
        element={<ProxyRoute isPrivate component={<SupplierRegister />} />}
      />

      <Route path="/leads" element={<ProxyRoute isPrivate component={<Lead />} />} />
      <Route
        path="/leads/register/:idLead?"
        element={<ProxyRoute isPrivate component={<LeadRegister />} />}
      />
      <Route
        path="/leads/register/:idLead/story/:idStory?"
        element={<ProxyRoute isPrivate component={<LeadStoryRegister />} />}
      />

      <Route
        path="/customerServices"
        element={<ProxyRoute isPrivate component={<CustomerService />} />}
      />
      <Route
        path="/customerServices/register/:idCustomerService?"
        element={<ProxyRoute isPrivate component={<CustomerServiceRegister />} />}
      />
      <Route
        path="/customerServices/panel"
        element={<ProxyRoute isPrivate component={<CustomerServicePanel />} />}
      />

      <Route
        path="/failures"
        element={<ProxyRoute isPrivate component={<Failure />} />}
      />
      <Route
        path="/failures/register/:idFailure?"
        element={<ProxyRoute isPrivate component={<FailureRegister />} />}
      />

      <Route
        path="/problemsSolutions"
        element={<ProxyRoute isPrivate component={<ProblemSolution />} />}
      />
      <Route
        path="/problemsSolutions/register/:idProblemSolution?"
        element={<ProxyRoute isPrivate component={<ProblemSolutionRegister />} />}
      />

      <Route path="/tasks" element={<ProxyRoute isPrivate component={<Task />} />} />
      <Route path="/tasks/panel" element={<ProxyRoute isPrivate component={<TaskPanel />} />} />
      <Route
        path="/tasks/register/:idTask?"
        element={<ProxyRoute isPrivate component={<TaskRegister />} />}
      />
      <Route
        path="/tasks/register/:idTask/topic/:idTopic?"
        element={<ProxyRoute isPrivate component={<TaskTopicRegister />} />}
      />
      <Route
        path="/tasks/register/:idTask/topic/:idTopic/test/:idTest?"
        element={<ProxyRoute isPrivate component={<TaskTopicTestRegister />} />}
      />

      <Route path="/managements" element={<ProxyRoute isPrivate component={<Management />} />} />
      <Route
        path="/managements/register/:idCompany?"
        element={<ProxyRoute isPrivate component={<ManagementCompanyRegister />} />}
      />

      <Route path="/receipts" element={<ProxyRoute isPrivate component={<Receipt />} />} />
      <Route
        path="/receipts/register/chargingInvoice"
        element={<ProxyRoute isPrivate component={<ReceiptRegisterChargingInvoice />} />}
      />
      <Route
        path="/receipts/register/:idPayment?/:type?"
        element={<ProxyRoute isPrivate component={<ReceiptRegister />} />}
      />
      <Route
        path="/receipts/report/comissions"
        element={<ProxyRoute isPrivate component={<ReceiptReportComissions />} />}
      />
      <Route
        path="/receipts/emailSubject/send"
        element={<ProxyRoute isPrivate component={<ReceiptSendEmailSubject />} />}
      />

      <Route path="/payments" element={<ProxyRoute isPrivate component={<Payment />} />} />
      <Route
        path="/payments/register/chargingPartner"
        element={<ProxyRoute isPrivate component={<PaymentRegisterChargingPartner />} />}
      />
      <Route
        path="/payments/register/:idPayment?/:type?"
        element={<ProxyRoute isPrivate component={<PaymentRegister />} />}
      />

      <Route
        path="/fiscalDocuments"
        element={<ProxyRoute isPrivate component={<FiscalDocument />} />}
      />
      <Route
        path="/fiscalDocuments/register"
        element={<ProxyRoute isPrivate component={<FiscalDocumentRegister />} />}
      />
      <Route
        path="/fiscalDocuments/register/:idFiscalDocument"
        element={<ProxyRoute isPrivate component={<FiscalDocumentEdit />} />}
      />

      <Route path="/companys" element={<ProxyRoute isPrivate component={<Company />} />} />
      <Route
        path="/companys/register/:idCompany?"
        element={<ProxyRoute isPrivate component={<CompanyRegister />} />}
      />
      <Route
        path="/companys/register/:idCompany/invoiceSetting/:idInvoiceSetting?"
        element={<ProxyRoute isPrivate component={<InvoiceSettingRegister />} />}
      />

      <Route path="/receiptsDC" element={<ProxyRoute isPrivate component={<ReceiptDC />} />} />
      <Route
        path="/receiptsDC/register/:idReceiptDC?"
        element={<ProxyRoute isPrivate component={<ReceiptDCRegister />} />}
      />
    </Switch>
  );
};

export default Routes;
