import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  FaPlus,
  FaClipboardList,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { FiEdit, FiKey, FiSearch, FiTrash } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';
import ClipLoader from 'react-spinners/ClipLoader';

import Switch from 'react-switch';

import Select from '../../components/Select';
import Confirmation from '../../components/Confirmation';
import Dropdown from '../../components/Dropdown';

import api from '../../services/api';

import { report } from '../../utils/printReport';
import { changeNamesOfColumns } from '../../utils/handleSelects';
import { orderBy as handleOrderBy } from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersLine,
  FiltersField,
  FiltersFieldSwitch,
  Tables,
  TableTd,
  TableTdCard,
  TablesPagination,
  FormLoading,
} from '../../styles/lists';
import Tooltip from '../../components/Tooltip';
import FilterButton from '../../components/FilterButton';
import { FilterButtonDiv, FilterContentDiv, FilterDiv } from './styles';
import { CustomFiltersLine } from '../Task/styles';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id?: string;
  codigo?: string;
  razaoSocial?: string;
  fantasia?: string;
  tipo?: string;
  cidade?: string;
  produtos?: string[];
  dataInativo?: Date;
}

const Client: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'razaoSocial',
    desc: false,
  });

  const filters: any = getFilters('clients');

  const [codigo, setCodigo] = useState(
    Object.keys(filters).length > 0 ? filters.codigo : '',
  );
  const [nome, setNome] = useState(
    Object.keys(filters).length > 0 ? filters.nome : '',
  );
  const [inativos, setInativos] = useState(
    Object.keys(filters).length > 0 ? filters.inativos : false,
  );

  const [cidades, setCidades] = useState<any>(
    Object.keys(filters).length > 0 ? filters.cidades : '',
  );

  const [tipos, setTipos] = useState<string>(
    Object.keys(filters).length > 0 ? filters.tipos : '',
  );

  const [produtos, setProdutos] = useState<string>(
    Object.keys(filters).length > 0 ? filters.produtos : '',
  );

  const [selectCidades, setSelectCidades] = useState([]);
  const [selectTipos, setSelectTipos] = useState([]);
  const [selectProdutos, setSelectProdutos] = useState([]);

  function saveFilters() {
    setFilters(
      {
        codigo,
        nome,
        inativos,
        cidades,
        tipos,
        produtos,
      },
      'clients',
    );
  }

  async function loadRegisters() {
    const delayDebounceFn = setTimeout(async () => {
      setLoading(true);
      try {
        const status = inativos ? {} : { status: 'ativo' };
        const response = await api.get('/clients', {
          params: {
            codigo,
            nome,
            cidade: cidades,
            tipo: tipos,
            produto: produtos,
            ...status,
          },
        });
        const responseDisassembled = await response.data.map((item: any) => {
          return {
            id: item.id,
            codigo: item.codigo,
            razaoSocial: item.razaoSocial,
            fantasia: item.fantasia,
            cidade: item.cidades.nome,
            tipo: item.tipos.descricao,
            dataInativo: item.dataInativo,
            produtos: item.produtos,
          };
        });
        const responseOrdered: any = await handleOrderBy(
          responseDisassembled,
          order,
        );
        setEntity(await responseOrdered);
        setPages(Math.ceil(response.data.length / 10));
        setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
        setLoading(false);
      } catch (err) {
        setEntity([{}]);
        setLoading(false);
        setPages(1);
        setActualPage(1);
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }

  useEffect(() => {
    loadRegisters();
  }, [inativos, cidades, tipos, produtos, order]);

  useEffect(() => {
    async function loadCities() {
      const response = await api.get('/cities');
      setSelectCidades(changeNamesOfColumns(response.data, 'nome', 'id'));
    }
    loadCities();

    async function loadClientTypes() {
      const response = await api.get('/clienttypes');
      setSelectTipos(changeNamesOfColumns(response.data, 'descricao', 'id'));
    }
    loadClientTypes();

    async function loadProducts() {
      const response = await api.get('/products', {
        params: {
          status: 'ativo',
        }});
      setSelectProdutos(changeNamesOfColumns(response.data, 'descricao', 'id'));
    }
    loadProducts();
  }, []);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#a53234' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  function handlePrint() {
    const header =
      '<th>CÓDIGO</th><th>NOME</th><th>CIDADE</th><th>TIPO</th><th>INATIVO</th>';
    let content = '';
    entity.map(
      // eslint-disable-next-line no-return-assign
      client =>
      (content =
        `${content}<tr key=${client.id}> ` +
        `<td>${client.codigo}</td>  ` +
        `<td>${client.fantasia}</td> ` +
        `<td>${client.cidade}</td> ` +
        `<td>${client.tipo}</td> ` +
        `<td>${client.dataInativo === null ? '' : client.dataInativo}</td> ` +
        `</tr>`),
    );
    report('RELAÇÃO DE CLIENTES', header, content);
  }

  function handleDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/clients/${id}`);
          // load();
          setCodigo('1'); // dispara o filtro
          setCodigo(''); // dispara o filtro
          addToast('Cliente deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o cliente, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o cliente ?',
      'delete',
    );
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/clients/register/${id}`);
  }

  function handleInsert() {
    saveFilters();
    navigate('/clients/register');
  }

  function handlePassword(id?: string) {
    navigate(`/clients/password/${id}`);
  }

  function handleReadjustment() {
    saveFilters();
    navigate('/clients/readjustment');
  }

  function handleSendEmails() {
    saveFilters();
    navigate('/clients/sendEmails');
  }

  async function handlePrintProductCurrentVersion() { 
    if (!!produtos) {
      try {
        const status = inativos ? {} : { status: 'ativo' };
        const response = await api.get('/clients/reports/productCurrentVersion', {
          params: {
            produto: produtos,
            ...status
          }
        });

        const header =
          '<th>CÓDIGO</th><th>CLIENTE</th><th>PRODUTO</th><th>VERSÃO</th>';
        let content = '';
        response.data.map((r: any) => {
          content =
            `${content}<tr key=${r.id}> ` +
            `<td>${r.codigo}</td>  ` +
            `<td>${r.fantasia}</td>  ` +
            `<td>${r.produto}</td>  ` +
            `<td>${r.versao}</td>  ` +
            `</tr>`;
          return true;
        });

        report(`RELAÇÃO CLIENTE PRODUTO VERSÃO ATUAL`, header, content);
      } catch (err) {
        addToast('Não foram encontrados registros para o filtro informado', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: PRODUTO`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Listagem de Clientes</h1>
      </Title>
      <Buttons>
        <Button onClick={handleInsert}>
          <FaPlus size={18} color="#faede8" />
          Adicionar
        </Button>
        <Button visual="secondary" onClick={handlePrint}>
          <FaClipboardList size={18} color="#a53234" />
          Imprimir
        </Button>

        <Dropdown name="Out. funções">
          <button type="button" onClick={handleSendEmails}>
            Envio de E-mails
          </button>
          <button type="button" onClick={handleReadjustment}>
            Reajuste de mensalidade
          </button>
        </Dropdown>

        <Dropdown name="Relatórios">
          <button type="button" onClick={handlePrintProductCurrentVersion}>
            <FaClipboardList size={18} color="#a53234" />
            Produto Versão Atual
          </button>
        </Dropdown>

      </Buttons>
      <Filters>
        <FilterDiv>
          <FilterContentDiv>
            <CustomFiltersLine first>
              <FiltersField size="25%">
                <input
                  value={codigo}
                  onChange={e => setCodigo((e.target.value as any) || '')}
                  id="codigo"
                  placeholder="Filtre pelo código"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      loadRegisters();
                    }
                  }}
                />
              </FiltersField>
              <FiltersField size="60%">
                <input
                  value={nome}
                  onChange={e => setNome((e.target.value as any) || '')}
                  id="nome"
                  placeholder="Filtre pelo nome"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      loadRegisters();
                    }
                  }}
                />
              </FiltersField>
              <FiltersField size="15%">
                <FiltersFieldSwitch>
                  <h3>Inativos</h3>
                  <Switch
                    checked={inativos}
                    onChange={() => setInativos(!inativos)}
                  />
                </FiltersFieldSwitch>
              </FiltersField>
            </CustomFiltersLine>
            <CustomFiltersLine second>
            <FiltersField size="34%">
                <Select
                  name="cidades"
                  height={40}
                  clear
                  maxMenuHeight={300}
                  placeholder="Filtre pelas cidades"
                  onChange={(e: any) => {
                    // eslint-disable-next-line no-unused-expressions
                    if (e !== null) {
                      setCidades(e.value);
                    } else {
                      setCidades('');
                    }
                  }}
                  defaultValue={cidades}
                  options={selectCidades}
                />
              </FiltersField>
              <FiltersField size="33%" >
                <Select
                  name="tipos"
                  height={40}
                  clear
                  maxMenuHeight={150}
                  placeholder="Filtre pelos tipos"
                  onChange={(e: any) => {
                    // eslint-disable-next-line no-unused-expressions
                    if (e !== null) {
                      setTipos(e.value);
                    } else {
                      setTipos('');
                    }
                  }}
                  defaultValue={tipos}
                  options={selectTipos}
                />
              </FiltersField>     
              <FiltersField size="33%" >
                <Select
                  name="produtos"
                  height={40}
                  clear
                  maxMenuHeight={150}
                  placeholder="Filtre pelos produtos"
                  onChange={(e: any) => {
                    // eslint-disable-next-line no-unused-expressions
                    if (e !== null) {
                      setProdutos(e.value);
                    } else {
                      setProdutos('');
                    }
                  }}
                  defaultValue={produtos}
                  options={selectProdutos}
                />
              </FiltersField>      
            </CustomFiltersLine>
          </FilterContentDiv>
          <FilterButtonDiv>
            <FilterButton
              tooltipDescription='Filtrar Clientes'
              onClick={loadRegisters}
            />
          </FilterButtonDiv>
        </FilterDiv>
      </Filters>
      <Tables>
        {loading ? (
          <FormLoading>
            <ClipLoader size={30} color="#FFFFFF" loading={loading} />
          </FormLoading>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>
                    <div>
                      Cod.
                      <button
                        onClick={() => {
                          orderBy('codigo');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('codigo', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('codigo', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Razão social
                      <button
                        onClick={() => {
                          orderBy('razaoSocial');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('razaoSocial', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('razaoSocial', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Fantasia
                      <button
                        onClick={() => {
                          orderBy('fantasia');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('fantasia', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('fantasia', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Cidade
                      <button
                        onClick={() => {
                          orderBy('cidade');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('cidade', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('cidade', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Tipo
                      <button
                        onClick={() => {
                          orderBy('tipo');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('tipo', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('tipo', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>Produtos</div>
                  </th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {'id' in entity[0] &&
                  entity
                    .slice(handlePagination(1), handlePagination(2))
                    .map((e, index) => (
                      <tr key={index}>
                        <TableTd color={e.dataInativo && '#a53234'} width="7%">
                          {e.codigo}
                        </TableTd>
                        <TableTd color={e.dataInativo && '#a53234'} width="20%">
                          {e.razaoSocial}
                        </TableTd>
                        <TableTd color={e.dataInativo && '#a53234'} width="25%">
                          {e.fantasia}
                        </TableTd>
                        <TableTd color={e.dataInativo && '#a53234'} width="20%">
                          {e.cidade}
                        </TableTd>
                        <TableTd color={e.dataInativo && '#a53234'} width="10%">
                          {e.tipo}
                        </TableTd>
                        <TableTd width="10%">
                          {e.produtos?.map(item => {
                            return (
                              <TableTdCard key={item} fontSize={10}>{item}</TableTdCard>
                            );
                          })}
                        </TableTd>
                        <TableTd center width="8%">
                          <button
                            type="button"
                            onClick={() => handleEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handlePassword(e.id)}
                          >
                            <FiKey size={20} color="#fafafa" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
              </tbody>
            </table>
          </>
        )}
        <TablesPagination>
          <span>
            {`${'id' in entity[0] ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#a53234'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#a53234'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default Client;
