import React from 'react';
import pt from 'date-fns/locale/pt';
import MaskedInput from 'react-maskedinput';

import { ReactDatePickerProps } from 'react-datepicker';

import { Date, Container } from './styles';

interface DatePickerProps extends ReactDatePickerProps {
  error: boolean;
}
const DatePickerCompoment: React.FC<DatePickerProps> = ({ error, ...rest }) => {
  return (
    <Container>
      <Date
        {...rest}
        error={error}
        name="data"
        locale={pt}
        dateFormat="dd/MM/yyyy"
        autoComplete="off"
        customInput={<MaskedInput mask="11/11/1111" placeholder="dd/mm/yyyy" />}
      />
    </Container>
  );
};

export default DatePickerCompoment;
