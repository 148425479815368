import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import ClipLoader from 'react-spinners/ClipLoader';
import api from '../../../../services/api';

import { getIndividualErrors, getErrors } from '../../../../utils/validateForm';
import { changeForm, saveForm } from '../../../../utils/handleForms';
import { mesAnoMask } from '../../../../utils/handleMasks';

import Confirmation from '../../../../components/Confirmation';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormButtons,
  Button,
  FormLoading,
} from '../../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  mesAno: string;
}

interface ErrorsData {
  [key: string]: string;
}

const ChargingPartnerRegister: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);

  const [entity, setEntity] = useState<EntityData>({
    mesAno: '',
  });

  const [entityValidation] = useState({
    mesAno: Yup.string().required('Preenchimento obrigatório').label('Mês/Ano'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    let newValue;
    if (type === 'mesAno') {
      newValue = mesAnoMask(value);
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);

        setLoading(true);
        await api.post('/chargingPartnerComissionMonth', entitySave);

        addToast('Comissões do mês geradas com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        setLoading(false);
        navigate(-1);
      } catch (err) {
        setLoading(false);
        addToast(
          'Problemas ao gravar as cobranças de comissões do mês, tente novamente',
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
      }
    } else {
      setLoading(false);
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de comissões de parceiros</h1>
      </Title>
      {loading ? (
        <FormLoading>
          <ClipLoader size={30} color="#FFFFFF" loading={loading} />
        </FormLoading>
      ) : (
        <Form>
          <FormBlock>
            <FormLine isJustifyLeft>
              <FormField size="20%">
                <span>
                  {`
                Mês / Ano *
                ${errors.mesAno ? `(${errors.mesAno})` : ''}
                `}
                </span>
                <FormFieldInput
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => {
                    handleChanges(e.target.id, e.target.value, 'mesAno');
                  }}
                  value={entity.mesAno}
                  id="mesAno"
                  maxLength={7}
                  autoComplete="off"
                  error={!!errors.mesAno}
                  placeholder="Digite o mês e ano"
                />
              </FormField>
            </FormLine>
          </FormBlock>
          <FormButtons>
            <Button width="200px" onClick={handleSubmit} type="button">
              <FiSave size={20} color="#faede8" />
              Gerar comissões
            </Button>
            <Button
              visual="secondary"
              type="button"
              onClick={() => {
                Confirmation(
                  () => navigate(-1),
                  'Tem certeza que deseja cancelar este cadastro ?',
                  'abort',
                );
              }}
            >
              <FiXCircle size={20} color="#a53234" />
              Cancelar
            </Button>
          </FormButtons>
        </Form>
      )}
    </Container>
  );
};

export default ChargingPartnerRegister;
