import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  height: 500px;
  margin-bottom: 55px;
  padding: 5px 15px;

  > span {
    color: red;
    align-self: flex-start;
    margin: 0 0 10px;
    font-weight: bold;
  }
  > div {
    width: 100%;
    height: 100%;
    background: transparent;

    & .rdw-dropdown-wrapper {
      min-width: 60px;
      background: #d1cdcb;
      border: 0;
      transition: background 0.4s;
      &:hover {
        background: ${shade(0.2, '#d1cdcb')};
        box-shadow: none;
      }
    }

    & .image {
      height: 30px;
      width: 50px;
      background: #d1cdcb;
      border: 0;
      transition: background 0.4s;
      &:hover {
        background: ${shade(0.2, '#d1cdcb')};
        box-shadow: none;
      }
    }

    & .embedded {
      height: 30px;
      width: 50px;
      background: #d1cdcb;
      border: 0;
      transition: background 0.4s;
      &:hover {
        background: ${shade(0.2, '#d1cdcb')};
        box-shadow: none;
      }
    }

    & .colorPicker {
      height: 30px;
      width: 50px;
      background: #d1cdcb;
      border: 0;
      transition: background 0.4s;
      &:hover {
        background: ${shade(0.2, '#d1cdcb')};
        box-shadow: none;
      }
    }

    & .emoji {
      height: 30px;
      width: 50px;
      background: #d1cdcb;
      border: 0;
      transition: background 0.4s;
      &:hover {
        background: ${shade(0.2, '#d1cdcb')};
        box-shadow: none;
      }
    }

    & .rdw-storybook-editor {
      height: 96%;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(35, 33, 41, 0.8);
      }
      scrollbar-width: thin;
      scrollbar-color: rgba(35, 33, 41, 0.8) transparent;
    }
  }
`;
