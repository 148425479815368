import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import {
  FaPlus,
  FaClipboardList,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import Confirmation from '../../components/Confirmation';
import DatePicker from '../../components/DatePicker';

import api from '../../services/api';

import { report } from '../../utils/printReport';
import {
  manipulateFloats,
  orderBy as handleOrderBy,
  manipulateDates,
} from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersLine,
  FiltersField,
  Tables,
  TableTd,
  TablesPagination,
  FiltersFieldBetween,
} from '../../styles/lists';

import { mesAnoMask } from '../../utils/handleMasks';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id?: string;
  created_at?: Date;
  produto_descricao?: string;
  cliente_fantasia?: string;
  descricao?: string;
  mesano?: string;
  valor?: number;
  tipodescricao?: string;
}

const ReceiptDC: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'created_at',
    desc: false,
  });

  const filters: any = getFilters('receiptsDC');

  const [clienteNome, setClienteNome] = useState(
    Object.keys(filters).length > 0 ? filters.clienteNome : '',
  );
  const [descricao, setDescricao] = useState(
    Object.keys(filters).length > 0 ? filters.descricao : '',
  );
  const [mesAnoInicial, setMesAnoInicial] = useState<string>(
    Object.keys(filters).length > 0 ? filters.mesAnoInicial : '',
  );
  const [mesAnoFinal, setMesAnoFinal] = useState<string>(
    Object.keys(filters).length > 0 ? filters.mesAnoFinal : '',
  );
  const [dataInicial, setDataInicial] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataInicial !== null
      ? parseISO(filters.dataInicial)
      : null,
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataFinal !== null
      ? parseISO(filters.dataFinal)
      : null,
  );

  function saveFilters() {
    setFilters(
      {
        clienteNome,
        descricao,
        mesAnoInicial,
        mesAnoFinal,
        dataInicial,
        dataFinal,
      },
      'receiptsDC',
    );
  }

  const load = useCallback(async () => {
    try {
      const response = await api.get('/receiptsDC', {
        params: {
          descricao,
          clienteNome,
          mesAnoInicial: mesAnoInicial.length === 7 ? mesAnoInicial : '',
          mesAnoFinal: mesAnoFinal.length === 7 ? mesAnoFinal : '',
          dataDigitacaoInicial:
            dataInicial && format(dataInicial, 'yyyy-MM-dd'),
          dataDigitacaoFinal: dataFinal && format(dataFinal, 'yyyy-MM-dd'),
        },
      });
      const responseDisassembled = await response.data.map((item: any) => {
        return {
          id: item.id,
          created_at: item.created_at,
          produto_descricao: item.produto_descricao,
          cliente_fantasia: item.cliente_fantasia,
          descricao: item.descricao,
          mesano: item.mesano,
          valor: manipulateFloats(item.valor),
          tipodescricao: item.tipodescricao,
        };
      });
      const responseOrdered: any = await handleOrderBy(
        responseDisassembled,
        order,
      );
      setEntity(responseOrdered);
      setPages(Math.ceil(response.data.length / 10));
      setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
    } catch (err) {
      setEntity([{}]);
      setPages(1);
      setActualPage(1);
    }
  }, [
    clienteNome,
    descricao,
    mesAnoInicial,
    mesAnoFinal,
    dataInicial,
    dataFinal,
    order,
  ]);

  useEffect(() => {
    load();
  }, [load]);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#a53234' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  function handlePrint() {
    const header =
      '<th>DATA</th><th>CLIENTE FANTASIA</th><th>PRODUTO</th><th>MÊS/ANO</th><th>VALOR</th><th>TIPO</th>';
    let content = '';
    entity.map(
      // eslint-disable-next-line no-return-assign
      receiptDC =>
      (content =
        `${content}<tr key=${receiptDC.id}> ` +
        `<td>${receiptDC.created_at}</td> ` +
        `<td>${receiptDC.cliente_fantasia}</td> ` +
        `<td>${receiptDC.produto_descricao}</td> ` +
        `<td>${receiptDC.mesano}</td> ` +
        `<td>${receiptDC.valor}</td> ` +
        `<td>${receiptDC.tipodescricao}</td> ` +
        `</tr>`),
    );
    report('RELAÇÃO DE ACRÉSCIMOS E DESCONTOS', header, content);
  }

  function handleDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/receiptsDC/${id}`);
          load();
          addToast('Acréscimo/Desconto deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast(
            'Não foi possível deletar o acréscimo/desconto, tente novamente',
            {
              appearance: 'error',
              autoDismiss: true,
            },
          );
        }
      },
      'Tem certeza que deseja excluir o acréscimo/desconto ?',
      'delete',
    );
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/receiptsDC/register/${id}`);
  }

  function handleInsert() {
    saveFilters();
    navigate('/receiptsDC/register');
  }

  return (
    <Container>
      <Title>
        <h1>Listagem de Acréscimos/Descontos</h1>
      </Title>
      <Buttons>
        <Button onClick={handleInsert}>
          <FaPlus size={18} color="#faede8" />
          Adicionar
        </Button>
        <Button visual="secondary" onClick={handlePrint}>
          <FaClipboardList size={18} color="#a53234" />
          Imprimir
        </Button>
      </Buttons>
      <Filters>
        <FiltersLine>
          <FiltersFieldBetween size="25%">
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataInicial(e);
                }}
                placeholderText="Data inicial"
                selected={dataInicial}
              />
            </div>
            <h3>a</h3>
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataFinal(e);
                }}
                placeholderText="Data final"
                selected={dataFinal}
              />
            </div>
          </FiltersFieldBetween>
          <FiltersField size="25%">
            <input
              value={clienteNome}
              onChange={e => setClienteNome((e.target.value as any) || '')}
              id="clienteNome"
              placeholder="Filtre pelo cliente"
            />
          </FiltersField>
          <FiltersField size="20%">
            <input
              value={descricao}
              onChange={e => setDescricao((e.target.value as any) || '')}
              id="descricao"
              placeholder="Filtre pela descrição"
            />
          </FiltersField>
          <FiltersFieldBetween size="30%">
            <FiltersField size="100%">
              <input
                value={mesAnoInicial}
                maxLength={7}
                onChange={e => setMesAnoInicial(mesAnoMask(e.target.value))}
                id="mesAnoInicial"
                placeholder="Mês/Ano inicial"
              />
            </FiltersField>
            <h3>e</h3>
            <FiltersField size="100%">
              <input
                value={mesAnoFinal}
                maxLength={7}
                onChange={e => setMesAnoFinal(mesAnoMask(e.target.value))}
                id="mesAnoFinal"
                placeholder="Mês/Ano final"
              />
            </FiltersField>
          </FiltersFieldBetween>
        </FiltersLine>
      </Filters>
      <Tables>
        <table>
          <thead>
            <tr>
              <th>
                <div>
                  Data
                  <button
                    onClick={() => {
                      orderBy('created_at');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('created_at', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('created_at', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Fantasia
                  <button
                    onClick={() => {
                      orderBy('cliente_fantasia');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('cliente_fantasia', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('cliente_fantasia', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Produto
                  <button
                    onClick={() => {
                      orderBy('produto_descricao');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('produto_descricao', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('produto_descricao', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Descrição
                  <button
                    onClick={() => {
                      orderBy('descricao');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('descricao', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('descricao', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Mês/Ano
                  <button
                    onClick={() => {
                      orderBy('mesano');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('mesano', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('mesano', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Valor
                  <button
                    onClick={() => {
                      orderBy('valor');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('valor', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('valor', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Tipo
                  <button
                    onClick={() => {
                      orderBy('tipodescricao');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('tipodescricao', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('tipodescricao', true)}
                    />
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {'id' in entity[0] &&
              entity
                .slice(handlePagination(1), handlePagination(2))
                .map((e, index) => (
                  <tr key={index}>
                    <TableTd width="10%">
                      {manipulateDates(`${e.created_at}`)}
                    </TableTd>
                    <TableTd width="20%">{e.cliente_fantasia}</TableTd>
                    <TableTd width="14%">{e.produto_descricao}</TableTd>
                    <TableTd width="22%">{e.descricao}</TableTd>
                    <TableTd width="8%">{e.mesano}</TableTd>
                    <TableTd width="8%">{e.valor}</TableTd>
                    <TableTd width="8%">{e.tipodescricao}</TableTd>
                    <TableTd center width="10%">
                      <button type="button" onClick={() => handleEdit(e.id)}>
                        <FiEdit size={20} color="#Fafafa" />
                      </button>
                      <button type="button" onClick={() => handleDelete(e.id)}>
                        <FiTrash size={20} color="#d13337" />
                      </button>
                    </TableTd>
                  </tr>
                ))}
          </tbody>
        </table>
        <TablesPagination>
          <span>
            {`${'id' in entity[0] ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#a53234'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#a53234'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default ReceiptDC;
