import React, { useState, useCallback, FormEvent, useEffect } from 'react';
import { FiLock, FiUser } from 'react-icons/fi';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { useToasts } from 'react-toast-notifications';

import { getIndividualErrors, getErrors } from '../../utils/validateForm';
import { changeForm } from '../../utils/handleForms';
import { useAuth } from '../../hooks/AuthContext';

import logo from '../../assets/logo-3r-reddarken.png';

import Input from '../../components/LoginInput';
import Button from '../../components/LoginButton';

import { Container, Content, AnimationContainer, Background } from './styles';

interface EntityData {
  name: string;
  password: string;
}

interface ErrorsData {
  [key: string]: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    name: '',
    password: '',
  });
  const [entityValidation] = useState({
    name: Yup.string().required('Nome obrigatório'),
    password: Yup.string().required('Senha obrigatória'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    const name = localStorage.getItem('@3rsistemas-gerencial/name');
    if (name) {
      handleChanges('name', name);
      document.getElementById('password')?.focus();
    } else {
      document.getElementById('name')?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleValidation(field: string, general = false) {
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
      });
  }

  function handleChanges(id: string, value: string, type?: string) {
    let newValue;
    if (type === 'login') {
      newValue = value.toUpperCase();
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    await handleValidation('', true);

    if (JSON.stringify(errors) === JSON.stringify({})) {
      try {
        await signIn(entity);
        localStorage.setItem('@3rsistemas-gerencial/name', entity.name);
        navigate('/dashboard');
      } catch (err) {
        addToast('Nome e senha não conferem, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logo} alt="3r" />
          <form onSubmit={e => handleSubmit(e)}>
            <h1>Faça seu login</h1>
            <Input
              onBlur={e => handleValidation(e.target.id)}
              onChange={e => {
                handleChanges(e.target.id, e.target.value, 'login');
              }}
              error={errors.name}
              value={entity.name}
              id="name"
              icon={FiUser}
              placeholder="Digite seu nome"
            />
            <Input
              onBlur={e => handleValidation(e.target.id)}
              onChange={e => handleChanges(e.target.id, e.target.value)}
              error={errors.password}
              value={entity.password}
              id="password"
              icon={FiLock}
              placeholder="Digite sua senha"
              type="password"
            />
            <Button type="submit">Entrar</Button>
          </form>
        </AnimationContainer>
      </Content>
      <Background>
        <ReactPlayer
          width={1920}
          height={1080}
          playing
          muted
          loop
          url="https://videos.pexels.com/video-files/853919/853919-hd_1920_1080_25fps.mp4"
        />
      </Background>
    </Container>
  );
};

export default Login;
