import { ValidationError } from 'yup';
import { cnpj as cnpjOK, cpf as cpfOK } from 'cpf-cnpj-validator';

interface Errors {
  [key: string]: string;
}

export function getErrors(err: ValidationError): Errors {
  const validationErrors: Errors = {};

  err.inner.forEach(error => {
    if (error.path) {
      validationErrors[error.path] = error.message;
    }
  });

  return validationErrors;
}

export function getIndividualErrors(
  err: ValidationError,
  field: string,
  list: unknown,
): Errors {
  const validationErrors: Errors = list as Errors;

  // eslint-disable-next-line dot-notation
  delete validationErrors[field];
  err.inner.forEach(error => {
    if (error.path === field) {
      validationErrors[error.path] = error.message;
    }
  });

  return validationErrors;
}

export async function validCnpj(cnpj: string): Promise<boolean> {
  return cnpj !== '' ? cnpjOK.isValid(cnpj) : true;
}

export async function validCpf(cpf: string): Promise<boolean> {
  return cpf !== '' ? cpfOK.isValid(cpf) : true;
}

export function validFone(fone: string): boolean {
  return fone !== '' ? fone.match(/\(\d{2}\)\s\d{4,5}-\d{4}/) !== null : true;
}

export function validCep(cep: string): boolean {
  return cep !== '' ? cep.match(/^\d{5}-\d{3}$/) !== null : true;
}
