import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';
import Switch from 'react-switch';

import api from '../../../../services/api';

import { getIndividualErrors, getErrors, validCnpj, validCpf } from '../../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../../utils/handleForms';
import Select from '../../../../components/Select';

import Confirmation from '../../../../components/Confirmation';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
  FormFieldWithSearch,
} from '../../../../styles/registers';
import { cnpjMask, cpfMask, moneyMask } from '../../../../utils/handleMasks';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  empresa: string | undefined;
  descricao: string;
  bancoNome: string | null;
  bancoAgencia: string;
  bancoAgenciaDigito: string;
  bancoCedente: string;
  bancoCedenteDigito: string;
  bancoConvenio: string;
  seqNumero: number;
  seqByte: number;
  seqAno: number;
  tarifaCobrar: number;
  tarifaValor: number;
  protestarDiasApos: number;
  protestar: number;
  mensagemProtesto: string;
  jurosPercDia: number;
  multaPerc: number;
  apiChaveMaster: string;
  apiUrlSession: string;
  apiUrlActions: string;
  accessToken: string;
  beneficiarioFinalNome: string;
  beneficiarioFinalCpfCnpj: string;
  hibrido: number;
}

interface ErrorsData {
  [key: string]: string;
}

interface SelectData {
  label: string;
  value: string;
}

const InvoiceSettingRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idCompany } = useParams<{ idCompany: string }>();
  const { idInvoiceSetting } = useParams<{ idInvoiceSetting: string }>();
  const { addToast } = useToasts();
  const [selectBanco, setSelectBanco] = useState<SelectData[]>([]);

  const [fisicaJuridica, setFisicaJuridica] = useState('1');
  const [selectFisicaJuridica, setSelectFisicaJuridica] = useState<
    SelectData[]
  >([]);

  const [entity, setEntity] = useState<EntityData>({
    empresa: idCompany,
    descricao: '',
    bancoNome: 'SICREDI',
    bancoAgencia: '',
    bancoAgenciaDigito: '',
    bancoCedente: '',
    bancoCedenteDigito: '',
    bancoConvenio: '',
    seqNumero: 0,
    seqByte: 0,
    seqAno: 0,
    tarifaCobrar: 0,
    tarifaValor: 0,
    protestarDiasApos: 0,
    protestar: 0,
    mensagemProtesto: '',
    jurosPercDia: 0,
    multaPerc: 0,
    apiChaveMaster: '',
    apiUrlSession: '',
    apiUrlActions: '',
    accessToken: '',
    beneficiarioFinalNome: '',
    beneficiarioFinalCpfCnpj: '',
    hibrido: 0,
  });

  useEffect(() => {
    setErrors({});
    setEntityValidation({
      empresa: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Empresa'),
      descricao: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Descrição'),
      bancoNome: Yup.string().nullable(),
      bancoAgencia: Yup.string().nullable(),
      bancoAgenciaDigito: Yup.string().nullable(),
      bancoCedente: Yup.string().nullable(),
      bancoCedenteDigito: Yup.string().nullable(),
      bancoConvenio: Yup.string().nullable(),
      seqNumero: Yup.string().nullable(),
      seqByte: Yup.string().nullable(),
      seqAno: Yup.string().nullable(),
      tarifaCobrar: Yup.number().nullable(),
      tarifaValor: Yup.string().nullable(),
      protestarDiasApos: Yup.string().nullable(),
      protestar: Yup.number().nullable(),
      mensagemProtesto: Yup.string().nullable(),
      jurosPercDia: Yup.string().nullable(),
      multaPerc: Yup.string().nullable(),
      apiChaveMaster: Yup.string().nullable(),
      apiUrlSession: Yup.string().nullable(),
      apiUrlActions: Yup.string().nullable(),
      accessToken: Yup.string().nullable(),
      beneficiarioFinalNome: Yup.string().nullable(),
      beneficiarioFinalCpfCnpj: Yup.string()
        .nullable()
        .label(`${Number(fisicaJuridica) === 1 ? 'CNPJ' : 'CPF'}`)
        .test(
          'testCpfCnpj',
          Number(fisicaJuridica) === 1 ? 'CNPJ Inválido' : 'CPF Inválido',
          async function validate(value) {
            const result =
              Number(fisicaJuridica) === 1
                ? await validCnpj(value as string)
                : await validCpf(value as string);
            return result;
          },
        ),
      hibrido: Yup.number().nullable(),
    });

  }, [fisicaJuridica]);
  const [entityValidation, setEntityValidation] = useState({});
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    setSelectFisicaJuridica([
      {
        label: 'FÍSICA',
        value: '0',
      },
      {
        label: 'JURÍDICA',
        value: '1',
      },
    ]);

    async function loadEntity() {
      if (idInvoiceSetting !== undefined) {
        const response = await api.get(`/invoiceSettings/${idInvoiceSetting}`);
        const entityManipulated: any = loadForm(
          response.data,
          ['tarifaValor', 'jurosPercDia', 'multaPerc'],
          [],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;
        delete entityManipulated.sessionAccessToken;
        delete entityManipulated.sessionAccessTokenExpiresin;
        delete entityManipulated.sessionRefreshToken;
        delete entityManipulated.sessionRefreshTokenExpiresin;

        const { beneficiarioFinalCpfCnpj } = response.data;
        if (beneficiarioFinalCpfCnpj && beneficiarioFinalCpfCnpj.length === 18) {
          setFisicaJuridica('1');
        } else {
          setFisicaJuridica('0');
        }

        setEntity(entityManipulated);
      }
    }
    loadEntity();

    async function loadSelects() {
      setSelectBanco([
        {
          label: 'SICREDI',
          value: 'SICREDI',
        },
      ]);
    }
    loadSelects();
  }, [idInvoiceSetting]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  const handleChanges = useCallback(
    async (id: string, value: any, fieldType?: string) => {
      let newValue;
      if (fieldType === 'money') {
        newValue = moneyMask(value);
      } else if (fieldType === 'cpf') {
        newValue = cpfMask(value);
      } else if (fieldType === 'cnpj') {
        newValue = cnpjMask(value);
      } else {
        newValue = value;
      }
      const newEntity = changeForm(entity, id, newValue);
      setEntity((newEntity as unknown) as EntityData);
    },
    [entity],
  );

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, [
          'tarifaValor',
          'jurosPercDia',
          'multaPerc',
        ]);
        if (idInvoiceSetting === undefined) {
          await api.post('/invoiceSettings', entitySave);
        } else {
          await api.put(`/invoiceSettings/${idInvoiceSetting}`, entitySave);
        }
        addToast('Boleto gravado com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err) {
        addToast('Problemas ao gravar o boleto, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Boletos</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="32%">
              <span>
                {`
                Descrição *
                ${errors.descricao ? `(${errors.descricao})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.descricao}
                id="descricao"
                autoComplete="off"
                error={!!errors.descricao}
                placeholder="Digite o descrição"
              />
            </FormField>
            <FormField size="32%">
              <span>Nome do Banco</span>
              <Select
                name="bancoNome"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o "
                onChange={(e: any) => {
                  handleChanges('bancoNome', e.value);
                }}
                defaultValue={entity.bancoNome}
                options={selectBanco}
              />
            </FormField>
            <FormField size="5%">
              <span>
                {`
                Ano
                ${errors.seqAno ? `(${errors.seqAno})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.seqAno}
                id="seqAno"
                autoComplete="off"
                error={!!errors.seqAno}
                placeholder="Digite o ano"
              />
            </FormField>
            <FormField size="5%">
              <span>
                {`
                Byte
                ${errors.seqByte ? `(${errors.seqByte})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.seqByte}
                id="seqByte"
                autoComplete="off"
                error={!!errors.seqByte}
                placeholder="Digite o byte"
              />
            </FormField>
            <FormField size="6%">
              <span>
                {`
                Número
                ${errors.seqNumero ? `(${errors.seqNumero})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.seqNumero}
                id="seqNumero"
                autoComplete="off"
                error={!!errors.seqNumero}
                placeholder="Digite o número"
              />
            </FormField>
            <FormField size="10%">
              <span>
                {`Valor Tarifa
                ${errors.tarifaValor ? `(${errors.tarifaValor})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.tarifaValor}
                autoComplete="off"
                id="tarifaValor"
                error={!!errors.tarifaValor}
                placeholder="Digite o valor"
              />
            </FormField>
            <FormField size="10%" style={{ padding: 'center' }}>
              <span>Cobrar Tarifa</span>
              <Switch
                checked={!!entity.tarifaCobrar}
                onChange={() => {
                  handleChanges(
                    'tarifaCobrar',
                    entity.tarifaCobrar === 0 ? 1 : 0,
                  );
                }}
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="16%">
              <span>
                {`
                Convênio
                ${errors.bancoConvenio ? `(${errors.bancoConvenio})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.bancoConvenio}
                id="bancoConvenio"
                autoComplete="off"
                error={!!errors.bancoConvenio}
                placeholder="Digite o convênio"
              />
            </FormField>
            <FormField size="16%">
              <span>
                {`
                Agência
                ${errors.bancoAgencia ? `(${errors.bancoAgencia})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.bancoAgencia}
                id="bancoAgencia"
                autoComplete="off"
                error={!!errors.bancoAgencia}
                placeholder="Digite a agência"
              />
            </FormField>
            <FormField size="16%">
              <span>
                {`
                Posto
                ${errors.bancoAgenciaDigito
                    ? `(${errors.bancoAgenciaDigito})`
                    : ''
                  }
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.bancoAgenciaDigito}
                id="bancoAgenciaDigito"
                autoComplete="off"
                error={!!errors.bancoAgenciaDigito}
                placeholder="Digite o posto"
              />
            </FormField>
            <FormField size="16%">
              <span>
                {`
                Cedente
                ${errors.bancoCedente ? `(${errors.bancoCedente})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.bancoCedente}
                id="bancoCedente"
                autoComplete="off"
                error={!!errors.bancoCedente}
                placeholder="Digite o cedente"
              />
            </FormField>
            <FormField size="16%">
              <span>
                {`
                Cedente Dígito
                ${errors.bancoCedenteDigito
                    ? `(${errors.bancoCedenteDigito})`
                    : ''
                  }
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.bancoCedenteDigito}
                id="bancoCedenteDigito"
                autoComplete="off"
                error={!!errors.bancoCedenteDigito}
                placeholder="Digite o dígito do cedente"
              />
            </FormField>
            <FormField size="10%">
              <span>
                {`Juros ao dia %
                ${errors.jurosPercDia ? `(${errors.jurosPercDia})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.jurosPercDia}
                autoComplete="off"
                id="jurosPercDia"
                error={!!errors.jurosPercDia}
                placeholder="Digite o valor"
              />
            </FormField>
            <FormField size="10%">
              <span>
                {`Valor Multa %
                ${errors.multaPerc ? `(${errors.multaPerc})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.multaPerc}
                autoComplete="off"
                id="multaPerc"
                error={!!errors.multaPerc}
                placeholder="Digite o valor"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="5%">
              <span>Protestar</span>
              <Switch
                checked={!!entity.protestar}
                onChange={() => {
                  handleChanges('protestar', entity.protestar === 0 ? 1 : 0);
                }}
              />
            </FormField>
            <FormField size="11%">
              <span>
                {`
                Protestar Dias Após
                ${errors.protestarDiasApos
                    ? `(${errors.protestarDiasApos})`
                    : ''
                  }
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.protestarDiasApos}
                id="protestarDiasApos"
                autoComplete="off"
                error={!!errors.protestarDiasApos}
                placeholder="Digite os dias"
              />
            </FormField>
            <FormField size="84%">
              <span>
                {`
                Mensagem de protesto
                ${errors.mensagemProtesto ? `(${errors.mensagemProtesto})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.mensagemProtesto}
                id="mensagemProtesto"
                autoComplete="off"
                error={!!errors.mensagemProtesto}
                placeholder="Digite a mensagem de protesto"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="25%">
              <span>
                {`
                API Chave Master
                ${errors.apiChaveMaster ? `(${errors.apiChaveMaster})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.apiChaveMaster}
                id="apiChaveMaster"
                autoComplete="off"
                error={!!errors.apiChaveMaster}
                placeholder="Digite a Chave Master da API"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`
                API URL Sessão
                ${errors.apiUrlSession ? `(${errors.apiUrlSession})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.apiUrlSession}
                id="apiUrlSession"
                autoComplete="off"
                error={!!errors.apiUrlSession}
                placeholder="Digite a URL de Sessão da API"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`
                API URL Ações
                ${errors.apiUrlActions ? `(${errors.apiUrlActions})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.apiUrlActions}
                id="apiUrlActions"
                autoComplete="off"
                error={!!errors.apiUrlActions}
                placeholder="Digite a URL de Ações da API"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`
                API Access Token
                ${errors.accessToken ? `(${errors.accessToken})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.accessToken}
                id="accessToken"
                autoComplete="off"
                error={!!errors.accessToken}
                placeholder="Digite o Access Token da API"
              />
            </FormField>

          </FormLine>
          <FormLine>
            <FormField size="10%" style={{ padding: 'center' }}>
              <span>Boleto Híbrido</span>
              <Switch
                checked={!!entity.hibrido}
                onChange={() => {
                  handleChanges(
                    'hibrido',
                    entity.hibrido === 0 ? 1 : 0,
                  );
                }}
              />
            </FormField>
            <FormField size="20%">
              <span>Física/Jurídica</span>
              <Select
                name="fisicajuridica"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha entre física e jurídica"
                onChange={(e: any) => {
                  setFisicaJuridica(e.value);
                  handleChanges(
                    'beneficiarioFinalCpfCnpj',
                    entity.beneficiarioFinalCpfCnpj,
                    e.value === '1' ? 'cnpj' : 'cpf',
                  );
                }}
                defaultValue={fisicaJuridica}
                options={selectFisicaJuridica}
              />
            </FormField>
            <FormField size="35%">
              <span>
                {`
                  ${'Nome Beneficiário Final'}
                  ${errors.beneficiarioFinalNome ? `(${errors.beneficiarioFinalNome})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.beneficiarioFinalNome}
                autoComplete="off"
                id="beneficiarioFinalNome"
                error={!!errors.beneficiarioFinalNome}
                placeholder="Digite o nome"
              />
            </FormField>
            <FormFieldWithSearch size="35%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'CNPJ Beneficiário Final' : 'CPF Beneficiário Final'}
                ${errors.beneficiarioFinalCpfCnpj ? `(${errors.beneficiarioFinalCpfCnpj})` : ''}
                `}
              </span>
              <div>
                <FormFieldInput
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => {
                    handleChanges(
                      e.target.id,
                      e.target.value,
                      fisicaJuridica === '1' ? 'cnpj' : 'cpf',
                    );
                  }}
                  maxLength={fisicaJuridica === '1' ? 18 : 14}
                  value={entity.beneficiarioFinalCpfCnpj}
                  autoComplete="off"
                  id="beneficiarioFinalCpfCnpj"
                  placeholder={
                    fisicaJuridica === '1' ? 'Defina o CNPJ' : 'Defina o CPF'
                  }
                  error={!!errors.beneficiarioFinalCpfCnpj}
                />
              </div>
            </FormFieldWithSearch>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default InvoiceSettingRegister;
