import styled from 'styled-components';

interface CustomFiltersLineProps {
  first?: boolean;
  second?: boolean;
}

export const CustomFiltersLine = styled.div<CustomFiltersLineProps>`
  width: 100%;
  height: 50px;
  padding: 0 10px;
  margin-top: ${props => (props.first ? '17px' : '7px')};
  margin-bottom: ${props => props.second && '17px'};
  display: flex;
  flex-direction: row;
  align-items: center;
`;
