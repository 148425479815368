import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../services/api';

import { getIndividualErrors, getErrors } from '../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../utils/handleSelects';
import { moneyMask } from '../../../utils/handleMasks';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
} from '../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  cliente: string;
  dataEmissao: Date | null;
  valor: number;
  nfseSituacaoTributaria: string;
  nfseItemLista: string;
  nfseAliquota: number;
}

interface ErrorsData {
  [key: string]: string;
}

const FiscalDocumentEdit: React.FC = () => {
  const navigate = useNavigate();
  const { idFiscalDocument } = useParams<{ idFiscalDocument: string }>();
  const { addToast } = useToasts();

  const [selectClientes, setSelectClientes] = useState([]);
  const [entity, setEntity] = useState<EntityData>({
    cliente: '',
    dataEmissao: null,
    valor: 0,
    nfseSituacaoTributaria: '',
    nfseItemLista: '',
    nfseAliquota: 0,
  });

  const [entityValidation] = useState({
    cliente: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Cliente'),
    dataEmissao: Yup.date()
      .required('Preenchimento obrigatório')
      .label('Data Emissão'),
    valor: Yup.string().required('Preenchimento obrigatório').label('Valor'),
    nfseSituacaoTributaria: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Situação Tributária'),
    nfseItemLista: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Ítem Lista'),
    nfseAliquota: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Alíquota'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/clients');
        setSelectClientes(
          changeNamesOfColumns(response.data, 'fantasia', 'id'),
        );
      } catch (err) {
        //
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idFiscalDocument !== undefined) {
        const response = await api.get(`/fiscalDocuments/${idFiscalDocument}`);
        const entityManipulated: any = loadForm(
          response.data,
          ['valor', 'nfseAliquota'],
          ['dataEmissao'],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;
        delete entityManipulated.nfseDanfse;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idFiscalDocument]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    let newValue;
    if (type === 'money') {
      newValue = moneyMask(value);
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, ['valor', 'nfseAliquota']);
        await api.put(`/fiscalDocuments/${idFiscalDocument}`, entitySave);

        addToast('Nota fiscal alterada com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err) {
        addToast('Problemas ao alterar a nota fiscal, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Alteração de Notas Fiscais</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="80%">
              <span>
                {`
                Cliente *
                ${errors.cliente ? `(${errors.cliente})` : ''}
                `}
              </span>
              <Select
                name="cliente"
                height={40}
                maxMenuHeight={200}
                placeholder="Escolha o cliente"
                onBlur={(e: any) => handleValidation('cliente')}
                onChange={(e: any) => {
                  handleChanges('cliente', e.value);
                }}
                error={!!errors.cliente}
                defaultValue={entity.cliente}
                options={selectClientes}
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`
                Data de emissão *
                ${errors.dataEmissao ? `(${errors.dataEmissao})` : ''}
                `}
              </span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataEmissao')}
                error={!!errors.dataEmissao}
                onChange={(e: any) => {
                  handleChanges('dataEmissao', e, 'date');
                }}
                placeholderText="Defina a data de emissão"
                selected={entity.dataEmissao}
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`
                Valor *
                ${errors.valor ? `(${errors.valor})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.valor ? entity.valor : ''}
                autoComplete="off"
                id="valor"
                error={!!errors.valor}
                placeholder="Digite o valor"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>DADOS DA NFS-E</FormTitle>
          <FormLine>
            <FormField size="33.33%">
              <span>
                {`Situação tributária *
                ${errors.nfseSituacaoTributaria
                    ? `(${errors.nfseSituacaoTributaria})`
                    : ''
                  }
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.nfseSituacaoTributaria}
                autoComplete="off"
                id="nfseSituacaoTributaria"
                error={!!errors.nfseSituacaoTributaria}
                placeholder="Digite a situação tributária"
              />
            </FormField>
            <FormField size="33.33%">
              <span>
                {`Ítem da lista *
                ${errors.nfseItemLista ? `(${errors.nfseItemLista})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.nfseItemLista}
                autoComplete="off"
                id="nfseItemLista"
                error={!!errors.nfseItemLista}
                placeholder="Digite o item da lista"
              />
            </FormField>
            <FormField size="33.34%">
              <span>
                {`
                Alíquota *
                ${errors.nfseAliquota ? `(${errors.nfseAliquota})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.nfseAliquota ? entity.nfseAliquota : ''}
                autoComplete="off"
                id="nfseAliquota"
                error={!!errors.nfseAliquota}
                placeholder="Digite a alíquota"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default FiscalDocumentEdit;
