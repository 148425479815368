import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../../services/api';

import { getIndividualErrors, getErrors } from '../../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../../utils/handleSelects';

import Confirmation from '../../../../components/Confirmation';
import Select from '../../../../components/Select';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormTitle,
  FormButtons,
  Button,
  FormFieldInput,
} from '../../../../styles/registers';
import { manipulateFloats } from '../../../../utils/handleLists';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  cliente?: string;
  colaborador?: string;
  percentual: string | null;
}

interface ErrorsData {
  [key: string]: string;
}

const ClientComissionRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idClient } = useParams<{ idClient: string }>();
  const { idClientComission } = useParams<{ idClientComission: string }>();
  const { addToast } = useToasts();
  const [idClientComissionState, setIdClientComissionState] = useState(
    idClientComission,
  );

  const [entity, setEntity] = useState<EntityData>({
    cliente: idClient,
    colaborador: '',
    percentual: '0',
  });

  const [selectCollaborators, setSelectCollaborators] = useState([]);

  const [entityValidation] = useState({
    cliente: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Cliente'),
    colaborador: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Colaborador'),
    percentual: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Valor %')
      .min(0.01, 'Informe um valor maior que 0'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/collaborators', {
          params: {
            status: 'ativo',
          },
        });
        setSelectCollaborators(
          changeNamesOfColumns(response.data, 'nome', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idClientComission !== undefined) {
        const response = await api.get(
          `/clientComissions/${idClientComission}`,
        );
        const entityManipulated: any = loadForm(
          response.data,
          ['percentual'],
          [],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idClientComission]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, ['percentual']);
        if (idClientComission === undefined) {
          const response = await api.post('/clientComissions', entitySave);
          setIdClientComissionState(response.data.id);
          navigate(
            `/clients/register/${idClient}/comission/${response.data.id}`,
            { replace: true }
          );
          addToast('Comissão de colaborador inserida com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          await api.put(`/clientComissions/${idClientComission}`, entitySave);
          addToast('Comissão de colaborador editada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
        navigate(-1);
      } catch (err: any) {
        addToast(
          err.response
            ? err.response.data.message
            : 'Problemas ao gravar a comissão do colaborador, tente novamente',
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handleCollaborator(collaborator: string) {
    const response = await api.get(`/collaborators/${collaborator}`);

    const entityModified: EntityData = entity;
    entityModified.colaborador = collaborator;
    entityModified.percentual = manipulateFloats(response.data.valorComissao);

    setEntity(entityModified);
    document.getElementById('percentual')?.focus();
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Comissões de Colaborador</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="80%">
              <span>
                {`Colaborador *
                ${errors.colaborador ? `(${errors.colaborador})` : ''}
                `}
              </span>
              <Select
                name="colaborador"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o colaborador"
                onBlur={(e: any) => {
                  handleValidation('colaborador');
                }}
                onChange={(e: any) => {
                  handleCollaborator(e.value);
                }}
                error={!!errors.colaborador}
                defaultValue={entity.colaborador}
                options={selectCollaborators}
              />
            </FormField>
            <FormField size="20%">
              <span>
                {`Valor %
                ${errors.percentual ? `(${errors.percentual})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.percentual ? entity.percentual : ''}
                autoComplete="off"
                id="percentual"
                error={!!errors.percentual}
                placeholder="Digite o valor do percentual"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ClientComissionRegister;
