import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import 'react-datepicker/dist/react-datepicker.css';

import AppProvider from './hooks';
import GlobalStyle from './styles/global';
import Routes from './routes';
import RouteAuth from './routes/RouteAuth';

const App: React.FC = () => (
  <BrowserRouter>
    <ToastProvider>
      <AppProvider>
        <Routes />
        <RouteAuth />
      </AppProvider>
    </ToastProvider>
    <GlobalStyle />
  </BrowserRouter>
);

export default App;
