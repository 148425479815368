import styled from 'styled-components';

interface TableProps {
  height?: string;
}
interface TableTdProps {
  center?: boolean;
  color?: string;
}

export const Tables = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 20px 20px 20px;
  border-radius: 10px;
  //padding: 0 10px;
  height: 700px;
`;

export const Table = styled.div<TableProps>`
  width: 25%;
  background: #262424;
  border-radius: 10px;
  padding: 0 10px;
  & + div {
    margin-left: 10px;
  }
  h3 {
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 16px;
    color: #a53234;
  }

  width: 100%;
  min-height: ${props => (props.height ? props.height : '100px')};
  overflow: auto;
  // margin: 10px 0;

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(35, 33, 41, 0.8);
  }

  table {
    width: 100%;
    flex: 1;
    border-collapse: collapse;

    position: sticky;
    // z-index: 100;
    top: 0;

    thead {
      position: sticky;
      // z-index: 100;
      top: 0;

      background: transparent;
      border-bottom: 1px solid #2e2c2c;
      color: #d1cdcb;
      font-size: 12px;
      tr {
        height: 40px;
        width: 100%;
        min-width: 100%;

        th {
          position: sticky;
          // z-index: 100;
          top: 0;
          background: #262424;
          > div {
            width: 100%;
            display: flex;
            margin-bottom: 12px;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }
    tbody {
      tr {
        position: sticky;
        // z-index: 100;
        top: 0;

        height: 50px;
        &:hover {
          background: #2e2c2c;
          transition: background 0.4s;
        }
      }
    }
  }
`;

export const TableTd = styled.td<TableTdProps>`
  text-align: ${props => props.center && 'center'};
  border-bottom: 1px solid #2e2c2c;
  padding-right: 5px;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    color: ${props => (props.color ? props.color : '#888888')};
    svg {
      margin-right: 7px;
    }
  }
  > button {
    border: 0;
    background: transparent;
    width: 38px;
    height: 40px;
    &:disabled {
      cursor: not-allowed;
    }
  }
`;
