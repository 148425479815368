import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa';
import {
  FiCode,
  FiDollarSign,
  FiLayers,
  FiList,
  FiSettings,
  FiUser,
  FiUsers,
  FiColumns,
  FiGrid,
  FiHome,
  FiTrendingDown,
  FiTrendingUp,
  FiCreditCard,
  FiBook,
  FiBarChart,
  FiHexagon,
  FiBarChart2,
  FiUserCheck,
  FiPhoneCall,
  FiSun,
  FiAlertTriangle,
} from 'react-icons/fi';

import { Container, Button, Section } from './styles';

import { useAuth } from '../../hooks/AuthContext';

const Menu: React.FC = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();

  const [registerIsOpen, setRegisterIsOpen] = useState(false);
  const [complementaryIsOpen, setComplementaryIsOpen] = useState(false);
  const [registerFinancialIsOpen, setregisterFinancialIsOpen] = useState(false);
  const [customerServiceIsOpen, setCustomerServiceIsOpen] = useState(false);
  const [financialIsOpen, setFinancialIsOpen] = useState(false);
  const [peopleIsOpen, setPeopleIsOpen] = useState(false);
  const [optionIsOpen, setOptionIsOpen] = useState(false);

  function checkIsActive(button: string): boolean {
    const url = window.location.pathname.split('/');
    return !!(url[1] === `${button}`);
  }

  return (
    <Container>
      {/** Dashboard */}

      <Button
        onClick={() => navigate('/dashboard')}
        type="button"
        isOpen={false}
        isActive={checkIsActive('dashboard')}
      >
        <FiBarChart className="icon" size={20} color="#faede8" />
        Dashboard
      </Button>

      {/** Cadastros */}
      <Button
        onClick={() => setRegisterIsOpen(!registerIsOpen)}
        type="button"
        isOpen={registerIsOpen}
      >
        <FiHexagon className="icon" size={20} color="#faede8" />
        Cadastros
        <FaChevronDown className="arrow" size={18} color="#faede8" />
      </Button>
      <Section isOpen={registerIsOpen}>
        <Button
          isActive={checkIsActive('clients')}
          onClick={() => navigate('/clients')}
          type="button"
          numberOfChildren={2}
        >
          <FiUser className="icon" size={20} color="#faede8" />
          Clientes
        </Button>
        <Button
          isActive={checkIsActive('collaborators')}
          onClick={() => navigate('/collaborators')}
          type="button"
          numberOfChildren={2}
          style={user.nivel !== 1 ? { display: 'none' } : {}}
        >
          <FiUser className="icon" size={20} color="#faede8" />
          Colaboradores
        </Button>
        <Button
          isActive={checkIsActive('contacts')}
          onClick={() => navigate('/contacts')}
          type="button"
          numberOfChildren={2}
        >
          <FiUser className="icon" size={20} color="#faede8" />
          Contatos
        </Button>
        <Button
          isActive={checkIsActive('suppliers')}
          onClick={() => navigate('/suppliers')}
          type="button"
          numberOfChildren={2}
        >
          <FiUsers className="icon" size={20} color="#faede8" />
          Fornecedores
        </Button>
        <Button
          isActive={checkIsActive('products')}
          onClick={() => navigate('/products')}
          type="button"
          numberOfChildren={2}
        >
          <FiLayers className="icon" size={20} color="#faede8" />
          Produtos
        </Button>

        <Button
          onClick={() => setComplementaryIsOpen(!complementaryIsOpen)}
          type="button"
          isOpen={complementaryIsOpen}
          numberOfChildren={2}
        >
          <FiList className="icon" size={20} color="#faede8" />
          Complementares
          <FaChevronDown className="arrow" size={18} color="#faede8" />
        </Button>
        <Section isOpen={complementaryIsOpen}>
          <Button
            isActive={checkIsActive('emailSubjects')}
            onClick={() => navigate('/emailSubjects')}
            type="button"
            numberOfChildren={3}
            style={user.nivel !== 1 ? { display: 'none' } : {}}
          >
            <FiCreditCard className="icon" size={20} color="#faede8" />
            Assuntos de E-mail
          </Button>
        </Section>
        <Section isOpen={complementaryIsOpen}>
          <Button
            onClick={() => setregisterFinancialIsOpen(!registerFinancialIsOpen)}
            type="button"
            isOpen={registerFinancialIsOpen}
            numberOfChildren={3}
            style={user.nivel !== 1 ? { display: 'none' } : {}}
          >
            <FiColumns className="icon" size={20} color="#faede8" />
            Financeiro
            <FaChevronDown className="arrow" size={18} color="#faede8" />
          </Button>
          <Section isOpen={registerFinancialIsOpen}>
            <Button
              isActive={checkIsActive('paymentMethods')}
              onClick={() => navigate('/paymentMethods')}
              type="button"
              numberOfChildren={4}
              style={user.nivel !== 1 ? { display: 'none' } : {}}
            >
              <FiCreditCard className="icon" size={20} color="#faede8" />
              Formas Pgto
            </Button>
          </Section>
          <Section isOpen={registerFinancialIsOpen}>
            <Button
              isActive={checkIsActive('cashPlaces')}
              onClick={() => navigate('/cashPlaces')}
              type="button"
              numberOfChildren={4}
              style={user.nivel !== 1 ? { display: 'none' } : {}}
            >
              <FiBook className="icon" size={20} color="#faede8" />
              Caixas
            </Button>
          </Section>
          <Section isOpen={registerFinancialIsOpen}>
            <Button
              isActive={checkIsActive('cashPlaceGroups')}
              onClick={() => navigate('/cashPlaceGroups')}
              type="button"
              numberOfChildren={4}
              style={user.nivel !== 1 ? { display: 'none' } : {}}
            >
              <FiBook className="icon" size={20} color="#faede8" />
              Grupos
            </Button>
          </Section>
          <Section isOpen={registerFinancialIsOpen}>
            <Button
              isActive={checkIsActive('cashPlaceSubGroups')}
              onClick={() => navigate('/cashPlaceSubGroups')}
              type="button"
              numberOfChildren={4}
              style={user.nivel !== 1 ? { display: 'none' } : {}}
            >
              <FiBook className="icon" size={20} color="#faede8" />
              SubGrupos
            </Button>
          </Section>
          <Section isOpen={registerFinancialIsOpen}>
            <Button
              isActive={checkIsActive('cashPlaceAccounts')}
              onClick={() => navigate('/cashPlaceAccounts')}
              type="button"
              numberOfChildren={4}
              style={user.nivel !== 1 ? { display: 'none' } : {}}
            >
              <FiBook className="icon" size={20} color="#faede8" />
              Contas
            </Button>
          </Section>
          <Button
            onClick={() => setPeopleIsOpen(!peopleIsOpen)}
            type="button"
            isOpen={peopleIsOpen}
            numberOfChildren={3}
          >
            <FiUsers className="icon" size={20} color="#faede8" />
            Pessoas
            <FaChevronDown className="arrow" size={18} color="#faede8" />
          </Button>
          <Section isOpen={peopleIsOpen}>
            <Button
              isActive={checkIsActive('collaboratorFunctions')}
              onClick={() => navigate('/collaboratorFunctions')}
              type="button"
              numberOfChildren={4}
            >
              <FiLayers className="icon" size={20} color="#faede8" />
              Funções de colabs.
            </Button>
          </Section>
          <Section isOpen={peopleIsOpen}>
            <Button
              isActive={checkIsActive('clientGroups')}
              onClick={() => navigate('/clientGroups')}
              type="button"
              numberOfChildren={4}
            >
              <FiLayers className="icon" size={20} color="#faede8" />
              Grupos de clientes
            </Button>
          </Section>
          <Section isOpen={peopleIsOpen}>
            <Button
              isActive={checkIsActive('clientTypes')}
              onClick={() => navigate('/clientTypes')}
              type="button"
              numberOfChildren={4}
            >
              <FiLayers className="icon" size={20} color="#faede8" />
              Tipos de clientes
            </Button>
          </Section>
          <Section isOpen={peopleIsOpen}>
            <Button
              isActive={checkIsActive('contactTypes')}
              onClick={() => navigate('/contactTypes')}
              type="button"
              numberOfChildren={4}
            >
              <FiLayers className="icon" size={20} color="#faede8" />
              Tipos de contatos
            </Button>
          </Section>
        </Section>
      </Section>

      {/** Interesses */}
      <Button
        onClick={() => navigate('/leads')}
        type="button"
        isOpen={false}
        isActive={checkIsActive('leads')}
      >
        <FiUserCheck className="icon" size={20} color="#faede8" />
        Interesses
      </Button>

      {/** Atendimentos */}
      <Button
        onClick={() => setCustomerServiceIsOpen(!customerServiceIsOpen)}
        type="button"
        isOpen={customerServiceIsOpen}
      >
        <FiUsers className="icon" size={20} color="#faede8" />
        Suporte
        <FaChevronDown className="arrow" size={18} color="#faede8" />
      </Button>
      <Section isOpen={customerServiceIsOpen}>
        <Button
          isActive={checkIsActive('customerServices')}
          onClick={() => navigate('/customerServices')}
          type="button"
          numberOfChildren={2}
        >
          <FiPhoneCall className="icon" size={20} color="#faede8" />
          Atendimentos
        </Button>
        <Button
          isActive={checkIsActive('failures')}
          onClick={() => navigate('/failures')}
          type="button"
          numberOfChildren={2}
        >
          <FiAlertTriangle className="icon" size={20} color="#faede8" />
          Ocorrência de Falhas
        </Button>
        <Button
          isActive={checkIsActive('problemsSolutions')}
          onClick={() => navigate('/problemsSolutions')}
          type="button"
          numberOfChildren={2}
        >
          <FiSun className="icon" size={20} color="#faede8" />
          Problemas e soluções
        </Button>
      </Section>

      {/** Tarefas */}
      <Button
        onClick={() => navigate('/tasks')}
        type="button"
        isOpen={false}
        isActive={checkIsActive('tasks')}
      >
        <FiCode className="icon" size={20} color="#faede8" />
        Tarefas
      </Button>

      {/** Gerenciamento */}
      <Button
        onClick={() => navigate('/managements')}
        type="button"
        isOpen={false}
        isActive={checkIsActive('managements')}
      >
        <FiCode className="icon" size={20} color="#faede8" />
        Gerenciam. Easy Cloud
      </Button>

      {/** Financeiro */}
      <Button
        onClick={() => setFinancialIsOpen(!financialIsOpen)}
        type="button"
        isOpen={financialIsOpen}
        style={user.nivel === 3 ? { display: 'none' } : {}}
      >
        <FiDollarSign className="icon" size={20} color="#faede8" />
        Financeiro
        <FaChevronDown className="arrow" size={18} color="#faede8" />
      </Button>
      <Section isOpen={financialIsOpen}>
        <Button
          onClick={() => navigate('/receiptsDC')}
          type="button"
          isActive={checkIsActive('receiptsDC')}
          numberOfChildren={2}
          style={user.nivel !== 1 ? { display: 'none' } : {}}
        >
          <FiBarChart2 className="icon" size={20} color="#faede8" />
          Acréscimos e Descontos
        </Button>
        <Button
          onClick={() => navigate('/payments')}
          type="button"
          isActive={checkIsActive('payments')}
          numberOfChildren={2}
          style={user.nivel !== 1 ? { display: 'none' } : {}}
        >
          <FiTrendingDown className="icon" size={20} color="#faede8" />
          Contas a pagar
        </Button>
        <Button
          onClick={() => navigate('/receipts')}
          type="button"
          isActive={checkIsActive('receipts')}
          numberOfChildren={2}
          style={user.nivel === 3 ? { display: 'none' } : {}}
        >
          <FiTrendingUp className="icon" size={20} color="#faede8" />
          Contas a receber
        </Button>
      </Section>

      {/** Notas fiscais */}
      <Button
        onClick={() => navigate('/fiscalDocuments')}
        type="button"
        isOpen={false}
        isActive={checkIsActive('fiscalDocuments')}
        style={user.nivel === 3 ? { display: 'none' } : {}}
      >
        <FiGrid className="icon" size={20} color="#faede8" />
        Notas fiscais
      </Button>

      {/** Opções */}
      <Button
        onClick={() => setOptionIsOpen(!optionIsOpen)}
        type="button"
        isOpen={optionIsOpen}
        isActive={checkIsActive('options')}
      >
        <FiSettings className="icon" size={20} color="#faede8" />
        Opções
        <FaChevronDown className="arrow" size={18} color="#faede8" />
      </Button>
      <Section isOpen={optionIsOpen}>
        <Button
          onClick={() => navigate('/companys')}
          type="button"
          isActive={checkIsActive('companys')}
          numberOfChildren={2}
        >
          <FiHome className="icon" size={20} color="#faede8" />
          Empresas
        </Button>
      </Section>
    </Container>
  );
};

export default Menu;
