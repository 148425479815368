import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import { FaSearch } from 'react-icons/fa';
import api from '../../../services/api';

import {
  getIndividualErrors,
  getErrors,
  validFone,
  validCep,
  validCpf,
} from '../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../utils/handleSelects';
import { cpfMask, cepMask, foneMask } from '../../../utils/handleMasks';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldWithSearch,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
} from '../../../styles/registers';
import { getCEP } from '../../../utils/handleCEP';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  nome: string;
  cpf: string;
  rg: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  cidade: string;
  telefone1: string;
  telefone2: string;
  email1: string;
  email2: string;
  dataNascimento: Date | null;
  dataInativo: Date | null;
  funcao: string;
  usuario: string;
  valorComissao: number | null;
}
interface ErrorsData {
  [key: string]: string;
}

const ContactRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idCollaborator } = useParams<{ idCollaborator: string }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    nome: '',
    cpf: '',
    rg: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    cidade: '',
    telefone1: '',
    telefone2: '',
    email1: '',
    email2: '',
    dataNascimento: null,
    dataInativo: null,
    funcao: '',
    usuario: '',
    valorComissao: 0,
  });

  const [idCollaboratorState, setIdCollaboratorState] = useState(
    idCollaborator,
  );

  const [selectCidades, setSelectCidades] = useState([]);
  const [selectFuncoes, setSelectFuncoes] = useState([]);
  const [selectUsuarios, setSelectUsuarios] = useState([]);

  const [entityValidation, setEntityValidation] = useState({});
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      let response;

      try {
        response = await api.get('/cities');
        setSelectCidades(changeNamesOfColumns(response.data, 'nome', 'id'));
      } catch (err) {
        // manipulacao do erro
      }

      try {
        response = await api.get('/collaboratorFunctions');
        setSelectFuncoes(
          changeNamesOfColumns(response.data, 'descricao', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }

      try {
        response = await api.get('/users');
        setSelectUsuarios(changeNamesOfColumns(response.data, 'nome', 'id'));
      } catch (err) {
        // manipulacao do erro
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idCollaboratorState !== undefined) {
        const response = await api.get(`/collaborators/${idCollaboratorState}`);
        const entityManipulated: any = loadForm(
          response.data,
          ['valorComissao'],
          ['dataInativo', 'dataNascimento'],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idCollaboratorState]);

  useEffect(() => {
    setErrors({});
    setEntityValidation({
      nome: Yup.string().required('Preenchimento obrigatório').label('Nome'),
      cpf: Yup.string()
        .required('Preenchimento obrigatório')
        .label('CPF')
        .test('testCpf', 'CPF Inválido', async function validate(value) {
          const result = await validCpf(value as string);
          return result;
        }),
      rg: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Registro Geral'),
      logradouro: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Logradouro'),
      numero: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Número'),
      complemento: Yup.string(),
      bairro: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Bairro'),
      cep: Yup.string()
        .required('Preenchimento obrigatório')
        .label('CEP')
        .test('testeCEPOK', 'CEP com formato inválido', function validate(
          value,
        ) {
          return validCep(value as string);
        }),
      cidade: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Cidade'),
      telefone1: Yup.string()
        .nullable()
        .label('Telefone 1')
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        }),
      telefone2: Yup.string()
        .nullable()
        .label('Telefone 2')
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        }),
      email1: Yup.string()
        .email('E-mail com formato inválido')
        .label('E-mail 1'),
      email2: Yup.string()
        .email('E-mail com formato inválido')
        .label('E-mail 2'),
      dataNascimento: Yup.date().nullable(),
      dataInativo: Yup.date().nullable(),
      funcao: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Função'),
      usuario: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Usuário'),
      valorComissao: Yup.string().nullable(),
    });
  }, []);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    let newValue;
    if (type === 'cpf') {
      newValue = cpfMask(value);
    } else if (type === 'cep') {
      newValue = cepMask(value);
    } else if (type === 'fone') {
      newValue = foneMask(value);
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, ['valorComissao']);
        if (idCollaborator === undefined) {
          await api.post('/collaborators', entitySave);
        } else {
          await api.put(`/collaborators/${idCollaborator}`, entitySave);
        }
        addToast('Colaborador gravado com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err) {
        addToast('Problemas ao gravar o colaborador, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handleSearchCEP(cep: string) {
    try {
      const data = await getCEP(cep);

      if (data.erro) {
        addToast('CEP não encontrado, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      const entityModified: EntityData = entity;
      if (data.logradouro) {
        entityModified.logradouro = data.logradouro;
      }

      if (data.bairro) entityModified.bairro = data.bairro;

      if (data.localidade) {
        const cityReponse = await api.get(`cities/`, {
          params: {
            nomeCompleto: data.localidade.toUpperCase(),
          },
        });
        entityModified.cidade = cityReponse.data[0].id;
      }

      setEntity(entityModified);
      document.getElementById('bairro')?.focus();
      document.getElementById('logradouro')?.focus();
    } catch (err) {
      addToast('Problemas ao buscar dados do CEP, tente novamente', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Colaboradores</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="100%">
              <span>
                {`Nome *
                ${errors.nome ? `(${errors.nome})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.nome}
                id="nome"
                autoComplete="off"
                error={!!errors.nome}
                placeholder="Digite o nome"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="25%">
              <span>
                {`CPF *
                ${errors.cpf ? `(${errors.cpf})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'cpf');
                }}
                value={entity.cpf}
                id="cpf"
                autoComplete="off"
                error={!!errors.cpf}
                placeholder="Digite o cpf"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`RG *
                ${errors.rg ? `(${errors.rg})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.rg}
                id="rg"
                autoComplete="off"
                error={!!errors.rg}
                placeholder="Digite o RG"
              />
            </FormField>
            <FormField size="25%">
              <span>Data de nascimento</span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataNascimento')}
                error={!!errors.dataNascimento}
                onChange={(e: any) => {
                  handleChanges('dataNascimento', e, 'date');
                }}
                placeholderText="Defina a data de nascimento"
                selected={entity.dataNascimento}
              />
            </FormField>
            <FormField size="25%">
              <span>Data de inativação</span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataInativo')}
                error={!!errors.dataInativo}
                onChange={(e: any) => {
                  handleChanges('dataInativo', e, 'date');
                }}
                placeholderText="Defina a data de inativação"
                selected={entity.dataInativo}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>ENDEREÇOS</FormTitle>
          <FormLine>
            <FormFieldWithSearch size="20%">
              <span>
                {`CEP *
                ${errors.cep ? `(${errors.cep})` : ''}
                `}
              </span>
              <div>
                <FormFieldInput
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => {
                    handleChanges(e.target.id, e.target.value, 'cep');
                  }}
                  value={entity.cep}
                  id="cep"
                  maxLength={9}
                  autoComplete="off"
                  error={!!errors.cep}
                  placeholder="Digite o CEP"
                />
                <button
                  onClick={() => handleSearchCEP(entity.cep)}
                  type="button"
                >
                  <FaSearch size={20} color="#faede8" />
                </button>
              </div>
            </FormFieldWithSearch>

            <FormField size="80%">
              <span>
                {`Logradouro *
                ${errors.logradouro ? `(${errors.logradouro})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.logradouro}
                autoComplete="off"
                id="logradouro"
                error={!!errors.logradouro}
                placeholder="Digite o logradouro"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="20%">
              <span>
                {`Número *
                ${errors.numero ? `(${errors.numero})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.numero}
                autoComplete="off"
                id="numero"
                error={!!errors.numero}
                placeholder="Digite o número"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`Bairro *
                ${errors.bairro ? `(${errors.bairro})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.bairro}
                autoComplete="off"
                id="bairro"
                error={!!errors.bairro}
                placeholder="Digite o bairro"
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Complemento
                ${errors.complemento ? `(${errors.complemento})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.complemento}
                autoComplete="off"
                id="complemento"
                error={!!errors.complemento}
                placeholder="Digite o complemento"
              />
            </FormField>
            <FormField size="40%">
              <span>
                {`Cidade *
                ${errors.cidade ? `(${errors.cidade})` : ''}
                `}
              </span>
              <Select
                name="cidade"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha a cidade"
                onBlur={(e: any) => handleValidation('cidade')}
                onChange={(e: any) => {
                  handleChanges('cidade', e.value);
                }}
                error={!!errors.cidade}
                defaultValue={entity.cidade}
                options={selectCidades}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>CONTATO PRINCIPAL</FormTitle>
          <FormLine>
            <FormField size="50%">
              <span>
                {`Telefone 1
                ${errors.telefone1 ? `(${errors.telefone1})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone1}
                autoComplete="off"
                id="telefone1"
                maxLength={15}
                error={!!errors.telefone1}
                placeholder="Digite o telefone"
              />
            </FormField>
            <FormField size="50%">
              <span>
                {`Telefone 2
                ${errors.telefone2 ? `(${errors.telefone2})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone2}
                autoComplete="off"
                id="telefone2"
                maxLength={15}
                error={!!errors.telefone2}
                placeholder="Digite o telefone"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="50%">
              <span>
                {`Email 1
                ${errors.email1 ? `(${errors.email1})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email1}
                autoComplete="off"
                id="email1"
                error={!!errors.email1}
                placeholder="Digite o e-mail"
              />
            </FormField>
            <FormField size="50%">
              <span>
                {`Email 2
                ${errors.email2 ? `(${errors.email2})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email2}
                autoComplete="off"
                id="email2"
                error={!!errors.email2}
                placeholder="Digite o e-mail"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>OUTRAS INFORMAÇÕES</FormTitle>
          <FormLine>
            <FormField size="40%">
              <span>
                {`Função *
                ${errors.funcao ? `(${errors.funcao})` : ''}
                `}
              </span>
              <Select
                name="funcao"
                height={40}
                maxMenuHeight={80}
                placeholder="Escolha a funcao"
                onBlur={(e: any) => handleValidation('funcao')}
                onChange={(e: any) => {
                  handleChanges('funcao', e.value);
                }}
                error={!!errors.funcao}
                defaultValue={entity.funcao}
                options={selectFuncoes}
              />
            </FormField>
            <FormField size="40%">
              <span>
                {`Usuário *
                ${errors.usuario ? `(${errors.usuario})` : ''}
                `}
              </span>
              <Select
                name="usuario"
                height={40}
                maxMenuHeight={80}
                placeholder="Escolha o usuario"
                onBlur={(e: any) => handleValidation('usuario')}
                onChange={(e: any) => {
                  handleChanges('usuario', e.value);
                }}
                error={!!errors.usuario}
                defaultValue={entity.usuario}
                options={selectUsuarios}
              />
            </FormField>
            <FormField size="20%">
              <span>
                {`Valor %
                ${errors.valorComissao ? `(${errors.valorComissao})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.valorComissao ? entity.valorComissao : ''}
                autoComplete="off"
                id="valorComissao"
                error={!!errors.valorComissao}
                placeholder="Digite o valor da comissão "
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ContactRegister;
