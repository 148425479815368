import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle, FiEdit, FiTrash } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';

import api from '../../../services/api';

import {
  getIndividualErrors,
  getErrors,
  validCpf,
  validCnpj,
  validFone,
} from '../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../utils/handleSelects';
import {
  cpfMask,
  cnpjMask,
  cepMask,
  foneMask,
  moneyMask,
} from '../../../utils/handleMasks';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormTable,
  FormButtons,
  Table,
  TableTd,
  Button,
  TableHeader,
  TableHeaderColumn,
} from '../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}
interface EntityData {
  razaoSocial: string;
  fantasia: string;
  cpfCnpj: string;
  rgIe: string;
  im: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  cidade: string;
  telefone: string;
  email: string;
}

interface EntityNFSeData {
  empresa: string;
  situacaoTributaria: number;
  itemLista: string;
  aliquota: number;
  login?: string;
  senha?: string;
}

interface EntityInvoiceSettingData {
  id?: string;
  descricao?: string;
}

interface ErrorsData {
  [key: string]: string;
}

interface SelectData {
  label: string;
  value: string;
}

const CompanyRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idCompany } = useParams<{ idCompany: string }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    razaoSocial: '',
    fantasia: '',
    cpfCnpj: '',
    rgIe: '',
    im: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    cidade: '',
    telefone: '',
    email: '',
  });
  const [entityNFSe, setEntityNFSe] = useState<EntityNFSeData>({
    empresa: '',
    situacaoTributaria: 1,
    itemLista: '',
    aliquota: 0,
    login: '',
    senha: '',
  });

  const [fisicaJuridica, setFisicaJuridica] = useState('1');
  const [idCompanyState, setIdCompanyState] = useState(idCompany);
  const [idFiscalDocumentSettings, setIdFiscalDocumentSettings] = useState();

  const [entityInvoiceSettings, setEntityInvoiceSettings] = useState<
    EntityInvoiceSettingData[]
  >([{}]);

  const [selectCidades, setSelectCidades] = useState([]);
  const [selectFisicaJuridica, setSelectFisicaJuridica] = useState<
    SelectData[]
  >([]);

  const [entityValidation, setEntityValidation] = useState({});
  const [entityNFSeValidation] = useState({
    situacaoTributaria: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Situação Tributária'),
    itemLista: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Item Lista'),
    aliquota: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Aliquota'),
    login: Yup.string().nullable().label('Login'),
    senha: Yup.string().nullable().label('Senha'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});
  const [errorsNFSe, setErrorsNFSe] = useState<ErrorsData>({});

  const loadInvoiceSettingEntity = useCallback(async () => {
    if (!idCompanyState) return;

    try {
      const response = await api.get('/invoiceSettings', {
        params: {
          empresa: idCompanyState,
        },
      });
      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          descricao: item.descricao,
        };
      });
      setEntityInvoiceSettings(responseDisassembled);
    } catch (error) {
    }
  }, [idCompanyState]);

  useEffect(() => {
    async function loadSelects() {
      let response;

      try {
        response = await api.get('/cities');
        setSelectCidades(changeNamesOfColumns(response.data, 'nome', 'id'));
      } catch (err) {
        // manipulacao do erro
      }

      setSelectFisicaJuridica([
        {
          label: 'FÍSICA',
          value: '0',
        },
        {
          label: 'JURÍDICA',
          value: '1',
        },
      ]);
    }
    loadSelects();

    async function loadEntity() {
      if (idCompanyState !== undefined) {
        const response = await api.get(`/companys/${idCompanyState}`);
        const entityManipulated: any = loadForm(response.data, [], [], []);

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);

        const { cpfCnpj } = response.data;
        if (cpfCnpj.length === 18) {
          setFisicaJuridica('1');
        } else {
          setFisicaJuridica('0');
        }

        let responseNFSe = await api.get(`/fiscalDocumentSettings`, {
          params: {
            empresa: idCompanyState,
          },
        });
        let entityNFSeManipulated: any = loadForm(
          responseNFSe.data[0],
          ['aliquota'],
          [],
          [],
        );
        if (responseNFSe.status !== 200) {
          responseNFSe = await api.post('/fiscalDocumentSettings', {
            situacaoTributaria: 1,
            itemLista: '00.00',
            aliquota: 0,
            empresa: idCompanyState,
          });
          entityNFSeManipulated = loadForm(
            responseNFSe.data,
            ['aliquota'],
            [],
            [],
          );
        }
        setIdFiscalDocumentSettings(entityNFSeManipulated.id);
        delete entityNFSeManipulated.id;
        delete entityNFSeManipulated.empresas;
        delete entityNFSeManipulated.created_at;
        delete entityNFSeManipulated.updated_at;
        setEntityNFSe(entityNFSeManipulated);
      }
    }
    loadEntity();

    loadInvoiceSettingEntity();
  }, [idCompanyState, loadInvoiceSettingEntity]);

  useEffect(() => {
    setErrors({});
    setEntityValidation({
      razaoSocial: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Razão Social'),
      fantasia: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Fantasia'),
      cpfCnpj: Yup.string()
        .required('Preenchimento obrigatório')
        .test(
          'testCpfCnpj',
          fisicaJuridica === '1' ? 'CNPJ Inválido' : 'CPF Inválido',
          async function validate(value) {
            const result =
              fisicaJuridica === '1'
                ? await validCnpj(value as string)
                : await validCpf(value as string);
            return result;
          },
        )
        .label('CPF/CNPJ'),
      rgIe: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Registro Geral/Inscrição Estadual'),
      im: Yup.string().label('Inscrição Municipal'),
      logradouro: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Logradouro'),
      numero: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Número'),
      complemento: Yup.string().label('Complemento'),
      bairro: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Bairro'),
      cep: Yup.string()
        .required('Preenchimento obrigatório')
        .length(9)
        .matches(/^\d{5}-\d{3}$/, 'CEP com formato inválido')
        .label('CEP'),
      cidade: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Cidade'),
      telefone: Yup.string()
        .nullable()
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        })
        .label('Telefone'),
      email: Yup.string().email('E-mail com formato inválido').label('Email'),
    });
  }, [fisicaJuridica]);

  async function handleValidation(
    field: string,
    general = false,
    isNFSe = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(
      isNFSe ? entityNFSeValidation : entityValidation,
    );
    await schema
      .validate(isNFSe ? entityNFSe : entity, {
        abortEarly: false,
      })
      .then(() => {
        // eslint-disable-next-line no-unused-expressions
        isNFSe ? setErrorsNFSe({}) : setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, isNFSe ? errorsNFSe : errors);
        // eslint-disable-next-line no-unused-expressions
        isNFSe
          ? setErrorsNFSe(JSON.parse(JSON.stringify(returnedErrors)))
          : setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(
    id: string,
    value: any,
    isNFSe = false,
    type?: string,
  ) {
    let newValue;
    if (type === 'cpf') {
      newValue = cpfMask(value);
    } else if (type === 'money') {
      newValue = moneyMask(value);
    } else if (type === 'cnpj') {
      newValue = cnpjMask(value);
    } else if (type === 'cep') {
      newValue = cepMask(value);
    } else if (type === 'fone') {
      newValue = foneMask(value);
    } else {
      newValue = value;
    }
    if (isNFSe) {
      const newEntity = changeForm(entityNFSe, id, newValue);
      setEntityNFSe((newEntity as unknown) as EntityNFSeData);
    } else {
      const newEntity = changeForm(entity, id, newValue);
      setEntity((newEntity as unknown) as EntityData);
    }
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    const validationNFSe = await handleValidation('', true, true);

    if (validation.b && validationNFSe.b) {
      try {
        const entitySave = saveForm(entity, []);
        const entityNFSeSave = saveForm(entityNFSe, ['aliquota']);

        if (idCompanyState === undefined) {
          delete entitySave.codigo;
          const response = await api.post('/companys', entitySave);
          setIdCompanyState(response.data.id);
          navigate(`/companys/register/${response.data.id}`, { replace: true });
          addToast(
            'Empresa inserida com sucesso, clique novamente para fechar ou insira registros secundários',
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );

          await api.post('/fiscalDocumentSettings', entityNFSeSave);
        } else {
          await api.put(`/companys/${idCompanyState}`, entitySave);

          delete entityNFSeSave.id;
          await api.put(
            `/fiscalDocumentSettings/${idFiscalDocumentSettings}`,
            entityNFSeSave,
          );

          navigate(-1);
          addToast('Empresa editada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      } catch (err) {
        addToast('Problemas ao gravar a empresa, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(
        `Defina: ${!validation.b
          ? validation.fieldNameError
          : validationNFSe.fieldNameError
        }`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
  }

  function handleInvoiceSettingDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/invoiceSettings/${id}`);
          setEntityInvoiceSettings([{}]);
          loadInvoiceSettingEntity();
          addToast('Boleto deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o boleto, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o boleto ?',
      'delete',
    );
  }

  function handleInvoiceSettingEdit(id?: string) {
    navigate(`/companys/register/${idCompanyState}/invoiceSetting/${id}`);
  }

  function handleInvoiceSettingInsert() {
    navigate(`/companys/register/${idCompanyState}/invoiceSetting`);
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Empresas</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="50%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'Razão social *' : 'Nome *'}
                ${errors.razaoSocial ? `(${errors.razaoSocial})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, false);
                }}
                value={entity.razaoSocial}
                id="razaoSocial"
                autoComplete="off"
                error={!!errors.razaoSocial}
                placeholder={
                  fisicaJuridica === '1'
                    ? 'Digite a razão social'
                    : 'Digite o nome'
                }
              />
            </FormField>
            <FormField size="50%">
              <span>
                {`
                 ${fisicaJuridica === '1' ? 'Nome fantasia *' : 'Apelido *'}
                ${errors.fantasia ? `(${errors.fantasia})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, false);
                }}
                value={entity.fantasia}
                autoComplete="off"
                id="fantasia"
                error={!!errors.fantasia}
                placeholder={
                  fisicaJuridica === '1'
                    ? 'Digite o nome fantasia'
                    : 'Digite o apelido'
                }
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="15%">
              <span>Física/Jurídica</span>
              <Select
                name="fisicajuridica"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha entre física e jurídica"
                onChange={(e: any) => {
                  setFisicaJuridica(e.value);
                  handleChanges(
                    'cpfCnpj',
                    entity.cpfCnpj,
                    false,
                    e.value === '1' ? 'cnpj' : 'cpf',
                  );
                }}
                defaultValue={fisicaJuridica}
                options={selectFisicaJuridica}
              />
            </FormField>
            <FormField size="35%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'CNPJ *' : 'CPF *'}
                ${errors.cpfCnpj ? `(${errors.cpfCnpj})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(
                    e.target.id,
                    e.target.value,
                    false,
                    fisicaJuridica === '1' ? 'cnpj' : 'cpf',
                  );
                }}
                maxLength={fisicaJuridica === '1' ? 18 : 14}
                value={entity.cpfCnpj}
                autoComplete="off"
                id="cpfCnpj"
                placeholder={
                  fisicaJuridica === '1' ? 'Defina o CNPJ' : 'Defina o CPF'
                }
                error={!!errors.cpfCnpj}
              />
            </FormField>
            <FormField size="35%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'I.E *' : 'I.E/RG *'}
                ${errors.rgIe ? `(${errors.rIge})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, false);
                }}
                value={entity.rgIe}
                id="rgIe"
                autoComplete="off"
                error={!!errors.rgIe}
                placeholder={
                  fisicaJuridica === '1'
                    ? 'Digite a inscrição estadual'
                    : 'Digite o RG ou a inscrição estadual'
                }
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`I.M
                ${errors.im ? `(${errors.im})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, false);
                }}
                value={entity.im}
                id="im"
                autoComplete="off"
                error={!!errors.im}
                placeholder="Digite a inscrição municipal"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>ENDEREÇOS</FormTitle>
          <FormLine>
            <FormField size="20%">
              <span>
                {`CEP *
                ${errors.cep ? `(${errors.cep})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, false, 'cep');
                }}
                value={entity.cep}
                id="cep"
                maxLength={9}
                autoComplete="off"
                error={!!errors.cep}
                placeholder="Digite o CEP"
              />
            </FormField>
            <FormField size="80%">
              <span>
                {`Logradouro *
                ${errors.logradouro ? `(${errors.logradouro})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, false);
                }}
                value={entity.logradouro}
                autoComplete="off"
                id="logradouro"
                error={!!errors.logradouro}
                placeholder="Digite o logradouro"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="20%">
              <span>
                {`Número *
                ${errors.numero ? `(${errors.numero})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, false);
                }}
                value={entity.numero}
                autoComplete="off"
                id="numero"
                error={!!errors.numero}
                placeholder="Digite o número"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`Bairro *
                ${errors.bairro ? `(${errors.bairro})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, false);
                }}
                value={entity.bairro}
                autoComplete="off"
                id="bairro"
                error={!!errors.bairro}
                placeholder="Digite o bairro"
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Complemento
                ${errors.complemento ? `(${errors.complemento})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, false);
                }}
                value={entity.complemento}
                autoComplete="off"
                id="complemento"
                error={!!errors.complemento}
                placeholder="Digite o complemento"
              />
            </FormField>
            <FormField size="40%">
              <span>
                {`Cidade *
                ${errors.cidade ? `(${errors.cidade})` : ''}
                `}
              </span>
              <Select
                name="cidade"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha a cidade"
                onBlur={(e: any) => handleValidation('cidade')}
                onChange={(e: any) => {
                  handleChanges('cidade', e.value, false);
                }}
                error={!!errors.cidade}
                defaultValue={entity.cidade}
                options={selectCidades}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>CONTATOS</FormTitle>
          <FormLine>
            <FormField size="20%">
              <span>
                {`Telefone
                ${errors.telefone ? `(${errors.telefone})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, false, 'fone');
                }}
                value={entity.telefone}
                autoComplete="off"
                id="telefone1"
                maxLength={15}
                error={!!errors.telefone}
                placeholder="Digite o telefone"
              />
            </FormField>
            <FormField size="80%">
              <span>
                {`Email
                ${errors.email ? `(${errors.email})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, false);
                }}
                value={entity.email}
                autoComplete="off"
                id="email"
                error={!!errors.email1}
                placeholder="Digite o e-mail"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTable height="330px">
            <div>
              <h1>LISTA DE BOLETOS</h1>
              <div>
                <button
                  disabled={idCompanyState === undefined}
                  onClick={handleInvoiceSettingInsert}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="90%">Descrição</TableHeaderColumn>
            </TableHeader>
            <Table height="230px">
              <table>
                <tbody>
                  {'id' in entityInvoiceSettings[0] &&
                    entityInvoiceSettings.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="90%">{e.descricao}</TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleInvoiceSettingEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleInvoiceSettingDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>
        <FormBlock>
          <FormTitle>PARÂMETROS DA NFS-e</FormTitle>
          <FormLine>
            <FormField size="20%">
              <span>
                {`Sit. Tributária
                ${errorsNFSe.situacaoTributaria
                    ? `(${errorsNFSe.situacaoTributaria})`
                    : ''
                  }
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id, false, true)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, true);
                }}
                value={entityNFSe.situacaoTributaria}
                autoComplete="off"
                id="situacaoTributaria"
                error={!!errorsNFSe.situacaoTributaria}
                placeholder="Digite a situação tributária"
              />
            </FormField>
            <FormField size="20%">
              <span>
                {`Item da Lista
                ${errorsNFSe.itemLista ? `(${errorsNFSe.itemLista})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id, false, true)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, true);
                }}
                value={entityNFSe.itemLista}
                autoComplete="off"
                id="itemLista"
                error={!!errorsNFSe.itemLista}
                placeholder="Digite o ítem da lista"
              />
            </FormField>
            <FormField size="20%">
              <span>
                {`Alíquota
                ${errorsNFSe.aliquota ? `(${errorsNFSe.aliquota})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id, false, true)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, true, 'money');
                }}
                value={entityNFSe.aliquota}
                autoComplete="off"
                id="aliquota"
                error={!!errorsNFSe.aliquota}
                placeholder="Digite a alíquota"
              />
            </FormField>
            <FormField size="20%">
              <span>
                {`Login
                ${errorsNFSe.login ? `(${errorsNFSe.login})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id, false, true)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, true);
                }}
                value={entityNFSe.login}
                autoComplete="off"
                id="login"
                error={!!errorsNFSe.login}
                placeholder="Digite o login"
              />
            </FormField>
            <FormField size="20%">
              <span>
                {`Senha
                ${errorsNFSe.senha ? `(${errorsNFSe.senha})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id, false, true)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, true);
                }}
                value={entityNFSe.senha}
                autoComplete="off"
                id="senha"
                error={!!errorsNFSe.senha}
                placeholder="Digite a senha"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default CompanyRegister;
