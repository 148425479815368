import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FiPrinter, FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../../services/api';

import { report } from '../../../../utils/printReport';

import { getIndividualErrors, getErrors } from '../../../../utils/validateForm';
import { changeForm } from '../../../../utils/handleForms';
import { manipulateDates } from '../../../../utils/handleLists';
import { changeNamesOfColumns } from '../../../../utils/handleSelects';

import Confirmation from '../../../../components/Confirmation';
import Select from '../../../../components/Select';
import DatePicker from '../../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormButtons,
  Button,
} from '../../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  dataInicial: Date | null;
  dataFinal: Date | null;
  colaborador: string;
}

interface ErrorsData {
  [key: string]: string;
}

const ComissionsReport: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [selectCollaborators, setSelectCollaborators] = useState([]);

  const [entity, setEntity] = useState<EntityData>({
    dataInicial: null,
    dataFinal: null,
    colaborador: '',
  });

  const [entityValidation] = useState({
    dataInicial: Yup.date()
      .required('Preenchimento obrigatório')
      .label('Data inicial')
      .nullable(),
    dataFinal: Yup.date()
      .required('Preenchimento obrigatório')
      .label('Data final')
      .nullable(),
    colaborador: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Colaborador'),
  });

  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/collaborators', {
          params: {
            status: 'ativo',
          },
        });
        setSelectCollaborators(
          changeNamesOfColumns(response.data, 'nome', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }
    }
    loadSelects();
  }, []);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    const newValue = value;
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handlePrint() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        // const entitySave = saveForm(entity, []);
        // console.log(entitySave);

        const response = await api.get('/receipts/comissions', {
          params: {
            dataInicial: entity.dataInicial,
            dataFinal: entity.dataFinal,
            colaborador: entity.colaborador,
          },
        });

        const header =
          '<th>COD.</th><th>CLIENTE</th><th>VCTO</th><th>VALOR</th><th>PERC.</th><th>COMISS.</th>';
        let content = '';
        let total = 0;
        response.data.map((r: any) => {
          content =
            `${content}<tr key=${r.id}> ` +
            `<td>${r.codigo}</td>  ` +
            `<td>${r.fantasia}</td>  ` +
            `<td>${manipulateDates(`${r.datavencimento}`)}</td>  ` +
            `<td>${r.valor.toFixed(2).replace('.', ',')}</td>  ` +
            `<td>${r.percentual.toFixed(2).replace('.', ',')} %</td>  ` +
            `<td><b>${r.valorcomissao
              .toFixed(2)
              .replace('.', ',')}</b></td>  ` +
            `</tr>`;
          total += r.valorcomissao
            ? parseFloat(String(r.valorcomissao).replace(',', '.'))
            : 0;
          return true;
        });

        content =
          `${content}<tr> ` +
          `<td></td>  ` +
          `<td></td>  ` +
          `<td></td>  ` +
          `<td></td>  ` +
          `<td><b>TOTAL</b></td>  ` +
          `<td><b>${String(total.toFixed(2)).replace('.', ',')}</b></td>  ` +
          `</tr>`;

        report(`RELAÇÃO DE COMISSÕES`, header, content);
      } catch (err) {
        addToast('Não foram encontrados registros para o filtro informado', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Relatório de comissões de colaboradores</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormLine isJustifyLeft>
            <FormField size="20%">
              <span>
                {`
                Data inicial *
                ${errors.dataInicial ? `(${errors.dataInicial})` : ''}
                `}
              </span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataInicial')}
                error={!!errors.dataInicial}
                onChange={(e: any) => {
                  handleChanges('dataInicial', e, 'date');
                }}
                placeholderText="Defina a data inicial"
                selected={entity.dataInicial}
              />
            </FormField>
            <FormField size="20%">
              <span>
                {`
                Data Final *
                ${errors.dataFinal ? `(${errors.dataFinal})` : ''}
                `}
              </span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataFinal')}
                error={!!errors.dataFinal}
                onChange={(e: any) => {
                  handleChanges('dataFinal', e, 'date');
                }}
                placeholderText="Defina a data final"
                selected={entity.dataFinal}
              />
            </FormField>
            <FormField size="60%">
              <span>
                {`
                Colaborador *
                ${errors.colaborador ? `(${errors.colaborador})` : ''}
                `}
              </span>
              <Select
                name="colaborador"
                height={40}
                maxMenuHeight={80}
                placeholder="Escolha o colaborador"
                onBlur={(e: any) => handleValidation('colaborador')}
                onChange={(e: any) => {
                  handleChanges('colaborador', e.value);
                }}
                error={!!errors.colaborador}
                defaultValue={entity.colaborador}
                options={selectCollaborators}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handlePrint} type="button">
            <FiPrinter size={20} color="#faede8" />
            Imprimir
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ComissionsReport;
