import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle, FiEdit, FiTrash } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';

import api from '../../../../services/api';

import { getIndividualErrors, getErrors } from '../../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../../utils/handleSelects';

import Confirmation from '../../../../components/Confirmation';
import Select from '../../../../components/Select';
import DatePicker from '../../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormTable,
  FormTableLegend,
  FormTitle,
  FormButtons,
  Button,
  Table,
  TableTd,
  TableHeader,
  TableHeaderColumn,
} from '../../../../styles/registers';

interface EntityData {
  cliente?: string;
  produto?: string;
  dataInativo?: Date | null;
}

interface EntityProductModuleData {
  id?: string;
  clienteProduto?: string;
  produtoModulo?: string;
  dataInativo?: Date | null;
}

interface EntityProductVersionData {
  id?: string;
  clienteProduto?: string;
  produtoVersao?: string;
}

interface ErrorsData {
  [key: string]: string;
}

const ClientProductRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idClient } = useParams<{ idClient: string }>();
  const { idClientProduct } = useParams<{ idClientProduct: string }>();
  const { addToast } = useToasts();
  const [idClientProductState, setIdClientProductState] = useState(
    idClientProduct,
  );

  const [entity, setEntity] = useState<EntityData>({
    cliente: idClient,
    produto: '',
    dataInativo: null,
  });

  const [entityProductModules, setEntityProductModules] = useState<
    EntityProductModuleData[]
  >([{}]);

  const [entityProductVersions, setEntityProductVersions] = useState<
    EntityProductVersionData[]
  >([{}]);

  const [selectProducts, setSelectProducts] = useState([]);

  const [entityValidation] = useState({
    cliente: Yup.string().required('Preenchimento obrigatório'),
    produto: Yup.string().required('Preenchimento obrigatório'),
    dataInativo: Yup.date().nullable(),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  const loadProductModuleEntity = useCallback(async () => {
    if (!idClientProductState) return;

    try {
      const response = await api.get('/clientProductModules', {
        params: {
          clienteProduto: idClientProductState,
        },
      });
      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          clienteProduto: item.clienteProduto,
          produtoModulo: item.produtoModulos.descricao,
          dataInativo: item.dataInativo,
        };
      });
      setEntityProductModules(responseDisassembled);
    } catch (error) {
    }
  }, [idClientProductState]);

  const loadProductVersionEntity = useCallback(async () => {
    if (!idClientProductState) return;

    try {
      const response = await api.get('/clientProductVersions', {
        params: {
          clienteProduto: idClientProductState,
          lastCreated: true,
        },
      });
      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          clienteProduto: item.clienteProduto,
          produtoVersao: item.produtoVersoes.versao,
        };
      });
      setEntityProductVersions(responseDisassembled);
    } catch (error) {
    }

  }, [idClientProductState]);

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/products');
        setSelectProducts(
          changeNamesOfColumns(response.data, 'descricao', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idClientProduct !== undefined) {
        const response = await api.get(`/clientProducts/${idClientProduct}`);
        const entityManipulated: any = loadForm(
          response.data,
          [],
          ['dataInativo'],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();

    loadProductModuleEntity();
    loadProductVersionEntity();
  }, [idClientProduct, loadProductModuleEntity, loadProductVersionEntity]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<boolean> {
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return validate;
  }

  function handleChanges(id: string, value: any, type?: string) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation) {
      try {
        const entitySave = saveForm(entity, []);
        if (idClientProduct === undefined) {
          const response = await api.post('/clientProducts', entitySave);
          setIdClientProductState(response.data.id);
          navigate(
            `/clients/register/${idClient}/product/${response.data.id}`,
            { replace: true }
          );
          addToast(
            'Produto do cliente inserido com sucesso, clique novamente para fechar ou insira registros secundários',
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );
        } else {
          await api.put(`/clientProducts/${idClientProduct}`, entitySave);
          navigate(-1);
          addToast('Produto do cliente editado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      } catch (err) {
        addToast('Problemas ao gravar o produto do cliente, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast('Registros obrigatórios incompletos, verifique', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  function handleProductModuleDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/clientProductModules/${id}`);
          setEntityProductModules([{}]);
          loadProductModuleEntity();
          addToast('Módulo deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o módulo, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o módulo ?',
      'delete',
    );
  }

  function handleProductModuleEdit(id?: string) {
    navigate(
      `/clients/register/${idClient}/product/${idClientProductState}/module/${id}`,
    );
  }

  function handleProductModuleInsert() {
    navigate(
      `/clients/register/${idClient}/product/${idClientProductState}/module`,
    );
  }

  function handleProductVersionDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/clientProductVersions/${id}`);
          setEntityProductVersions([{}]);
          loadProductVersionEntity();
          addToast('Versão deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar a versão, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o módulo ?',
      'delete',
    );
  }

  function handleProductVersionEdit(id?: string) {
    navigate(
      `/clients/register/${idClient}/product/${idClientProductState}/version/${id}`,
    );
  }

  function handleProductVersionInsert() {
    navigate(
      `/clients/register/${idClient}/product/${idClientProductState}/version`,
    );
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Produtos do Cliente</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="80%">
              <span>
                {`Produto *
                ${errors.produto ? `(${errors.produto})` : ''}
                `}
              </span>
              <Select
                name="produto"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o produto"
                onBlur={(e: any) => handleValidation('produto')}
                onChange={(e: any) => {
                  handleChanges('produto', e.value);
                }}
                error={!!errors.produto}
                defaultValue={entity.produto}
                options={selectProducts}
              />
            </FormField>
            <FormField size="20%">
              <span>Data de inativação</span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataInativo')}
                error={!!errors.dataInativo}
                onChange={(e: any) => {
                  handleChanges('dataInativo', e, 'date');
                }}
                placeholderText="Defina a data de inativação"
                selected={entity.dataInativo}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTable height="280px">
            <div>
              <h1>LISTA DE MÓDULOS</h1>
              <div>
                <FormTableLegend color="#a53234">Inativo</FormTableLegend>
                <button
                  disabled={idClientProductState === undefined}
                  onClick={handleProductModuleInsert}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="90%">Nome</TableHeaderColumn>
            </TableHeader>
            <Table height="180px">
              <table>
                <tbody>
                  {'id' in entityProductModules[0] &&
                    entityProductModules.map((e, index) => (
                      <tr key={index}>
                        <TableTd
                          color={e.dataInativo !== null ? '#a53234' : '#888888'}
                          width="90%"
                        >
                          {e.produtoModulo}
                        </TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleProductModuleEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleProductModuleDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>
        <FormBlock>
          <FormTable height="280px">
            <div>
              <h1>LISTA DE VERSÕES</h1>
              <div>
                <button
                  disabled={idClientProductState === undefined}
                  onClick={handleProductVersionInsert}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="90%">Nome</TableHeaderColumn>
            </TableHeader>
            <Table height="180px">
              <table>
                <tbody>
                  {'id' in entityProductVersions[0] &&
                    entityProductVersions.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="90%">{e.produtoVersao}</TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleProductVersionEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleProductVersionDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ClientProductRegister;
