import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import {
  FaPlus,
  FaClipboardList,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { FiEdit, FiTrash, FiPrinter, FiZapOff } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import Confirmation from '../../components/Confirmation';

import api from '../../services/api';

import { report } from '../../utils/printReport';
import {
  orderBy as handleOrderBy,
  manipulateDates,
  manipulateFloats,
} from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersLine,
  FiltersField,
  FiltersFieldBetween,
  Tables,
  TableTd,
  TablesPagination,
} from '../../styles/lists';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id?: string;
  cliente?: string;
  dataEmissao?: Date;
  valor?: number;
  nfseNumero?: string;
  nfseStatus?: string;
  created_at?: string;
  nfseDanfse?: string;
}

const FiscalDocument: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'created_at',
    desc: true,
  });
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [selectTipoDatas] = useState([
    {
      label: 'Digitação',
      value: 'created_at',
    },
    {
      label: 'Emissão',
      value: 'dataEmissao',
    },
    {
      label: 'Cancelamento',
      value: 'nfseCancelamento',
    },
  ]);

  const filters: any = getFilters('fiscalDocuments');

  const [numeroNFSe, setNumeroNFSe] = useState(
    Object.keys(filters).length > 0 ? filters.numeroNFSe : '',
  );
  const [cliente, setCliente] = useState(
    Object.keys(filters).length > 0 ? filters.cliente : '',
  );
  const [tipoData, setTipoData] = useState(
    Object.keys(filters).length > 0 ? filters.tipoData : 'created_at',
  );
  const [dataInicial, setDataIncial] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataInicial !== null
      ? parseISO(filters.dataInicial)
      : null,
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataFinal !== null
      ? parseISO(filters.dataFinal)
      : null,
  );

  function saveFilters() {
    setFilters(
      {
        numeroNFSe,
        cliente,
        tipoData,
        dataInicial,
        dataFinal,
      },
      'fiscalDocuments',
    );
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      try {
        const response = await api.get('/fiscalDocuments', {
          params: {
            clienteNome: cliente,
            numero: numeroNFSe,
            tipoData,
            dataInicial: dataInicial && format(dataInicial, 'yyyy-MM-dd'),
            dataFinal: dataFinal && format(dataFinal, 'yyyy-MM-dd'),
          },
        });
        const responseDisassembled = await response.data.map((item: any) => {
          return {
            id: item.id,
            cliente: item.clientes.fantasia,
            dataEmissao: item.dataEmissao,
            created_at: item.created_at,
            valor: manipulateFloats(item.valor),
            nfseNumero: item.nfseNumero,
            nfseDanfse: item.nfseDanfse,
            nfseStatus:
              // eslint-disable-next-line no-nested-ternary
              item.nfseNumero === null
                ? 'EM DIGITAÇÃO'
                : item.nfseCancelamento === null
                  ? 'AUTORIZADA'
                  : 'CANCELADA',
          };
        });
        const responseOrdered: any = await handleOrderBy(
          responseDisassembled,
          order,
        );
        setEntity(responseOrdered);
        setPages(Math.ceil(response.data.length / 20));
        setActualPage(Math.ceil(response.data.length / 20) > 0 ? 1 : 0);
      } catch (err) {
        setEntity([{}]);
        setPages(1);
        setActualPage(1);
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [cliente, numeroNFSe, tipoData, dataInicial, dataFinal, order]);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#a53234' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 20;
    return actualPage * 20;
  }

  function handlePrint() {
    const header =
      '<th>CLIENTE</th><th>DATA</th><th>NÚMERO NFSE</th><th>VALOR</th>';
    let content = '';
    let total = 0;
    entity.map(fiscalDocuments => {
      content =
        `${content}<tr key=${fiscalDocuments.id}> ` +
        `<td>${fiscalDocuments.cliente}</td>  ` +
        `<td>${fiscalDocuments.created_at &&
        manipulateDates(`${fiscalDocuments.created_at}`)
        }</td>  ` +
        `<td>${fiscalDocuments.nfseNumero ? fiscalDocuments.nfseNumero : ''
        }</td>  ` +
        `<td>${fiscalDocuments.valor}</td>  ` +
        `</tr>`;
      total += fiscalDocuments.valor
        ? parseFloat(String(fiscalDocuments.valor).replace(',', '.'))
        : 0;
      return true;
    });
    content =
      `${content}<tr> ` +
      `<td></td>  ` +
      `<td></td>  ` +
      `<td><b>TOTAL</b></td>  ` +
      `<td>${String(total.toFixed(2)).replace('.', ',')}</td>  ` +
      `</tr>`;

    report('RELAÇÃO DE NOTAS FISCAIS', header, content);
  }

  function handleDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/fiscalDocuments/${id}`);

          setNumeroNFSe('1');
          setNumeroNFSe('');

          addToast('Nota deletada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar a nota, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir a nota ?',
      'delete',
    );
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/fiscalDocuments/register/${id}`);
  }

  function handleInsert() {
    saveFilters();
    navigate('/fiscalDocuments/register');
  }

  async function handleSelected(id?: string, checkStatus?: boolean) {
    let selectedList = selectedRows;
    if (id === '') {
      selectedList = [];
      entity
        .slice(handlePagination(1), handlePagination(2))
        .map(function loop(e) {
          selectedList.push(e.id || '');
          const element: any = document.getElementById(e.id || '');
          if (element !== null) element.checked = checkStatus;
          return true;
        });
      if (checkStatus === false) {
        selectedList = [];
      }
    } else if (checkStatus) {
      selectedList.push(id || '');
    } else {
      const index = selectedList.indexOf(id || '');
      selectedList.splice(index, 1);
    }
    setSelectedRows(selectedList);
  }

  async function handleEmitNFSe() {
    if (selectedRows.length === 0) {
      addToast('Selecione pelo menos uma nota para gerar', {
        appearance: 'error',
        autoDismiss: true,
      });
      return false;
    }

    Confirmation(
      async () => {
        Promise.all(
          selectedRows.map((item, index) => {
            // eslint-disable-next-line no-new
            return new Promise(() =>
              setTimeout(async () => {
                const response = await api.post(
                  '/fiscalDocuments/electronicService',
                  {
                    id: item,
                  },
                );
                if (response.data === 'Nota fiscal emitida com sucesso') {
                  addToast(`Nota ${index + 1} emitida com sucesso`, {
                    appearance: 'success',
                    autoDismiss: true,
                  });
                } else {
                  addToast(
                    `Erro ao gerar a nota ${index + 1}. ${response.data}`,
                    {
                      appearance: 'error',
                      autoDismiss: true,
                    },
                  );
                }

                setNumeroNFSe('1');
                setNumeroNFSe('');
              }, index * 2000),
            );
          }),
        );

        // Clear all the checkboxs selected
        handleSelected('', false);
        const element: any = document.getElementById('checkboxGlobal');
        if (element !== null) element.checked = false;
        setSelectedRows([]);
      },
      `Tem certeza que deseja emitir a(s) ${selectedRows.length} nota(s) selecionada(s) ?`,
      'abort',
    );
    return true;
  }

  function handleCancelNFSe(id?: string) {
    Confirmation(
      async () => {
        const result = await api.put(
          `/fiscalDocuments/electronicService/${id}`,
        );

        setNumeroNFSe('1');
        setNumeroNFSe('');

        if (result.data === 'Nota fiscal cancelada com sucesso') {
          addToast('Nota cancelada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          addToast('Não foi possível cancelar a nota, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja cancelar a nota ?',
      'delete',
    );
  }

  function handlePrintNFSe(url?: string) {
    window.open(url, '_blank');
  }

  return (
    <Container>
      <Title>
        <h1>Listagem de Notas fiscais</h1>
      </Title>
      <Buttons>
        <Button width={240} onClick={handleInsert}>
          <FaPlus size={18} color="#faede8" />
          Inc. registros mensais
        </Button>
        <Button visual="secondary" onClick={handlePrint}>
          <FaClipboardList size={18} color="#a53234" />
          Imprime rel.
        </Button>
        <Button visual="secondary" onClick={handleEmitNFSe}>
          <FaClipboardList size={18} color="#a53234" />
          Gerar NFS-e
        </Button>
      </Buttons>
      <Filters>
        <FiltersLine>
          <FiltersFieldBetween size="40%">
            <Select
              name="tipo"
              height={40}
              maxMenuHeight={300}
              placeholder="Tipo"
              onChange={(e: any) => {
                // eslint-disable-next-line no-unused-expressions
                if (e !== null) {
                  setTipoData(e.value);
                }
              }}
              defaultValue={tipoData}
              options={selectTipoDatas}
            />
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataIncial(e);
                }}
                placeholderText="Data inicial"
                selected={dataInicial}
              />
            </div>
            <h3>a</h3>
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataFinal(e);
                }}
                placeholderText="Data final"
                selected={dataFinal}
              />
            </div>
          </FiltersFieldBetween>
          <FiltersField size="40%">
            <input
              value={cliente}
              onChange={e => setCliente((e.target.value as any) || '')}
              id="cliente"
              placeholder="Filtre pelo cliente"
            />
          </FiltersField>
          <FiltersField size="20%">
            <input
              value={numeroNFSe}
              onChange={e => setNumeroNFSe((e.target.value as any) || '')}
              id="numeroNFSe"
              placeholder="Filtre pelo número"
            />
          </FiltersField>
        </FiltersLine>
      </Filters>
      <Tables>
        <table>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  id="checkboxGlobal"
                  onChange={checked => {
                    handleSelected('', checked.target.checked);
                  }}
                />
              </th>
              <th>
                <div>
                  Cliente
                  <button
                    onClick={() => {
                      orderBy('cliente');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('cliente', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('cliente', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Data
                  <button
                    onClick={() => {
                      orderBy('dataEmissao');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('dataEmissao', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('dataEmissao', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Valor
                  <button
                    onClick={() => {
                      orderBy('valor');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('valor', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('valor', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Número
                  <button
                    onClick={() => {
                      orderBy('nfseNumero');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('nfseNumero', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('nfseNumero', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Status
                  <button
                    onClick={() => {
                      orderBy('nfseStatus');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('nfseStatus', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('nfseStatus', true)}
                    />
                  </button>
                </div>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {'id' in entity[0] &&
              entity
                .slice(handlePagination(1), handlePagination(2))
                .map((e, index) => (
                  <tr key={index}>
                    <TableTd center width="8%">
                      <input
                        id={e.id}
                        type="checkbox"
                        onChange={checked => {
                          handleSelected(e.id, checked.target.checked);
                        }}
                      />
                    </TableTd>
                    <TableTd width="25%">{e.cliente}</TableTd>
                    <TableTd width="15%">
                      {e.created_at && manipulateDates(`${e.created_at}`)}
                    </TableTd>
                    <TableTd width="15%">{e.valor}</TableTd>
                    <TableTd width="12%">{e.nfseNumero}</TableTd>
                    <TableTd
                      color={
                        // eslint-disable-next-line no-nested-ternary
                        e.nfseStatus === 'AUTORIZADA'
                          ? '#12522D'
                          : e.nfseStatus === 'CANCELADA'
                            ? '#a53234'
                            : ''
                      }
                      width="12%"
                    >
                      {e.nfseStatus}
                    </TableTd>
                    <TableTd center width="13%">
                      <button
                        disabled={e.nfseStatus !== 'EM DIGITAÇÃO'}
                        title="Alterar registro"
                        type="button"
                        onClick={() => handleEdit(e.id)}
                      >
                        <FiEdit
                          opacity={e.nfseStatus !== 'EM DIGITAÇÃO' ? 0.2 : 1}
                          size={20}
                          color="#Fafafa"
                        />
                      </button>
                      <button
                        title="Deletar registro"
                        type="button"
                        disabled={e.nfseStatus !== 'EM DIGITAÇÃO'}
                        onClick={() => handleDelete(e.id)}
                      >
                        <FiTrash
                          opacity={e.nfseStatus !== 'EM DIGITAÇÃO' ? 0.2 : 1}
                          size={20}
                          color="#d13337"
                        />
                      </button>
                      <button
                        disabled={e.nfseStatus !== 'AUTORIZADA'}
                        title="Imprimir NFS-e"
                        type="button"
                        onClick={() => handlePrintNFSe(e.nfseDanfse)}
                      >
                        <FiPrinter
                          opacity={e.nfseStatus !== 'AUTORIZADA' ? 0.2 : 1}
                          size={20}
                          color="#668B8B"
                        />
                      </button>
                      <button
                        disabled={e.nfseStatus !== 'AUTORIZADA'}
                        title="Cancelar NFS-e"
                        type="button"
                        onClick={() => handleCancelNFSe(e.id)}
                      >
                        <FiZapOff
                          opacity={e.nfseStatus !== 'AUTORIZADA' ? 0.2 : 1}
                          size={20}
                          color="#d13337"
                        />
                      </button>
                    </TableTd>
                  </tr>
                ))}
          </tbody>
        </table>
        <TablesPagination>
          <span>
            {`${'id' in entity[0] ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                if (actualPage > 1) {
                  setActualPage(actualPage - 1);
                  handleSelected('', false);
                  const element: any = document.getElementById(
                    'checkboxGlobal',
                  );
                  if (element !== null) element.checked = false;
                  setSelectedRows([]);
                }
              }}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#a53234'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                if (actualPage !== pages) {
                  setActualPage(actualPage + 1);
                  handleSelected('', false);
                  const element: any = document.getElementById(
                    'checkboxGlobal',
                  );
                  if (element !== null) element.checked = false;
                  setSelectedRows([]);
                }
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#a53234'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default FiscalDocument;
