import styled from 'styled-components';

interface MenuProps {
  expanded: boolean;
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: none;
`;

export const Menu = styled.div<MenuProps>`
  width: ${props => (props.expanded ? `330px` : `50px`)};
  height: 100%;
  border-right: 1px solid #232129;
  display: flex;
  flex-direction: column;
  box-shadow: 6px 3px 40px 15px rgba(0, 0, 0, 0.2);
  transition: width 0.3s;
`;

export const MenuLogo = styled.div`
  z-index: 99;
  width: 90%;
  height: 10%;
  min-height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 160px;
  }
  border-bottom: 1px solid #232129;
  margin: 0 5%;
`;

export const MenuItems = styled.div`
  width: 100%;
  height: 68%;
  min-height: 68%;
  padding: 25px 0;
`;

export const MenuProfile = styled.div`
  width: 90%;
  height: 17%;
  min-height: 17%;
  padding: 20px 10px;
  border-top: 1px solid #232129;
  margin: 0 5%;
  span {
    color: #faede8;
    font-size: 14px;
  }
  h1 {
    margin-top: 5px;
    color: #faede8;
    font-size: 18px;
  }
  button {
    margin-top: 10px;
    background: transparent;
    border: 0;
    border-radius: 5px;
    &:hover {
      background: #232129;
    }
  }
`;

export const MenuExpanded = styled.button`
  width: 100%;
  height: 5%;
  background-color: transparent;
  border: 0;
  &:hover {
    background: #232129;
  }
  color: #faede8;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;

export const MenuHidden = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  button {
    width: 100%;
    height: 5%;
    background-color: transparent;
    border: 0;
    &:hover {
      background: #232129;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const Content = styled.div`
  flex: 1;
`;
