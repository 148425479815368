import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO, isBefore } from 'date-fns';

import {
  FaPlus,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaClipboardList,
} from 'react-icons/fa';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import Select from '../../components/Select';
import Confirmation from '../../components/Confirmation';
import DatePicker from '../../components/DatePicker';

import api from '../../services/api';

import { report } from '../../utils/printReport';
import {
  manipulateDates,
  orderBy as handleOrderBy,
} from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersLine,
  FiltersField,
  FiltersFieldBetween,
  Legends,
  Legend,
  Tables,
  TableTd,
  TablesPagination,
} from '../../styles/lists';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id?: string;
  created_at?: string;
  empresa?: string;
  solicitacao?: string;
  dataAviso?: Date;
  dataCancelamento?: Date;
  dataFechamento?: Date;
}

const Lead: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'created_at',
    desc: true,
  });

  const filters: any = getFilters('leads');

  const [empresa, setEmpresa] = useState(
    Object.keys(filters).length > 0 ? filters.empresa : '',
  );
  const [solicitacao, setSolicitacao] = useState(
    Object.keys(filters).length > 0 ? filters.solicitacao : '',
  );
  const [dataInicial, setDataIncial] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataInicial !== null
      ? parseISO(filters.dataInicial)
      : null,
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataFinal !== null
      ? parseISO(filters.dataFinal)
      : null,
  );
  const [status, setStatus] = useState(
    Object.keys(filters).length > 0 ? filters.status : 'EM NEGOCIAÇÃO',
  );

  function saveFilters() {
    setFilters(
      {
        empresa,
        solicitacao,
        dataInicial,
        dataFinal,
        status,
      },
      'leads',
    );
  }

  const [selectStatus] = useState([
    { label: 'EM NEGOCIAÇÃO', value: 'EM NEGOCIAÇÃO' },
    { label: 'FECHADOS', value: 'FECHADO' },
    { label: 'CANCELADOS', value: 'CANCELADO' },
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      try {
        const response = await api.get('/leads', {
          params: {
            empresa,
            solicitacao,
            dataInicial: dataInicial && format(dataInicial, 'yyyy-MM-dd'),
            dataFinal: dataFinal && format(dataFinal, 'yyyy-MM-dd'),
            status,
          },
        });

        const responseDisassembled = await response.data.map((item: any) => {
          return {
            id: item.id,
            created_at: item.created_at,
            empresa: item.empresa,
            solicitacao: item.solicitacao,
            dataAviso: item.dataAviso,
            dataCancelamento: item.dataCancelamento,
            dataFechamento: item.dataFechamento,
          };
        });
        const responseOrdered: any = await handleOrderBy(
          responseDisassembled,
          order,
        );
        setEntity(responseOrdered);
        setPages(Math.ceil(response.data.length / 10));
        setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
      } catch (err) {
        setEntity([{}]);
        setPages(1);
        setActualPage(1);
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [empresa, solicitacao, dataInicial, dataFinal, status, order]);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#a53234' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  function handleDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/leads/${id}`);

          setSolicitacao('1');
          setSolicitacao('');

          addToast('Interesse deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o interesse, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o interesse ?',
      'delete',
    );
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/leads/register/${id}`);
  }

  function handleInsert() {
    saveFilters();
    navigate('/leads/register');
  }

  function handlePrint() {
    const header = '<th>DATA</th><th>EMPRESA</th><th>SOLICITAÇÃO</th>';
    let content = '';
    entity.map(
      // eslint-disable-next-line no-return-assign
      lead =>
      (content =
        `${content}<tr key=${lead.id}> ` +
        `<td>${manipulateDates(lead.created_at || '')}</td>  ` +
        `<td>${lead.empresa ? lead.empresa : ''}</td>  ` +
        `<td>${lead.solicitacao}</td>  ` +
        `</tr>`),
    );
    report('RELAÇÃO DE INTERESSES', header, content);
  }

  return (
    <Container>
      <Title>
        <h1>Listagem de Interesses</h1>
      </Title>
      <Buttons>
        <Button onClick={handleInsert}>
          <FaPlus size={18} color="#faede8" />
          Adicionar
        </Button>
        <Button visual="secondary" onClick={handlePrint}>
          <FaClipboardList size={18} color="#a53234" />
          Imprimir
        </Button>
      </Buttons>
      <Filters>
        <FiltersLine>
          <FiltersFieldBetween size="25%">
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataIncial(e);
                }}
                placeholderText="Data inicial"
                selected={dataInicial}
              />
            </div>
            <h3>a</h3>
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataFinal(e);
                }}
                placeholderText="Data final"
                selected={dataFinal}
              />
            </div>
          </FiltersFieldBetween>
          <FiltersField size="25%">
            <input
              value={empresa}
              onChange={e => setEmpresa((e.target.value as any) || '')}
              id="empresa"
              placeholder="Filtre pela empresa"
            />
          </FiltersField>
          <FiltersField size="25%">
            <input
              value={solicitacao}
              onChange={e => setSolicitacao((e.target.value as any) || '')}
              id="solicitacao"
              placeholder="Filtre pela solicitação da tarefa"
            />
          </FiltersField>
          <FiltersField size="25%">
            <Select
              name="status"
              height={40}
              maxMenuHeight={300}
              placeholder="Filtre pelo status"
              onChange={(e: any) => {
                setStatus(e.value);
              }}
              defaultValue={status}
              options={selectStatus}
            />
          </FiltersField>
        </FiltersLine>
      </Filters>
      <Legends>
        <Legend backgroundColor="#2e2c2c" fontColor="#d13337">
          Aviso
        </Legend>
      </Legends>
      <Tables withLegend>
        <table>
          <thead>
            <tr>
              <th>
                <div>
                  Data
                  <button
                    onClick={() => {
                      orderBy('created_at');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('created_at', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('created_at', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Empresa
                  <button
                    onClick={() => {
                      orderBy('empresa');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('empresa', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('empresa', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Solicitação
                  <button
                    onClick={() => {
                      orderBy('solicitacao');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('solicitacao', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('solicitacao', true)}
                    />
                  </button>
                </div>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {'id' in entity[0] &&
              entity
                .slice(handlePagination(1), handlePagination(2))
                .map((e, index) => (
                  <tr key={index}>
                    <TableTd
                      color={
                        e.dataCancelamento === null &&
                          e.dataFechamento === null &&
                          isBefore(
                            e.dataAviso === undefined
                              ? new Date()
                              : parseISO(`${e.dataAviso}`),
                            new Date(),
                          )
                          ? '#d13337'
                          : '#888888'
                      }
                      width="15%"
                    >
                      {e.created_at && manipulateDates(e.created_at)}
                    </TableTd>

                    <TableTd
                      color={
                        e.dataCancelamento === null &&
                          e.dataFechamento === null &&
                          isBefore(
                            e.dataAviso === undefined
                              ? new Date()
                              : parseISO(`${e.dataAviso}`),
                            new Date(),
                          )
                          ? '#d13337'
                          : '#888888'
                      }
                      width="35%"
                    >
                      {e.empresa}
                    </TableTd>
                    <TableTd
                      color={
                        e.dataCancelamento === null &&
                          e.dataFechamento === null &&
                          isBefore(
                            e.dataAviso === undefined
                              ? new Date()
                              : parseISO(`${e.dataAviso}`),
                            new Date(),
                          )
                          ? '#d13337'
                          : '#888888'
                      }
                      width="35%"
                    >
                      {e.solicitacao}
                    </TableTd>
                    <TableTd center width="15%">
                      <button type="button" onClick={() => handleEdit(e.id)}>
                        <FiEdit size={20} color="#Fafafa" />
                      </button>
                      <button type="button" onClick={() => handleDelete(e.id)}>
                        <FiTrash size={20} color="#d13337" />
                      </button>
                    </TableTd>
                  </tr>
                ))}
          </tbody>
        </table>
        <TablesPagination>
          <span>
            {`${'id' in entity[0] ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#a53234'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#a53234'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default Lead;
