import styled from 'styled-components';
import { shade } from 'polished';

interface ButtonProps {
  visual?: string;
  width?: number;
}

interface ButtonsProps {
  spaceBetween?: boolean;
}

interface FiltersFieldProps {
  size: string;
}

interface LegendProps {
  backgroundColor?: string;
  fontColor?: string;
}

interface TableProps {
  withLegend?: boolean;
}

interface TableTdProps {
  center?: boolean;
  color?: string;
}

interface TableTdCardProps {
  fontSize?: number;
}

interface ButtonMenuItensProps {
  isOpen?: boolean;
}

export const FormLoading = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #262424;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(35, 33, 41, 0.8);
  }
  scrollbar-width: thin;
  scrollbar-color: rgba(35, 33, 41, 0.8) transparent;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  padding: 20px;
  h1 {
    font-size: 18px;
    color: #faede8;
  }
`;

export const Buttons = styled.div<ButtonsProps>`
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
  justify-content: ${props =>
    props.spaceBetween ? 'space-between' : 'flex-start'};
  padding: 0 20px;
  margin-bottom: 7px;
`;

export const Button = styled.button<ButtonProps>`
  height: 40px;
  width: ${props => (props.width ? `${props.width}px` : '150px')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  color: ${props => (props.visual === 'secondary' ? '#a53234' : '#faede8')};
  background: ${props =>
    props.visual === 'secondary' ? '#faede8' : '#a53234'};
  & + button {
    margin-left: 10px;
  }
  transition: background-color 0.2s;
  &:hover {
    background: ${props =>
      props.visual === 'secondary'
        ? shade(0.2, '#faede8')
        : shade(0.2, '#a53234')};
  }
  svg {
    margin-right: 5px;
  }
`;

export const ButtonLink = styled.button`
  height: 40px;
  background: transparent;
  border: 0;
  color: #faede8;
  font-size: 13px;
  font-weight: bold;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.2s;
  &:hover {
    color: ${shade(0.2, '#faede8')};
  }
  svg {
    margin-right: 5px;
  }
`;

export const ButtonMenu = styled.div`
  height: 40px;
  width: 100px;
  background: blue;
`;

export const ButtonMenuItens = styled.div<ButtonMenuItensProps>`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  height: 100px;
  width: 100px;
  background: red;
  position: absolute;
  left: 661px;
  top: 100px;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 20px;
  background: #262424;
  border-radius: 10px;
  > button {
    width: 100px;
    height: 50px;
  }
`;

export const FiltersLine = styled.div`
  width: 100%;
  height: 80px;
  padding: 0 10px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FiltersField = styled.div<FiltersFieldProps>`
  width: ${props => props.size};
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  > div {
    width: 100%;
  }
  > input {
    width: 100%;
    height: 40px;
    background: #2e2c2c;
    border-radius: 8px;
    border: 0;
    color: #d1cdcb;
    font-weight: bold;
    font-size: 14px;
    padding-left: 8px;
    ::placeholder {
      color: #a39f9d;
      font-weight: bold;
      font-size: 14px;
    }
  }
`;

export const FiltersFieldSwitch = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #2e2c2c;
  border-radius: 8px;
  h3 {
    color: #a39f9d;
    width: 40%;
    font-size: 13px;
  }
`;

export const FiltersFieldBetween = styled.div<FiltersFieldProps>`
  width: ${props => props.size};
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 8px 0 8px;
  margin-right: 5px;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #2e2c2c;
  border-radius: 8px;
  > div {
    width: 100%;
  }
  h3 {
    color: #a39f9d;
    font-size: 13px;
    padding: 0 5px;
  }
`;

export const Legends = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 20px;
  background: #262424;
  border-radius: 10px 10px 0 0;
  min-height: 40px;
  padding: 0 10px;
`;

export const Legend = styled.span<LegendProps>`
  background: ${props => props.backgroundColor && props.backgroundColor};
  color: ${props => props.fontColor && props.fontColor};
  padding: 1px 5px;
  font-size: 13px;
  font-weight: bold;
  margin-left: 5px;
  border-radius: 5px;
  min-width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Tables = styled.div<TableProps>`
  background: #262424;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${props => `${props.withLegend ? '0' : '5px'} 20px 20px 20px`};
  border-radius: ${props => (props.withLegend ? '0 0 10px 10px' : '10px')};
  // height: 620px;
  // min-height: 620px;
  padding: 0 10px;
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      background: transparent;
      border-bottom: 1px solid #2e2c2c;
      color: #d1cdcb;
      tr {
        height: 50px;
        th {
          > div {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            button {
              background: transparent;
              margin-left: 10px;
              border: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    tbody {
      tr {
        height: 50px;
        &:hover {
          background: #2e2c2c;
          transition: background 0.4s;
        }
      }
    }
  }
`;

export const TableTd = styled.td<TableTdProps>`
  text-align: ${props => props.center && 'center'};
  border-bottom: 1px solid #2e2c2c;
  font-weight: bold;
  font-size: 14px;
  padding-right: 5px;
  color: ${props => (props.color ? props.color : '#888888')};
  > button {
    border: 0;
    background: transparent;
    width: 38px;
    height: 40px;
    &:disabled {
      cursor: not-allowed;
    }
  }
  > input {
    background: red;
  }
`;

export const TableTdCard = styled.div<TableTdCardProps>`
  background-color: #2e2c2c;
  height: 30px;
  width: 110px;
  margin: 5px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '12px')};
  svg {
    margin-right: 5px;
  }
`;

export const TablesPagination = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 5px 10px;
  span {
    color: #a39f9d;
    font-size: 14px;
  }
  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    strong {
      margin: 0 8px;
      font-size: 18px;
      color: #a39f9d;
    }
    button {
      border: 0;
      background: transparent;
    }
  }
`;
