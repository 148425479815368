import styled from 'styled-components';
import { shade } from 'polished';

interface ButtonProps {
  visual?: string;
  width?: string;
}
interface FormFieldProps {
  size: string;
}

interface FormLineProps {
  height?: string;
  isJustifyLeft?: boolean;
}

interface FormFieldInputProps {
  error?: boolean;
  height?: string;
}

interface TableProps {
  height?: string;
}

interface TableTdProps {
  center?: boolean;
  color?: string;
  paddingTopBottom?: boolean;
}

interface FormTableLegendProps {
  background?: string;
  color?: string;
}

interface TableHeaderColumnProps {
  width: string;
}

export const FormLoading = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(35, 33, 41, 0.8);
  }
  scrollbar-width: thin;
  scrollbar-color: rgba(35, 33, 41, 0.8) transparent;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  min-height: 50px;
  align-items: center;
  padding: 0 20px;
  h1 {
    font-size: 18px;
    color: #faede8;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

export const FormBlock = styled.div`
  width: 100%;
  background: #262424;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 10px 5px 20px 5px;
`;

export const FormTitle = styled.h1`
  padding: 15px 10px 5px 15px;
  width: 100%;
  color: #a53234;
  font-size: 14px;
`;

export const FormLine = styled.div<FormLineProps>`
  width: 100%;
  min-height: ${props => (props.height ? props.height : '75px')};
  padding: 0 10px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.isJustifyLeft ? 'flex-start' : 'center')};
`;

export const FormTable = styled.div<FormLineProps>`
  min-height: ${props => (props.height ? props.height : '70px')};
  max-height: ${props => (props.height ? props.height : '70px')};
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  border-radius: 8px;
  padding: 15px 15px;
  > div {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      color: #a53234;
      font-size: 14px;
    }
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      button {
        height: 30px;
        width: 120px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 0;
        font-size: 14px;
        border-radius: 5px;
        font-weight: bold;
        color: #faede8;
        background: #a53234;
        & + button {
          margin-left: 10px;
        }
        transition: background-color 0.2s;
        &:hover {
          background: ${shade(0.2, '#a53234')};
        }
        &:disabled {
          opacity: 0.1;
          cursor: not-allowed;
          &:hover {
            opacity: 0.1;
            background: #a53234;
          }
        }
        svg {
          margin-right: 5px;
        }
      }
    }
  }
`;

export const Table = styled.div<TableProps>`
  width: 100%;
  min-height: ${props => (props.height ? props.height : '100px')};
  overflow: auto;
  margin: 10px 0;

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(35, 33, 41, 0.8);
  }

  table {
    width: 100%;
    max-height: 50px;
    flex: 1;
    border-collapse: collapse;

    // position: sticky;
    // z-index: 100;
    // top: 0;

    thead {
      // position: sticky;
      // z-index: 2;
      // top: 0;

      background: transparent;
      border-bottom: 1px solid #2e2c2c;
      color: #d1cdcb;
      font-size: 12px;
      tr {
        height: 40px;
        width: 100%;
        min-width: 100%;
        th {
          //position: sticky;
          //top: 0;
          background: #262424;

          > div {
            width: 100%;
            display: flex;
            margin-bottom: 12px;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }
    tbody {
      tr {
        // position: sticky;
        // z-index: 100;
        // top: 0;

        height: 50px;
        &:hover {
          background: #2e2c2c;
          transition: background 0.4s;
        }
      }
    }
  }
`;

export const TableTd = styled.td<TableTdProps>`
  text-align: ${props => props.center && 'center'};
  border-bottom: 1px solid #2e2c2c;
  font-weight: bold;
  font-size: 14px;
  padding-right: 10px;
  padding-top: ${props => props.paddingTopBottom && '10px'};
  padding-bottom: ${props => props.paddingTopBottom && '10px'};
  color: ${props => (props.color ? props.color : '#888888')};
  button {
    border: 0;
    background: transparent;
    width: 38px;
    height: 40px;
  }
  > div {
    z-index: 1;
  }
`;

export const TableHeader = styled.div`
  width: calc(100% - 12px) !important;
  height: 50px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  padding: 10px 0 !important;
  border-bottom: 1px solid #2e2c2c !important;
`;

export const TableHeaderColumn = styled.div<TableHeaderColumnProps>`
  height: 100% !important;
  width: ${props => props.width && props.width} !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font-size: 12px !important;
  color: #d1cdcb !important;
  font-weight: bold !important;
`;

export const FormTableLegend = styled.h2<FormTableLegendProps>`
  color: ${props => props.color && props.color};
  background: ${props => props.background && props.background};
  font-size: 11px;
  margin-right: 16px;
`;

export const FormField = styled.div<FormFieldProps>`
  width: ${props => props.size};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 5px;
  p {
    color: #d1cdcb;
    margin-bottom: 7px;
    font-size: 14px;
    text-align: justify;
  }
  span {
    color: #d1cdcb;
    margin-bottom: 7px;
    font-size: 12px;
  }
  > div {
    width: 100%;
  }
`;

export const FormFieldWithSearch = styled.div<FormFieldProps>`
  width: ${props => props.size};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 5px;
  span {
    color: #d1cdcb;
    margin-bottom: 7px;
    font-size: 12px;
  }
  > div {
    > div {
      width: 100%;
    }
    width: 100%;
    display: flex;
    flex-direction: row;
    button {
      background-color: #a53234;
      width: 50px;
      margin-left: 5px;
      border: 0;
      border-radius: 7px;
      transition: background-color 0.2s;
      &:hover {
        background: ${shade(0.2, '#a53234')};
      }
    }
  }
`;

export const FormFieldInput = styled.input<FormFieldInputProps>`
  width: 100%;
  height: 40px;
  background: #2e2c2c;
  // ${props => (props.disabled ? 'rgba(35, 33, 41, 0.8)' : '#2e2c2c')};
  border-radius: 8px;
  border: ${props => (props.error ? '2px solid #a53234' : '0')};
  color: #d1cdcb;
  font-weight: bold;
  font-size: 14px;
  padding-left: 8px;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #a39f9d;
    font-weight: bold;
    font-size: 14px;
  }
`;

export const FormFieldInfo = styled.input<FormFieldInputProps>`
  width: 100%;
  height: 40px;
  background: #2e2c2c;
  border-radius: 8px;
  border: ${props => (props.error ? '2px solid #a53234' : '0')};
  color: #d1cdcb;
  font-weight: bold;
  font-size: 14px;
  padding-left: 8px;

  &:disabled {
    opacity: 0.6;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #a39f9d;
    font-weight: bold;
    font-size: 14px;
  }
`;

export const FormFieldTextArea = styled.textarea<FormFieldInputProps>`
  width: 100%;
  height: ${props => (props.height ? props.height : '100px')};
  min-height: 100px;
  background: #2e2c2c;
  border-radius: 8px;
  border: ${props => (props.error ? '2px solid #a53234' : '0')};
  color: #d1cdcb;
  font-weight: bold;
  resize: vertical;
  font-size: 14px;
  padding: 8px 0 0 8px;
  ::placeholder {
    color: #a39f9d;
    font-weight: bold;
    font-size: 14px;
  }
`;

export const FormButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const Button = styled.button<ButtonProps>`
  height: 40px;
  width: ${props => props.width || '150px'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  color: ${props => (props.visual === 'secondary' ? '#a53234' : '#faede8')};
  background: ${props =>
    props.visual === 'secondary' ? '#faede8' : '#a53234'};
  & + button {
    margin-left: 10px;
  }
  transition: background-color 0.2s;
  &:hover {
    background: ${props =>
      props.visual === 'secondary'
        ? shade(0.2, '#faede8')
        : shade(0.2, '#a53234')};
  }
  svg {
    margin-right: 5px;
  }
`;
