import styled from 'styled-components';
import { shade } from 'polished';

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 12px;
  &:hover {
    .dropdown-content {
      display: block;
    }
    .dropdown-button {
      background-color: ${shade(0.2, '#faede8')};
    }
  }
`;

export const DropdownButton = styled.button`
  height: 40px;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 5px;
  background-color: #faede8;
  font-weight: bold;
  color: #a53234;
  span {
    margin-right: 5px;
  }
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 300px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  > button {
    width: 100%;
    height: 40px;
    color: #a53234;
    font-weight: bold;
    border: 0;
    border-radius: 5px;
    background-color: transparent;
    display: block;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    svg {
      margin-right: 5px;
    }
    &:hover {
      color: ${shade(0.2, '#a53234')};
    }
  }
`;
