import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';
import Switch from 'react-switch';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

import baseURLconfig from '../../../../services/baseURL';
import api from '../../../../services/api';

import { getIndividualErrors, getErrors } from '../../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../../utils/handleSelects';

import Select from '../../../../components/Select';
import Confirmation from '../../../../components/Confirmation';
import Editor from '../../../../components/Editor';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
} from '../../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  tarefa: string | undefined;
  titulo: string;
  colaborador: string;
  descricao: string;
  concluido: number;
}

interface EntityTestData {
  id?: string;
  tarefaTopico?: string;
  colaborador?: string;
  created_at?: Date;
}

interface ErrorsData {
  [key: string]: string;
}

const TaskTopicTestRegister: React.FC = () => {
  const history = useNavigate();
  const { idTask } = useParams<{ idTask: string }>();
  const { idTopic } = useParams<{ idTopic: string }>();
  const { addToast } = useToasts();

  const initialEditor = convertFromRaw(
    JSON.parse(
      '{"blocks":[{"key":"dm025","text":" ","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":5,"style":"color-rgb(255,255,255)"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
    ),
  );
  const emptyEditorState = EditorState.createWithContent(initialEditor);

  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorState, setEditorState] = useState(emptyEditorState);

  const [idTopicState, setIdTopicState] = useState(idTopic);
  const [entity, setEntity] = useState<EntityData>({
    tarefa: idTask,
    titulo: '',
    colaborador: '',
    descricao: '',
    concluido: 0,
  });

  const [entityTest, setEntityTest] = useState<EntityTestData[]>([{}]);

  const [selectCollaborators, setSelectCollaborators] = useState([]);

  const [entityValidation] = useState({
    titulo: Yup.string().required('Preenchimento obrigatório').label('Título'),
    colaborador: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Colaborador'),
    descricao: Yup.string().nullable().label('Descrição'),
    concluido: Yup.number(),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  const loadTestsEntity = useCallback(async () => {
    if (!idTopicState) return;

    try {
      const response = await api.get('/tasktopicTests', {
        params: {
          tarefatopico: idTopicState,
        },
      });
      const responseDisassembled = await response.data.map((item: any) => {
        return {
          id: item.id,
          tarefaTopico: item.tarefaTopico,
          colaborador: item.colaboradores.nome,
          created_at: item.created_at,
        };
      });
      setEntityTest(responseDisassembled);
    } catch (error) {
    }
  }, [idTopicState]);

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/collaborators', {
          params: {
            status: 'ativo'
          }
        });
        setSelectCollaborators(
          changeNamesOfColumns(response.data, 'nome', 'id'),
        );
      } catch (err) {
        //
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idTopicState !== undefined) {
        const response = await api.get(`/taskTopics/${idTopicState}`);
        const entityManipulated: any = loadForm(response.data, [], [], []);
        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;
        delete entityManipulated.descricao;
        setEntity(entityManipulated);

        if (
          response.data.descricao !== null &&
          response.data.descricao !== undefined
        ) {
          const contentState = convertFromRaw(
            JSON.parse(response.data.descricao),
          );
          const editorStateManipulated = EditorState.createWithContent(
            contentState,
          );
          setEditorState(editorStateManipulated);
        } else {
          setEditorState(EditorState.createEmpty());
        }
      }
    }
    loadEntity();
    loadTestsEntity();
  }, [idTopicState, loadTestsEntity]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        entitySave.descricao = JSON.stringify(
          convertToRaw(editorState.getCurrentContent()),
        );

        if (idTopicState === undefined) {
          await api.post('/taskTopics', entitySave);
          addToast('Tópico inserido com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          await api.put(`/taskTopics/${idTopicState}`, entitySave);
          addToast('Tópico editado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
        history(-1);
      } catch (err) {
        addToast('Problemas ao gravar o tópico, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  // function handleTestDelete(id?: string) {
  //   Confirmation(
  //     async () => {
  //       try {
  //         await api.delete(`/taskTopicTests/${id}`);
  //         setEntityTest([{}]);
  //         loadTestsEntity();
  //         addToast('Teste deletado com sucesso', {
  //           appearance: 'success',
  //           autoDismiss: true,
  //         });
  //       } catch (err) {
  //         addToast('Não foi possível deletar o teste, tente novamente', {
  //           appearance: 'error',
  //           autoDismiss: true,
  //         });
  //       }
  //     },
  //     'Tem certeza que deseja excluir o teste ?',
  //     'delete',
  //   );
  // }

  // function handleSecondaryEdit(page: string, id?: string) {
  //   history(
  //     `/tasks/register/${idTask}/topic/${idTopicState}/${page}/${id}`,
  //   );
  // }

  // function handleSecondaryInsert(page: string) {
  //   history(`/tasks/register/${idTask}/topic/${idTopicState}/${page}`);
  // }

  function handleEditor(content: any) {
    setEditorState(content);
  }

  async function handleEditorImage(file: any) {
    const data = new FormData();
    data.append('picture', file);
    const response = await api.post('tasksTopics/upload', data);
    const { filename } = response.data;
    return {
      data: {
        link: `${baseURLconfig}tasks/pictures/${filename}`,
      },
    };
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de tópicos de tarefa</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="100%">
              <span>
                {`
                Titulo *
                ${errors.titulo ? `(${errors.titulo})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.titulo}
                id="titulo"
                autoComplete="off"
                error={!!errors.titulo}
                placeholder="Digite o título"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="93%">
              <span>
                {`
                Colaborador *
                ${errors.colaborador ? `(${errors.colaborador})` : ''}
                `}
              </span>
              <Select
                name="colaborador"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o colaborador"
                onBlur={(e: any) => handleValidation('colaborador')}
                onChange={(e: any) => {
                  handleChanges('colaborador', e.value);
                }}
                error={!!errors.colaborador}
                defaultValue={entity.colaborador}
                options={selectCollaborators}
              />
            </FormField>
            <FormField size="7%">
              <span>Concluído</span>
              <Switch
                checked={!!entity.concluido}
                onChange={() => {
                  handleChanges('concluido', entity.concluido === 0 ? 1 : 0);
                }}
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTitle>DESCRIÇÃO DO TÓPICO</FormTitle>
          <Editor
            uploadImage={handleEditorImage}
            editorState={editorState}
            onEditorStateChange={e => handleEditor(e)}
          />
        </FormBlock>

        {/*
        <FormBlock>
          <FormTable height="275px">
            <div>
              <h1>LISTA DE TESTES</h1>
              <div>
                <button
                  disabled={idTopicState === undefined}
                  onClick={() => {
                    handleSecondaryInsert('test');
                  }}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="75%">Colaborador</TableHeaderColumn>
              <TableHeaderColumn width="15%">Data</TableHeaderColumn>
            </TableHeader>
            <Table height="175px">
              <table>
                <tbody>
                  {'id' in entityTest[0] &&
                    entityTest.map((e, index) => (
                      <tr key={index}>
                        <TableTd paddingTopBottom width="75%">
                          {e.colaborador}
                        </TableTd>
                        <TableTd paddingTopBottom width="15%">
                          {format(
                            parseISO(String(e.created_at).substring(0, 10)),
                            'dd/MM/yyyy',
                          )}
                        </TableTd>
                        <TableTd paddingTopBottom center width="10%">
                          <button
                            type="button"
                            onClick={() => handleSecondaryEdit('test', e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleTestDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>
        */}

        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => history(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default TaskTopicTestRegister;
