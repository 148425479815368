import styled from 'styled-components';

interface SectionParams {
  isOpen?: boolean;
}

interface ButtonParams {
  isOpen?: boolean;
  isActive?: boolean;
  numberOfChildren?: number;
}

export const Container = styled.div`
  height: 100%;
  max-height: 100%;
  margin: 0 10px 0 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(35, 33, 41, 0.8);
  }
  scrollbar-width: thin;
  scrollbar-color: rgba(35, 33, 41, 0.8) transparent;
`;

export const Section = styled.div<SectionParams>`
  width: ${props => !props.isOpen && '0px'};
  height: ${props => !props.isOpen && '0px'};
  opacity: ${props => !props.isOpen && '0'};
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  transition: height opacity 1s;
`;

export const Button = styled.button<ButtonParams>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: transparent;

  border: 0;
  border-left: ${props =>
    props.isActive ? '7px solid #211e1e' : '7px solid #2E2C2C'};

  height: 60px;
  min-height: 60px;
  font-size: 16px;
  color: #faede8;
  font-weight: bold;
  z-index: 99;
  padding-left: ${props =>
    props.numberOfChildren ? `${20 * props.numberOfChildren}px` : '20px'};
  .icon {
    margin-right: 13px;
  }
  .arrow {
    transform: ${props => props.isOpen && 'rotate(180deg)'};
    transition: transform 0.2s;
    margin-left: 10px;
  }
  &:hover {
    padding-left: ${props =>
      props.numberOfChildren ? `${25 * props.numberOfChildren}px` : '25px'};
    transition: padding-left 0.4s;
  }
`;
