import styled from 'styled-components';

import DatePicker from 'react-datepicker';

interface DateProps {
  error: boolean;
}

export const Container = styled.div`
  width: 100%;
  > div {
    width: 100%;
  }

  .react-datepicker {
    background: #2e2c2c;
    border: 1px solid #262424;
    .react-datepicker__triangle {
      border-top-color: #2e2c2c;
      border-bottom-color: #2e2c2c;
    }
    .react-datepicker__month-container {
      .react-datepicker__header {
        background: #2e2c2c;
        border: 0;
        .react-datepicker__current-month {
          color: #a53234;
        }
        .react-datepicker__day-names {
          .react-datepicker__day-name {
            color: #d1cdcb;
            font-weight: bold;
          }
        }
      }
    }
    .react-datepicker__month {
      .react-datepicker__week {
        .react-datepicker__day {
          color: #a39f9d;
          font-weight: bold;
        }
        .react-datepicker__day--selected {
          background: #a53234;
        }
        .react-datepicker__day--keyboard-selected {
          background: grey;
        }
      }
    }
  }
`;

export const Date = styled(DatePicker)<DateProps>`
  width: 100%;
  height: 40px;
  background: #2e2c2c;
  border-radius: 8px;
  border: ${props => (props.error ? '2px solid #a53234' : '0')};
  color: #d1cdcb;
  font-weight: bold;
  font-size: 14px;
  padding-left: 8px;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  ::placeholder {
    color: #a39f9d;
    font-weight: bold;
    font-size: 14px;
  }
`;
